import React, { useState, useEffect, useRef } from 'react';
import { Grid, Button, Fab, Paper, Switch, Select, MenuItem } from '@material-ui/core';

import { useHistory, useParams } from "react-router-dom";
import { useStateValue } from 'contexts';
import axios from "axios";
import "./index.scss";
import { FiArrowLeft } from "react-icons/fi";
import { changedValues } from "others/services";
import useApi from 'hooks/useApi';

function ServicoEditar(){
    const api = useApi();
    const [{}, dispatch] = useStateValue();
    const [original, setOriginal] = useState({});

    const [servico, setServico] = useState({});

    const history = useHistory();
    const params = useParams();

    useEffect(() => {
        const { id } = params;
        const parserID = Number(id);
        if(parserID) loadServico(id);
    }, []);

    function showFlash(obj){

        obj.isOpen = true;

        dispatch({
            type: 'setFlash',
            payload: obj
        });

    }
    
    async function loadServico(id){

        try {
            const request = await api.get(`servicos/${id}`);
            const response = request.data.payload;
            setOriginal(response);
            setServico(response);
            document.title = 'BSI - '+ response.titulo;
        } catch (error) {
            
        }
      
    }

    async function save(){
        
        let newServico = changedValues(original, servico);

        let payload = {...newServico};

        payload.qtd = payload.qtd ? Number(payload.qtd) : 1;

        try {
            const { id } = servico;
            const request = (id) ? await api.put(`servicos/${id}`, payload) : await api.post(`servicos`, payload);
            const response = request.data;

            showFlash({type: "success", title: "Sucesso", subTitle: response.msg});
            
            setTimeout(() => {
                history.push("/app/unidade/servicos");
            }, 1000);
            
        } catch (error) {
            showFlash({type: "error", title: "Error", subTitle: "Verifique os dados"});
            console.log(error);
        }
       
    }

    return ( 
        <>
            <Grid className="servico editar" style={{width: "100%", margin: 0}} container spacing={2}>
                <Grid container>
                    <div className="page-title">
                        <a href="../servico">
                            <FiArrowLeft size={28} />
                            <h3>Voltar</h3>
                        </a>
                    </div>
                </Grid>
                <Grid container>
                    <div className="page-box">
                        <div className="title">
                            Cadastro
                        </div>
                        <Paper elevation={3} className="content">
                            <form>
                                <Grid container spacing={2}>
                                
                                    <Grid item md={2} xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-11">Código</label>
                                            <input defaultValue={servico.id} disabled={true} id="input-11" />
                                        </div>
                                    </Grid>
                                    
                                    <Grid item md xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-12">Titulo</label>
                                            <input defaultValue={servico.titulo} onChange={(evt) => setServico({...servico, titulo: evt.target.value } )} id="input-12" />
                                        </div>
                                    </Grid>
                                    <Grid item md={3} xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-13">Quantidade</label>
                                            <input type="number" defaultValue={servico.qtd} onChange={(evt) => setServico({...servico, qtd: evt.target.value } )} id="input-13" />
                                        </div>
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </div>

                </Grid>

                <Grid className="page-actions" container justify="flex-end" alignItems="center">
                    <Button variant="contained" href="../servico" color="inherit">Cancelar</Button>
                    <Button className="primary-gradient" variant="contained" onClick={save}>Salvar</Button>
                </Grid>


            </Grid>
            
        
        </>
    )
    
}

export default ServicoEditar;