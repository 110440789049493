import { customInstance } from '../axios';

export default function useFetcher() {

  const formatReturn = (data) => {
    if (!data?.data?.payload) return null;

    return data.data.payload;
  }

  async function get(path, format=true) {
    const request = await customInstance.get(path);
    return format ? formatReturn(request) : request;
  }

  async function post(url, data, format=true) {
    const request = await customInstance.post(url, data);

    return format ? formatReturn(request) : request;
  }

  async function custom(method, url, data = null, headers = {}, format = true) {
    const request = await customInstance[method](url, data, headers);

    return format ? formatReturn(request) : request;
  }


  return { post, get, custom };
}
