import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Signin from "./signin";

const Auth = () => {
    let { path } = useRouteMatch();

    return ( 
        <Switch>
            <Route exact path={path}>
                <Signin />
            </Route>
        </Switch>
    )
    
}

export default Auth;