import React, { useRef, useEffect } from 'react';
import TinyMCE from "react-tinymce";
import $ from "jquery";

import "./index.scss";

function Editor({content, onChange}){

    function filePicker (callback, value, meta){
        $('#upload').trigger('click');

        $('#upload').change( async (evt) => {
            
            const file = evt.target.files[0];

            const file_url = await this.doUpload(file);

            callback(file_url, {
                alt: ''
            });

        });
    
    }

    return ( 
        <div>
            <div style={{ marginTop: 10, display: content ? 'block' : 'none'}}>
            {
                content && <TinyMCE
                    content={content}
                    config={{
                        paste_data_images: true,
                        plugins: [
                            "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                            "searchreplace wordcount visualblocks visualchars code fullscreen",
                            "insertdatetime media nonbreaking save table contextmenu directionality",
                            "emoticons template paste textcolor colorpicker textpattern"
                        ],
                        toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image print preview media | forecolor backcolor emoticons",
                        image_advtab: true,
                        // file_picker_callback: filePicker,
                    }}
                    onChange={(evt) => onChange(evt.target.getContent())}
                />
            }
            </div>
            <div style={{display: content ? 'none' : 'block'}}>
                <TinyMCE
                    content={content}
                    config={{
                        paste_data_images: true,
                        plugins: [
                            "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                            "searchreplace wordcount visualblocks visualchars code fullscreen",
                            "insertdatetime media nonbreaking save table contextmenu directionality",
                            "emoticons template paste textcolor colorpicker textpattern"
                        ],
                        toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image print preview media | forecolor backcolor emoticons",
                        image_advtab: true,
                        // file_picker_callback: filePicker,
                    }}
                    onChange={(evt) => onChange(evt.target.getContent())}
                />
            </div>
        </div>
    )
    
}

export default Editor;