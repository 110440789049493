import React, { useState, useEffect } from 'react';

import { Grid, Paper } from '@material-ui/core';
import Slider from "react-slick";
import { FiChevronRight, FiClock, FiChevronLeft } from "react-icons/fi";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from "axios";
import { format } from "date-fns";
import { useStateValue } from 'contexts';
import { useHistory } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.scss';
import useApi from 'hooks/useApi';

function SampleNextArrow(props) {
	const { className, onClick } = props;
	return< FiChevronRight className={className} onClick={onClick}/>
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return <FiChevronLeft  className={className} onClick={onClick}/>
}

function QueueComponent(){
    const api = useApi();
    const matches = useMediaQuery('(max-width:768px)');
    const [queue, setQueue] = useState([]);
    const [enabled, setEnabled] = useState(false);
    const [{ activity }] = useStateValue();
    const history = useHistory();

    useEffect(() => {

        if(!enabled) return;

        getQueue();
        
        const loop = setInterval(() => {
            getQueue();
        }, 10000);

        return () => {
            clearInterval(loop);
        }
    }, [enabled]);

    useEffect(() => {
        if(!activity) return null;

        if(activity.slug === "online") {
            setEnabled(true);
        }else{
            setEnabled(false);
        }

    }, [activity]);

    const slickConfig = {
		speed: 500,
		slidesToShow: matches ? 2 : 3,
		infinite: false,
		slidesToScroll: matches ? 2 : 3,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />
    };
    
    async function getQueue(){
        
        try {
            const request = await api.get("/fila");
            const response = request.data.payload;
            if(response.length !== queue.length) setQueue(response);
        } catch (error) {
            
        }
       
    }

    async function accept(id){

        const single = queue.find(value => value.id);

        try {
            // adiciona o campo do final da fila adiciona pelo token o prestador
            await api.put(`/fila/${id}`, { end_at: new Date() });

            // Cria um Atendimento
            const request = await api.post(`/atendimentos`, { fila: { connect: { id }}, beneficiario: { connect: { id: single.beneficiario_id } } });
            const response = request.data.payload;

            history.push(`/app/prestador/atendimento/${response.id}`);

        } catch (error) {
            console.log(error);
            alert("Você não tem permissão pra iniciar um atendimento!");
        }
        
    }

    return (
        <Paper elevation={3} className="fila">
            <h4>Fila de Atendimento</h4>
            <div className="sliders-wrapper">
                <Slider {...slickConfig}>
                    {
                        queue.map(value => (
                            <div key={value.id}>
                                <div className="single">
                                    <Grid container wrap="nowrap">
                                        <Grid item md={1}>
                                            <FiClock className="blue-text calendar-icon" />
                                        </Grid>
                                        <Grid item md>
                                            <h1 className="gray-text">{format(new Date(value.created_at), "HH")}<small>{format(new Date(value.created_at), "mm")}</small></h1>
                                            <div className="tags">
                                                <div className={`tag ${value.prestador ? 'yellow' : 'green'}-bg`}>{value.prestador ? 'Em atendimento' : 'Aguardando'}</div>
                                            </div>
                                            <h4 className="gray-text">{value.beneficiario.nome}</h4>
                                            <h5 className="light-text">Triagem Covid-19</h5>
                                            {
                                                !value.prestador && <button disabled={!enabled} onClick={() => accept(value.id)} className="btn blue-bg small">Iniciar</button>
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        ))
                    }
                    
                </Slider>
            </div>
        </Paper>
    );
}

export default QueueComponent;