import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper, CircularProgress } from '@material-ui/core';
import NovoProcedimento from "components/agrupamentos/procedimentos";
import { useHistory, useParams } from "react-router-dom";
import { useStateValue } from 'contexts';
import axios from "axios";
import "../index.scss";
import useApi from 'hooks/useApi';

function AgrupamentoNovo(){
    const api = useApi();
    document.title = 'BSI - Novo Agrupamento';

    const [procedimentos, setProcedimentos] = useState([]);
    const [agrupamento, setAgrupamento] = useState({titulo: "", tipo: "procedimento"});
    const history = useHistory();
    const params = useParams();
    const [{}, dispatch] = useStateValue();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const { id } = params;
        if(id) loadAgrupamento(id);
        if(!id) setLoading(false);
    }, []);


    function salvarNovoProcedimento(procedimento){
        setProcedimentos([...procedimentos, procedimento]);
    }

    function removerNovoProcedimento(index){
        setProcedimentos(procedimentos.filter((item, i) => i !== index));
    }

    function showFlash(obj){

        obj.isOpen = true;

        dispatch({
            type: 'setFlash',
            payload: obj
        });

    }

    async function save(){

        const parserProcedimentos = procedimentos.map(value => {
            value.qtd = Number(value.qtd);
            return value;
        });

        try {
            const obj = {...agrupamento, lista: parserProcedimentos};
            const request = (agrupamento.id) ? await api.put(`/agrupamentos/${agrupamento.id}`, obj) : await api.post("/agrupamentos", obj);
            const msg = request.data.msg;
            showFlash({type: "success", title: "Sucesso", subTitle: msg});
            
            setTimeout(() => {
                history.push("/app/prestador/procedimentos/agrupamentos/lista");
            }, 1000);
            
        } catch (error) {
            showFlash({type: "error", title: "Error", subTitle: error.request.response.msg});
            console.log(error)
        }

        

    }

    async function loadAgrupamento(id){

        try {
            
            const request = await api.get(`/agrupamentos/${id}`);
            const response = request.data.payload;
            setAgrupamento(response);
            if(response.lista) setProcedimentos(response.lista);
            
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
        
    }

    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <>
            <Grid className="agrupamento novo" container spacing={2}>
                <div className="page-box">
                    <div className="title">Informações</div>
                    <Paper elevation={3} className="content">
                        <Grid container spacing={2}>
                            <Grid item md={2} xs={12}>
                                <div className="input-wrapper">
                                    <label htmlFor="input-2232">Código (Automático)</label>
                                    <input disabled type="number" defaultValue={agrupamento.id} id="input-2232" />
                                </div>
                            </Grid>

                            <Grid item md xs={12}>
                                <div className="input-wrapper">
                                    <label htmlFor="input-112">Titulo</label>
                                    <input id="input-112" value={agrupamento.titulo} onChange={(evt) => setAgrupamento({...agrupamento, titulo: evt.target.value})} />
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
                <div className="page-box">
                    <div className="title">Lista de Procedimentos</div>
                    <Paper elevation={3} className="content">

                        <table>
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Descrição</th>
                                        <th>Quantidade</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        procedimentos && procedimentos.map((value, i) => (
                                            <tr key={i}>
                                                <td>{value.id}</td>
                                                <td>{value.desc}</td>
                                                <td>{value.qtd}</td>
                                                <td><button className="btn gray-bg small" onClick={() => removerNovoProcedimento(i)} variant="contained">Remover</button></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <br/>
                            <br/>
                            <NovoProcedimento save={salvarNovoProcedimento} opened={true} />  

                    </Paper>
                </div>
                
                <Grid className="page-actions borded" container justify="flex-end" alignItems="center">
                    <Button href='lista' variant="contained">Cancelar</Button>
                    <Button variant="contained" color="primary" onClick={() => save()}>Salvar</Button>
                </Grid>
            </Grid>

        </>
    )
    
}

export default AgrupamentoNovo;