import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import MaskedInput from "react-text-mask";
import masks from "others/mask";
import Estados from "assets/json/estados.json";
import AutoComplete from "components/autoComplete";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStateValue } from "contexts";
import { changedValues } from "others/services";
import { FiArrowLeft } from "react-icons/fi";
import axios from "axios";

import DiagDoc from "../relatorios/default/diagnostico/doc";

import PageActions from "components/relatorio/actions";

import "./index.scss";
import useApi from "hooks/useApi";
import useFetcher from "hooks/useFetcher";
import useCert from "hooks/useCert";

function Diagnostico() {
  const { requestCert, signinFile } = useCert();
  const api = useApi();
  const fetcher = useFetcher();
  const [diagnostico, setDiagnostico] = useState({
    tipo: { id: "" },
    situacao: { id: 1 },
    gravidade: { id: "" },
    descricao: "",
  });
  const [original, setOriginal] = useState({});

  const [beneficiario, setBeneficiario] = useState({});
  const [beneficiarios, setBeneficiarios] = useState([]);

  const [prestador, setPrestador] = useState({});
  const [prestadores, setPrestadores] = useState([]);

  const [modal, setOpenModal] = useState(false);
  const [nascimento, setNascimento] = useState(null);

  const [situacoes, setSituacoes] = useState([]);
  const [gravidades, setGravidades] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [tipos, setTipos] = useState([]);

  const [disableSelectBeneficiario, setDisableSelectBeneficiario] =
    useState(false);

  const history = useHistory();
  const params = useParams();

  document.title = "BSI - Cadastro de diagnostico";

  const [{ user }, dispatch] = useStateValue();

  useEffect(() => {
    listaSituacoes();
    listaGravidades();
    listaTipos();
    listaBeneficiarios();
  }, []);

  useEffect(() => {
    const id = Number(params.id);

    if (user && user.id) {
      if (id) loadDiagnostico(id);
      if (!id) setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    const bene_id = Number(params.bene_id);

    if (bene_id && beneficiarios.length > 0) {
      const beneficiario = beneficiarios.find((value) => value.id === bene_id);
      if (beneficiario) {
        setBeneficiario(beneficiario);
        setDisableSelectBeneficiario(true);
      }
    }
  }, [beneficiarios]);

  function clearData() {
    setBeneficiario({});
    setPrestador({});
    setDiagnostico({
      tipo: { id: "" },
      situacao: { id: 1 },
      gravidade: { id: "" },
      descricao: "",
    });
  }

  async function loadDiagnostico(id) {
    try {
      const request = await api.get(`/diagnosticos/${id}`);
      let response = request.data.payload;

      setBeneficiario(response.beneficiario);
      setPrestador(response.prestador);

      delete response.prestador;
      delete response.beneficiario;

      setDiagnostico(response);
      setOriginal(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function listaTipos() {
    try {
      const request = await api.get("/tipos");
      const response = request.data.payload;
      const filter = response.filter((value) => value.diagnostico);
      setTipos(filter);
    } catch (error) {
      console.log(error);
    }
  }

  async function listaSituacoes() {
    try {
      const request = await api.get("/situacoes");
      const response = request.data.payload;
      setSituacoes(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function listaGravidades() {
    try {
      const request = await api.get("/gravidades");
      const response = request.data.payload;
      setGravidades(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function listaBeneficiarios() {
    try {
      const request = await api.get("/beneficiarios");
      const response = request.data.payload;
      setBeneficiarios(response);
    } catch (error) {
      console.log(error);
    }
  }

  function selectBeneficiario(single, byName = false) {
    if (single && byName) setOpenModal(true);
    setBeneficiario(single);
  }

  function verifyBeneficiario() {
    setOpenModal(false);
  }

  function showFlash(obj) {
    obj.isOpen = true;

    dispatch({
      type: "setFlash",
      payload: obj,
    });
  }

  function gerarRelatorio() {
    localStorage.setItem(
      "@bsi:relatorio",
      JSON.stringify({ diagnostico, beneficiario, prestador })
    );
    window.open("/relatorios/diagnostico", "_blank");
  }

  async function signinHandle() {
    const certificado = await requestCert();

    const props = { diagnostico, beneficiario, prestador, certificado };

    const docWithSignature = <DiagDoc {...props} />;

    const updateDiagnostico = await signinFile(
      `diagnosticos/${diagnostico.id}`,
      docWithSignature
    );

    if (!updateDiagnostico) return false;

    setDiagnostico(updateDiagnostico);
  }

  async function cancelDiagnostico() {
    const { id } = diagnostico;

    try {
      const request = await api.del(`/diagnosticos/${id}`);
      const response = request.data;

      setDiagnostico({ ...diagnostico, ...response.payload });

      showFlash({ type: "success", title: "Sucesso", subTitle: response.msg });
    } catch (error) {}
  }

  async function save(finished) {
    try {
      const newDiagnostico = changedValues(original, diagnostico);

      let payload = { ...newDiagnostico };

      payload.situacao = {
        // 1 = rascunho, 2 = Aguardando assinatura
        connect: { id: finished ? 2 : 1 },
      };

      payload.entidade = {
        connect: { id: 1 },
      };

      if (beneficiario && beneficiario.id) {
        payload.beneficiario = {
          connect: { id: beneficiario.id },
        };
      }

      if (diagnostico.tipo && diagnostico.tipo.id) {
        payload.tipo = {
          connect: { id: diagnostico.tipo.id },
        };
      }

      if (diagnostico.gravidade && diagnostico.gravidade.id) {
        payload.gravidade = {
          connect: { id: diagnostico.gravidade.id },
        };
      }

      const { id } = diagnostico;
      const request = id
        ? await api.put(`/diagnosticos/${id}`, payload)
        : await api.post(`/diagnosticos`, payload);
      const response = request.data.payload;

      setDiagnostico(response);

      showFlash({
        type: "success",
        title: "Sucesso",
        subTitle: request.data.msg,
      });

      if (response.situacao.id === 1)
        history.push("/app/prestador/diagnosticos/lista");
      if (response.situacao.id === 2)
        history.push("/app/prestador/diagnosticos/" + response.id);
    } catch (error) {
      showFlash({
        type: "error",
        title: "Error",
        subTitle: "Verifique os dados",
      });
      console.log(error);
    }
  }

  async function create(finished) {
    try {
      const newDiagnostico = changedValues(original, diagnostico);

      let payload = { ...newDiagnostico };

      payload.situacao_id = finished ? 2 : 1;

      if (beneficiario && beneficiario.id) {
        payload.beneficiario_id = beneficiario.id;
      }

      if (diagnostico.tipo && diagnostico.tipo.id) {
        payload.tipo_id = diagnostico.tipo.id;
      }

      if (diagnostico.gravidade && diagnostico.gravidade.id) {
        payload.gravidade_id = diagnostico.gravidade.id;
      }

      console.log(payload);
      const { id } = diagnostico;
      const request = id
        ? await fetcher.post(`/diagnosticos/update/${id}`, payload)
        : await fetcher.post(`/diagnosticos/create`, payload);
      const response = request.payload;

      console.log(response);
      setDiagnostico(response);

      showFlash({
        type: "success",
        title: "Sucesso",
        subTitle: request.data.msg,
      });

      if (response.situacao_id === 1)
        history.push("/app/prestador/diagnosticos/lista");
      if (response.situacao_id === 2)
        history.push("/app/prestador/diagnosticos/" + response.id);
    } catch (error) {
      showFlash({
        type: "error",
        title: "Error",
        subTitle: "Verifique os dados",
      });
      console.log(error);
    }
  }

  if (isLoading) {
    return (
      <div className="loading-wrapper">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Grid
        className="exames diagnostico"
        style={{ width: "100%", margin: 0 }}
        container
        spacing={2}
      >
        <Dialog
          open={modal}
          onClose={() => setBeneficiario({ id: "", nome: "" })}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Confirme a data de nascimento
          </DialogTitle>
          <DialogContent>
            <div className="input-wrapper">
              <label htmlFor="input-24">Data de Nascimento</label>
              <MaskedInput
                value={nascimento}
                onChange={(evt) => setNascimento(evt.target.value)}
                mask={masks.date}
                id="input-24"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <button
              disabled={!nascimento}
              className="btn blue-bg"
              onClick={verifyBeneficiario}
            >
              {" "}
              Confirmar{" "}
            </button>
            <br />
            <br />
          </DialogActions>
        </Dialog>
        <Grid container>
          <div className="page-title">
            <Link to="../diagnosticos/lista">
              <FiArrowLeft size={28} />
              <h3>Voltar</h3>
            </Link>
          </div>
        </Grid>
        <Grid container>
          <div className="page-box">
            <div className="title">Informações legais</div>
            <Paper elevation={3} className="content">
              <form>
                {/* <TextField onChange={(evt) => setCredential({...credential, login : evt.target.value})} color="primary" className="custom-input" label="Login" fullWidth variant="outlined" /> */}
                <Grid container spacing={2}>
                  <Grid item md={1} xs={6}>
                    <div className="input-wrapper">
                      <label htmlFor="input-2">Protocolo</label>
                      <input
                        defaultValue={diagnostico.id}
                        disabled
                        id="input-2"
                      />
                    </div>
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <AutoComplete
                      disabled={disableSelectBeneficiario}
                      list={beneficiarios}
                      onChangeHandle={selectBeneficiario}
                      label="Matricula"
                      object={"id"}
                      value={beneficiario}
                    />
                  </Grid>
                  <Grid item md={7} xs={12}>
                    <AutoComplete
                      disabled={disableSelectBeneficiario}
                      list={beneficiarios}
                      onChangeHandle={selectBeneficiario}
                      label="Beneficiário"
                      object={"nome"}
                      value={beneficiario}
                    />
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <div className="input-wrapper">
                      <label htmlFor="input-26">Data</label>
                      <input
                        disabled
                        defaultValue={diagnostico.created_at}
                        id="input-26"
                      />
                    </div>
                  </Grid>
                </Grid>
                {user.role === "entidade" && (
                  <Grid container spacing={2}>
                    <Grid item md={2} xs={12}>
                      <AutoComplete
                        disabled={user.role === "prestador"}
                        list={prestadores}
                        onChangeHandle={setPrestador}
                        label="Matricula"
                        object={"id"}
                        value={prestador}
                      />
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <AutoComplete
                        disabled={user.role === "prestador"}
                        list={prestadores}
                        onChangeHandle={setPrestador}
                        label="Profissional Solicitante"
                        object={"nome"}
                        value={prestador}
                      />
                    </Grid>

                    <Grid item md={3} xs={6}>
                      <div className="select-wrapper">
                        <label htmlFor="input-11">CRM Estado</label>
                        <Select
                          disabled
                          value={prestador.crm_uf || ""}
                          onChange={(evt) =>
                            setPrestador({
                              ...prestador,
                              crm_uf: evt.target.value,
                            })
                          }
                          htmlFor="input-11"
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem value="">
                            {" "}
                            <span>Selecione</span>{" "}
                          </MenuItem>
                          {Estados.map((value, i) => (
                            <MenuItem value={value.sigla} key={i}>
                              <span>{value.desc}</span>
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </Grid>
                    <Grid item md={2} xs={6}>
                      <div className="input-wrapper">
                        <label htmlFor="input-5">Número</label>
                        <input
                          disabled
                          value={prestador.crm || ""}
                          onChange={(evt) =>
                            setPrestador({
                              ...prestador,
                              crm: evt.target.value,
                            })
                          }
                          id="input-5"
                        />
                      </div>
                    </Grid>
                    <Grid item md xs={6}>
                      <div className="input-wrapper">
                        <label htmlFor="input-6">CBO</label>
                        <input
                          disabled
                          value={prestador.cbo || ""}
                          onChange={(evt) =>
                            setPrestador({
                              ...prestador,
                              cbo: evt.target.value,
                            })
                          }
                          id="input-6"
                        />
                      </div>
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={2}>
                  <Grid item md xs={12}>
                    <div className="input-wrapper">
                      <label htmlFor="input-7">Titulo</label>
                      <input
                        value={diagnostico.titulo || ""}
                        onChange={(evt) =>
                          setDiagnostico({
                            ...diagnostico,
                            titulo: evt.target.value,
                          })
                        }
                        id="input-6"
                      />
                    </div>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <div className="select-wrapper">
                      <label htmlFor="input-11">Tipo de Solicitação</label>
                      <Select
                        onChange={(evt) =>
                          setDiagnostico({
                            ...diagnostico,
                            tipo: { id: evt.target.value },
                          })
                        }
                        value={diagnostico.tipo.id}
                        htmlFor="input-11"
                        variant="outlined"
                        fullWidth
                      >
                        {tipos.map((value) => (
                          <MenuItem value={value.id} key={value.id}>
                            <span>{value.titulo}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <div className="select-wrapper">
                      <label htmlFor="input-11">Gravidade</label>
                      <Select
                        onChange={(evt) =>
                          setDiagnostico({
                            ...diagnostico,
                            gravidade: { id: evt.target.value },
                          })
                        }
                        value={diagnostico.gravidade.id}
                        htmlFor="input-11"
                        variant="outlined"
                        fullWidth
                      >
                        {gravidades.map((value) => (
                          <MenuItem value={value.id} key={value.id}>
                            <span>{value.titulo}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <div className="select-wrapper">
                      <label htmlFor="input-11">Situação</label>
                      <Select
                        disabled
                        onChange={(evt) =>
                          setDiagnostico({
                            ...diagnostico,
                            situacao: { id: evt.target.value },
                          })
                        }
                        value={diagnostico.situacao.id}
                        htmlFor="input-11"
                        variant="outlined"
                        fullWidth
                      >
                        {situacoes.map((value) => (
                          <MenuItem value={value.id} key={value.id}>
                            <span>{value.titulo}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md xs={12}>
                    <div className="input-wrapper">
                      <label htmlFor="input-7">Indicação Clínica</label>
                      <textarea
                        value={diagnostico.descricao || ""}
                        onChange={(evt) =>
                          setDiagnostico({
                            ...diagnostico,
                            descricao: evt.target.value,
                          })
                        }
                        id="input-7"
                      />
                    </div>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </div>
        </Grid>

        <PageActions
          formState={{
            saved: !!diagnostico.id,
            situacao: diagnostico.situacao.id,
          }}
          doPrint={gerarRelatorio}
          doClear={clearData}
          doCancel={cancelDiagnostico}
          doSave={save}
          doSigning={signinHandle}
        />
      </Grid>
    </>
  );
}

export default Diagnostico;
