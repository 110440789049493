import useFetcher from "./useFetcher";
import Swal from "sweetalert2";
import { pdf } from "@react-pdf/renderer";
import useApi from "./useApi";

export default function useCert() {
  const fetcher = useFetcher();
  const api = useApi();

  async function requestCert() {
    try {
      const request = await fetcher.get("v1/sign");

      if (!request) return false;

      return request;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async function signinFile(updateUrl, doc) {
    try {
      const asPdf = pdf({});
      asPdf.updateContainer(doc);

      const blob = await asPdf.toBlob();
      const formData = new FormData();

      formData.append("file", blob);

      const request = await fetcher.custom(
        'post',
        `v1/sign/pdf`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      console.log(request);
      if (!request) return false;

      const response = request;

      const updateRequest = await api.put(`${updateUrl}`, {
        situacao: { connect: { id: 4 } },
        documento: { create: { url: response.url } },
      });

      Swal.fire("Sucesso!", "Seu documento foi assinado!", "success");

      return updateRequest.data.payload;
    } catch (error) {
      console.log(error);

      Swal.fire("Error!", "Ocorreu um erro na assintura do arquivo!", "error");

      return false;
    }
  }
  return { requestCert, signinFile };
}
