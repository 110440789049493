import React, { useEffect } from 'react';
import { Grow, Paper, Grid } from "@material-ui/core";
import { useStateValue } from 'contexts';
import "./index.scss";
import { GoCheck, GoX } from "react-icons/go";

function Flash(){

    const [{ flash : { isOpen, type, title, subTitle, caption } }, dispatch] = useStateValue();


    useEffect(() => {
        if(isOpen) setTimeout(() => { close() }, 2500);
    },[isOpen]);

    function close(){
        
        dispatch({
            type: 'setFlash',
            payload: {}
        });

    }

    if(!isOpen) return null;
  
    return ( 
        <div className={`flash-wrapper ${type} ${caption ? 'with-caption' : ''}`} >
            <Grow in={true}>
                <Paper elevation={3} className="content">
                    <Grid container alignItems="center" justify="center">
                        <Grid item xs={2}>
                            <div className="icon">
                                <div className="wrapper">
                                    { type === 'success' ? <GoCheck size={42} /> : <GoX size={42} /> }
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs>
                            <GoX className="close" size={24} onClick={close} />
                            <h3 className="title">{title}</h3>
                            <h5 className="subtitle">
                                { subTitle }
                                <br/><b>{ caption }</b>
                            </h5>
                        </Grid>
                    </Grid>
                </Paper>
            </Grow>
        </div>
    )
    
}

export default Flash;