import React, { useState, useEffect } from 'react';
import { Grid, Chip, Button, Paper, CircularProgress } from '@material-ui/core';
import axios from "axios";
import "./index.scss";
import { cnpjMask, dateParser } from "others/services";
import { Link } from 'react-router-dom';
import useApi from 'hooks/useApi';

function Unidades(){
    const api = useApi();
    document.title = 'BSI - Lista de Unidades';

    const [unidades, setUnidades] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        loadUnidades();
    }, []);

    async function loadUnidades(){

        try {
            const request = await api.get("/unidades");
            const response = request.data.payload;
            setUnidades(response);
            
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
    }

    async function removeExame(id){
        alert("Ainda não foi implementado a regra pra exclusão de entidade!");
    }

    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <>
            <Grid className="unidades listar" container spacing={2}>
                <div className="page-box">
                    <div className="title">
                        Lista de Unidades Cadastradas
                    </div>
                    <Paper elevation={3} className="content">
                    <table>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Razão Social</th>
                                <th>Email</th> 
                                <th>CNPJ</th> 
                                <th>Data</th> 
                                <th>Situação</th> 
                                <th>Ações</th> 
                            </tr>
                        </thead>
                        <tbody>
                            {
                                unidades.map(value => (
                                    <tr key={value.id}>
                                        <td>{value.id}</td>
                                        <td>{value.razao_social}</td>
                                        <td>{value.credencial.email}</td>
                                        <td>{ cnpjMask(value.cnpj) }</td>
                                        <td>{ dateParser(value.credencial.created_at)}</td>
                                        <td> <Chip size="small" color={value.credencial.ativo ? "primary" : "danger"} label={value.credencial.ativo ? "Habilitado" : "Desabilitado"} /></td>
                                        <td>
                                            <Link className="btn blue-bg small" to={`Unidades/${value.id}`}>Editar</Link>
                                            <a className="btn gray-bg small" onClick={() => removeExame(value.id)}>Remover</a>
                                        </td>
                                    </tr>
                                ))
                            }
                           
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                    <Grid className="page-actions borded" container justify="flex-start" alignItems="center">
                        <Button href='unidades/novo' variant="contained" color="primary">Adicionar</Button>
                    </Grid>
                    </Paper>
                </div>

            </Grid>

        </>
    )
    
}

export default Unidades;