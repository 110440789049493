import React, { useRef } from 'react';

import { Grid, Fab, Paper } from '@material-ui/core';
import { IoMdCreate } from "react-icons/io";

import { useStateValue } from 'contexts';
import { cepMask, phoneMask, stateDesc } from "others/services";
import { FiCamera } from "react-icons/fi";

import './index.scss';
// import { useHistory } from "react-router-dom";

function Profile(){

    const [{ user : { nome, razao_social, idade, telefone, genero, imagem_url, endereco, credencial } }] = useStateValue();
    
    const localData = JSON.parse(localStorage.getItem("@bsi"));
    
    const { auth: { role }} = localData;
    let subTitle = '';

    if(role === 'admin') subTitle = "Administrador";
    if(role === 'unidade') subTitle = "Unidade";
    if(role === 'entidade') subTitle = "Entidade";
    if(role === 'prestador') subTitle = "Prestador";
    
    // const uploadInput = useRef(null);

    return (
        <Paper elevation={3} className="profile">
            <Grid container>
                <Grid container justify="flex-end">
                    <IoMdCreate className="edit-profile blue-bg" color={"white"} />
                </Grid>
                <Grid item md={12} xs>
                    <div className="avatar-wrapper">
                        <img src={imagem_url} alt=""/>
                    </div>
                    <h4 className="name text-center">{ nome || razao_social }</h4>
                    <h5 className="text-center" style={{textTransform: "capitalize"}}>{subTitle}</h5>
                </Grid>
                <Grid className="content" container>
                    <Grid container className="info">
                        <h4>Meus Dados</h4>
                    </Grid>
                    {
                        genero && 
                        <Grid container justify="space-between" className="info">
                            <h5>Genêro</h5>
                            <h5>{genero}</h5>
                        </Grid>
                    }
                    {
                        idade && 
                        <Grid container justify="space-between" className="info">
                            <h5>Idade</h5>
                            <h5>{idade} anos</h5>
                        </Grid>
                    }
                    {
                        telefone &&
                        <Grid container justify="space-between" className="info">
                            <h5>Telefone</h5>
                            <h5>{ phoneMask(telefone) }</h5>
                        </Grid>
                    }
                    {
                        credencial && credencial.email &&
                        <Grid container justify="space-between" className="info">
                            <h5>Email</h5>
                            <h5>{credencial.email}</h5>
                        </Grid>
                    }
                    
                    {
                        endereco &&
                        <>
                            <Grid container>
                                <h4>Endereço</h4>
                            </Grid>
                            <Grid container className="info">
                                <Grid container justify="space-between" className="info">
                                    <h5>{endereco.logradouro}, {endereco.numero} - {endereco.bairro},  <br/> {endereco.cidade} - { stateDesc(endereco.estado) }, { cepMask(endereco.cep) } <br/> { endereco.complemento } </h5>
                                </Grid>
                            </Grid>
                        </>
                    }
                </Grid>
            </Grid>
        </Paper>
    );
}

export default Profile;