import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Dashboard from "./dashboard";
import Atendimento from "../../atendimentos/sala";
import Atendimentos from "../../atendimentos";

import Solicitacoes from "pages/solicitacoes";
import SolicitacoesEditar from "pages/solicitacoes/editar";

import Procedimentos from "pages/procedimentos";
import ProcedimentosGrupos from "pages/procedimentos/agrupamentos";
import ProcedimentosGruposEditar from "pages/procedimentos/agrupamentos/editar";

import Medicamentos from "pages/medicamentos";
import MedicamentosGrupos from "pages/medicamentos/agrupamentos";
import MedicamentosGruposEditar from "pages/medicamentos/agrupamentos/editar";

import Pacientes from "pages/pacientes";
import PacientesEditar from "pages/pacientes/editar";

import Diagnosticos from "pages/diagnosticos";
import DiagnosticosEditar from "pages/diagnosticos/editar";

import MinhasNotificacoes from "pages/notificacoes/single";
import Presencial from 'pages/atendimentos/presencial';

const Auth = () => {
    let { path } = useRouteMatch();

    return ( 
        <Switch>
            <Route exact path={path}>
                <Dashboard />
            </Route>
            <Route exact path={`${path}/dashboard`}>
                <Dashboard />
            </Route>
            <Route exact path={`${path}/atendimentos`}>
                <Atendimentos />
            </Route>
            <Route exact path={`${path}/atendimento/:id`}>
                <Atendimento />
            </Route>

            <Route exact path={`${path}/atendimento/presencial/:id`}>
                <Presencial />
            </Route>

            <Route exact path={`${path}/medicamentos/lista`}>
                <Medicamentos />
            </Route>
            <Route exact path={`${path}/medicamentos/agrupamentos/lista`}>
                <MedicamentosGrupos />
            </Route>
            <Route exact path={`${path}/medicamentos/agrupamentos/novo`}>
                <MedicamentosGruposEditar />
            </Route>
            <Route exact path={`${path}/medicamentos/agrupamentos/:id`}>
                <MedicamentosGruposEditar />
            </Route>

            <Route exact path={`${path}/procedimentos/lista`}>
                <Procedimentos />
            </Route>
            <Route exact path={`${path}/procedimentos/agrupamentos/lista`}>
                <ProcedimentosGrupos />
            </Route>
            <Route exact path={`${path}/procedimentos/agrupamentos/novo`}>
                <ProcedimentosGruposEditar />
            </Route>
            <Route exact path={`${path}/procedimentos/agrupamentos/:id`}>
                <ProcedimentosGruposEditar />
            </Route>
            <Route exact path={`${path}/pacientes/lista`}>
                <Pacientes />
            </Route>
            <Route exact path={`${path}/pacientes/:id`}>
                <PacientesEditar />
            </Route>

            <Route exact path={`${path}/solicitacoes/lista`}>
                <Solicitacoes />
            </Route>
            <Route exact path={`${path}/solicitacoes/:id`}>
                <SolicitacoesEditar />
            </Route>
            <Route exact path={`${path}/solicitacoes/novo/:bene_id`}>
                <SolicitacoesEditar />
            </Route>

            <Route exact path={`${path}/diagnosticos/lista`}>
                <Diagnosticos />
            </Route>
            <Route exact path={`${path}/diagnosticos/:id`}>
                <DiagnosticosEditar />
            </Route>
            <Route exact path={`${path}/diagnosticos/novo/:bene_id`}>
                <DiagnosticosEditar />
            </Route>

            <Route exact path={`${path}/minhas-notificacoes/:id`}>
                <MinhasNotificacoes />
            </Route>
            
        </Switch>
    )
    
}

export default Auth;