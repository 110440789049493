import React, { useState, useEffect, useRef } from 'react';
import { Grid, Button, Fab, Paper, Switch, Select, MenuItem, CircularProgress } from '@material-ui/core';

import MaskedInput from 'react-text-mask';
import masks from "others/mask";
import Estados from "assets/json/estados.json";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStateValue } from 'contexts';
import axios from "axios";
import "./index.scss";
import { FiArrowLeft, FiCamera } from "react-icons/fi";
import { changedValues, prismaNewValues } from "others/services";
import Endereco from 'components/endereco';
import useApi from 'hooks/useApi';

function UnidadeEditar(){
    const api = useApi();
    const [original, setOriginal] = useState({});
    const [isLoading, setLoading] = useState(true);

    const [unidade, setUnidade] = useState({
        credencial: {
            ativo: true,
        },
        endereco: {
            estado: ''
        },
        imagem_url: "https://f0.pngfuel.com/png/340/956/profile-user-icon-png-clip-art-thumbnail.png",
    });

    const [{}, dispatch] = useStateValue();
    const uploadInput = useRef(null);

    const history = useHistory();
    const params = useParams();

    useEffect(() => {
        const { id } = params;
        const parserID = Number(id);
        if(!parserID) return setLoading(false);
        if(parserID) loadUnidade(id);
    }, []);

    function showFlash(obj){

        obj.isOpen = true;

        dispatch({
            type: 'setFlash',
            payload: obj
        });

    }
    
    async function loadUnidade(id){

        try {
            const request = await api.get(`unidades/${id}`);
            const response = request.data.payload;
            setOriginal(response);
            setUnidade(response);
            document.title = 'BSI - '+ response.razao_social;
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
      
    }

    async function save(){
        
        if(unidade.telefone) unidade.telefone = unidade.telefone.replace(/\D/g, '');
        if(unidade.cnpj) unidade.cnpj = unidade.cnpj.replace(/\D/g, '');
        if(unidade.endereco.cep) unidade.endereco.cep = Number(unidade.endereco.cep.toString().replace(/\D/g, ''));
        if(unidade.endereco.numero) unidade.endereco.numero = Number(unidade.endereco.numero);

        let newUnidade = changedValues(original, unidade);
        let endereco = prismaNewValues(original.endereco, unidade.endereco);
        let credencial = prismaNewValues(original.credencial, unidade.credencial);

        let payload = {...newUnidade};
        if(endereco) payload.endereco = endereco;
        if(credencial) payload.credencial = credencial;

        try {
            const { id } = unidade;
            const request = (id) ? await api.put(`unidades/${id}`, payload) : await api.post(`unidades`, payload);
            const response = request.data;

            showFlash({type: "success", title: "Sucesso", subTitle: response.msg});
            
            setTimeout(() => {
                history.push("/app/entidade/unidades");
            }, 1000);
            
        } catch (error) {
            showFlash({type: "error", title: "Error", subTitle: "Verifique os dados"});
            console.log(error);
        }
       
    }

    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <>
            <Grid className="unidades editar" style={{width: "100%", margin: 0}} container spacing={2}>
                <Grid container>
                    <div className="page-title">
                        <Link to="../unidades">
                            <FiArrowLeft size={28} />
                            <h3>Voltar</h3>
                        </Link>
                    </div>
                </Grid>
                <Grid container>
                    <div className="page-box">
                        <div className="title">
                            Informações legais
                        </div>
                        <Paper elevation={3} className="content">
                            <form>
                                {/* <TextField onChange={(evt) => setCredential({...credential, login : evt.target.value})} color="primary" className="custom-input" label="Login" fullWidth variant="outlined" /> */}
                                <Grid container>
                                    {/* <label htmlFor="input-2">Razão Social</label> */}
                                    <input placeholder="Razão Social / Nome" className="big-input" defaultValue={unidade.razao_social} onChange={(evt) => setUnidade({...unidade, razao_social: evt.target.value})} />
                                </Grid>
                                    
                                <Grid container spacing={2}>
                                    
                                    <Grid item md={3} xs={6}>
                                        <div className="avatar-wrapper">
                                            <img src={unidade.imagem_url} alt=""/>
                                            <input ref={uploadInput} accept="image/*" type="file" />
                                            <Fab onClick={() => uploadInput.current.click() } size="medium" className="primary-gradient"> <FiCamera size={20} /></Fab>
                                        </div>
                                    </Grid>

                                    <Grid item md>

                                        <Grid container spacing={2}>
                                            
                                            <Grid item md={3} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-3">CNPJ</label>
                                                    <MaskedInput guide={false} mask={masks.cnpj} defaultValue={unidade.cnpj} onChange={(evt) => setUnidade({...unidade, cnpj: evt.target.value })} id="input-3" />
                                                </div>
                                            </Grid>

                                            <Grid item md={1} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-4">Habilitado</label>
                                                    <Switch
                                                        checked={unidade.credencial.ativo}
                                                        color="primary"
                                                        onClick={() => setUnidade({...unidade, credencial: {...unidade.credencial, ativo: !unidade.credencial.ativo } })}
                                                    />
                                                </div>
                                            </Grid>

                                        </Grid>

                                        <Grid container spacing={2}>

                                            <Grid item md={4} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-5">Email</label>
                                                    <input defaultValue={unidade.credencial.email} onChange={(evt) => setUnidade({...unidade, credencial: {...unidade.credencial, email: evt.target.value }})} id="input-5" />
                                                </div>
                                            </Grid>

                                            <Grid item md={3} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-6">Telefone</label>
                                                    <MaskedInput guide={null} mask={masks.phone} defaultValue={unidade.telefone} onChange={(evt) => setUnidade({...unidade, telefone: evt.target.value})} id="input-6" />
                                                </div>
                                            </Grid>

                                        </Grid>

                                    </Grid>
                                </Grid>                               
                            </form>
                            <br/>
                        </Paper>
                    </div>
                    <div className="page-box">

                        <div className="title">
                            Endereço
                        </div>

                        <Endereco endereco={unidade.endereco} changeData={endereco => setUnidade({...unidade, endereco}) } />

                    </div>

                </Grid>

                <Grid className="page-actions" container justify="flex-end" alignItems="center">
                    <Button variant="contained" href="../unidades" color="inherit">Cancelar</Button>
                    <Button className="primary-gradient" variant="contained" onClick={save}>Salvar</Button>
                </Grid>


            </Grid>
            
        
        </>
    )
    
}

export default UnidadeEditar;