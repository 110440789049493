import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import axios from "axios";
import "./index.scss";
import { datetimeParser } from "others/services";
import { useStateValue } from 'contexts';
import useApi from 'hooks/useApi';

function Notificacao({id}){
    const api = useApi();
    const [notificacao, setNotificacao] = useState({lista: []});

    useEffect(() => {
        if(id) getNotificacao();
    }, [id]);

    async function getNotificacao() {
        const request = await api.get(`/notificacoes/global/${id}`);
        const response = request.data.payload;
        setNotificacao(response);
    }

    function bindNome(noti) {
        if(noti.prestador) return noti.prestador.nome;
        if(noti.beneficiario) return noti.beneficiario.nome;
        if(noti.entidade) return noti.entidade.razao_social;
    }

    return ( 
        <Grid className="notificacao" style={{width: "100%", margin: 0}} container spacing={2}>
            <h3>Dados do envio</h3>
            <Grid container justify="space-between">
                <Grid item>
                    <p><b>Protocolo:</b> #{ notificacao.id }</p>
                    <p><b>Titulo:</b> { notificacao.titulo }</p>
                    <p><b>Descrição:</b> { notificacao.lista[0] && notificacao.lista[0].descricao }</p>
                </Grid>
                <Grid item>
                    <p><b>Status:</b> Em Execução</p>
                    <p><b>Data da criação:</b> { datetimeParser(notificacao.created_at)}</p>
                    <p><b>Publico:</b> <span style={{textTransform: "capitalize"}}>{ notificacao.publico }</span> </p>
                </Grid>
            </Grid>

            <h3 className="borded">Destinatários</h3>

            <table>
                <thead>
                    <tr>
                        <th>Protocolo</th>
                        <th>Nome</th> 
                        <th>Situação</th> 
                    </tr>
                </thead>
                <tbody>
                    {
                        notificacao.lista.map(value => (
                            <tr key={value.id}>
                                <td>#{value.id}</td>
                                <td>{ bindNome(value) }</td>
                                <td>{value.aberto ? "Visualizado" : "Não Aberto"} </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
             
            
        </Grid>
    )
    
}

export default Notificacao;