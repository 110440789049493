import React, { useState, useEffect } from 'react';
import AutoComplete from "components/autoComplete";
import Tiss from "assets/json/tiss.json";
import axios from "axios";

import { Grid, Switch, Button, FormControlLabel } from '@material-ui/core';
import useApi from 'hooks/useApi';

const createNestedList = Tiss.filter((value, i) => {
    value.med_apresenta = value.medicamento + ' ---- ' + value.apresenta;
    return value;
});

function NovoMedicamento({save, disabled, listSave, opened = false}){
    const api = useApi();
    const [medicamento, setMedicamento] = useState({ean: null, qtd: null});
    const [agrupamentos, setAgrupamentos] = useState([]);
    const [agrupamento, setAgrupamento] = useState(null);
    const [tipoLista, setTipoLista] = useState(false);
    const [novo, setNovo] = useState(false);

    useEffect(()=> {
        if(opened) setNovo(true);
        if(!opened) loadAgrupamentos();
    }, []);

    useEffect(()=> {
        if(medicamento.ean && !medicamento.qtd) setMedicamento({...medicamento, qtd: 1});
    }, [medicamento]);

    async function loadAgrupamentos(){
        
        try {
            const request = await api.get("/agrupamentos/lista/medicamento");
            const response = request.data.payload;
            setAgrupamentos(response);
        } catch (error) {
            console.log(error);
        }
       
    }

    function openAgrupamentoDeMedicamentos(single){
        setAgrupamento(single);
    }

    function clearAll(){
        setMedicamento({id: '', qtd: 1});
        setTipoLista(false);
        setAgrupamento(null);
        if(!opened) setNovo(false);
    }
    function adicionar(){

        if(!medicamento.ean) {
            medicamento.qtd = Number(medicamento.qtd);
            medicamento.ean = Number(medicamento.ean);
        }

        if(agrupamento){
            listSave(agrupamento.lista);
        }else{
            save(medicamento);
        }

        clearAll();

    }

    return(
        <div className="medicamentos novo" style={{marginTop: 40}}>
            {
                !novo && (
                    <div className="close">
                        <Grid className="page-actions" container justify="flex-start" alignItems="center">
                            <Button disabled={disabled} variant="contained" onClick={() => setNovo(true)} color="primary">Adicionar</Button>
                        </Grid>
                    </div>
                )
            }
           
            {
                novo && (
                    <div className="open">
                        {
                            !opened && (
                                <Grid container spacing={2}>
                                    <Grid item md={3} xs={12}>
                                        <FormControlLabel
                                            control={<Switch onChange={() => setTipoLista(!tipoLista)} color="primary" name="checkedA" />}
                                            label="Grupo de medicamentos"
                                        />
                                    </Grid>
                                </Grid>
                            )
                        }
                       
                        <Grid container spacing={2}>
                            {
                                !tipoLista ? (
                                    <>
                                        <Grid item md={3} xs={12}>
                                            <AutoComplete 
                                                list={createNestedList} 
                                                onChangeHandle={setMedicamento} 
                                                label={"Ean"}
                                                object={'ean'} 
                                                value={medicamento} 
                                            />
                                        </Grid>
                                        <Grid item md xs={12}>
                                            <AutoComplete 
                                                list={createNestedList} 
                                                onChangeHandle={setMedicamento} 
                                                label={ "Medicamento" }
                                                object={'med_apresenta'} 
                                                value={medicamento} 
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12}>
                                            <div className="input-wrapper">
                                                <label htmlFor="input-2124">qtd</label>
                                                <input type="number" value={medicamento.qtd || ""} onChange={(evt) => setMedicamento({...medicamento, qtd: evt.target.value})} id="input-2124" />
                                            </div>
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <div className="input-wrapper">
                                                <label htmlFor="input-2124">Posologia</label>
                                                <textarea value={medicamento.posologia || ""} onChange={(evt) => setMedicamento({...medicamento, posologia: evt.target.value})} id="input-2124"></textarea>
                                            </div>
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid item md xs={12}>
                                        <AutoComplete 
                                            list={agrupamentos} 
                                            onChangeHandle={openAgrupamentoDeMedicamentos} 
                                            label={"Lista de Medicamentos"}
                                            object={'titulo'} 
                                            value={''} 
                                        />
                                    </Grid>
                                )
                            }
                           
                        </Grid>
                        <Grid className="page-actions" container justify="flex-start" alignItems="center">
                            <Button disabled={ (!medicamento.ean || !medicamento.posologia) && !agrupamento } variant="contained" onClick={adicionar} color="primary">{tipoLista ? 'Adicionar Lista' : "Incluir"}</Button>
                            <Button variant="contained" onClick={() => clearAll()}>Cancelar</Button>
                        </Grid>
                    </div>
                )
            }
            
        </div>
    )
}

export default NovoMedicamento;