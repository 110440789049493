import React from 'react';
import { Grid } from '@material-ui/core';
// import logov1 from "assets/img/logos/logov1.jpg";

import Profile from "components/home/profile";
import Statistics from "components/home/statistics";
import Queue from "components/queue";
import Table from "components/home/table";

import "./index.scss";

function Dashboard(){

    document.title = 'BSI - Dashboard';

    return ( 
        <>
            <Grid style={{width: "100%", margin: 0}} container spacing={2}>
                <Grid item md={3} xs>
                    <Profile/>
                </Grid>
                <Grid item md>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            {/* <Statistics /> */}
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Queue />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md xs>
                            {/* <Table /> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
    
}

export default Dashboard;