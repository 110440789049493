import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper, CircularProgress } from '@material-ui/core';
import "./index.scss";
import axios from 'axios';
import { dateParser } from "others/services";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import useApi from 'hooks/useApi';

function Exames(){
    const api = useApi();
    document.title = 'BSI - Lista de Diagnosticos';

    const [solicitacoes, setDiagnosticos] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        loadDiagnosticos();
    }, []);

    async function loadDiagnosticos(){

        try {
            const request = await api.get("/diagnosticos");
            const response = request.data.payload;
            setDiagnosticos(response);
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
    }

    async function doCancel(id){

        try {
            
            const request = await api.del(`/diagnosticos/${id}`);
            
            const newDiagnosticos = solicitacoes.map(value => {
                if(value.id === id) value.situacao_id = request.payload.situacao_id;
            });

            setDiagnosticos(newDiagnosticos);
        } catch (error) {
            
        }

    }


    function cancel(id){

        Swal.fire({
            title: 'Solicitação de Cancelamento',
            text: 'Esse processo é irreversivel, tem certeza?!',
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: 'Sim, cancelar!',
            cancelButtonText: 'Fechar'
        }).then((result) => {
            if (result.value) doCancel(id);
        });
        
    }

    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <>
            <Grid className="procedimentos listar" container spacing={2}>
                <div className="page-box">
                    <div className="title">
                        Lista de Diagnósticos
                    </div>
                    <Paper elevation={3} className="content">
                    <table>
                        <thead>
                            <tr>
                                <th>Protocolo</th>
                                <th>Titulo</th>
                                <th>Tipo</th>
                                <th>Beneficiário</th> 
                                <th>Urgência</th> 
                                <th>Data</th> 
                                <th>Situação</th> 
                                <th>Ações</th> 
                            </tr>
                        </thead>
                        <tbody>
                            {
                                solicitacoes.map(value => (
                                    <tr key={value.id}>
                                        <td>#{value.id}</td>
                                        <td>{value.titulo}</td>
                                        <td>{value.tipo.titulo}</td>
                                        <td>{value.beneficiario.nome}</td>
                                        <td>{value.gravidade.titulo}</td>
                                        <td>{ dateParser(value.created_at)}</td>
                                        <td>{value.situacao.titulo}</td>
                                        <td>
                                            <Link className="btn blue-bg small" to={`${value.id}`}>Editar</Link>
                                            <a className="btn bg-bg small" onClick={() => cancel(value.id)}>Cancelar</a>
                                        </td>
                                    </tr>
                                ))
                            }
                           
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                    <Grid className="page-actions borded" container justify="flex-start" alignItems="center">
                        <Link to='novo' variant="contained" className="btn blue-bg" color="primary">Adicionar</Link>
                    </Grid>
                    </Paper>
                </div>

            </Grid>

        </>
    )
    
}

export default Exames;