import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { Grid, Paper, CircularProgress } from "@material-ui/core";
import useQueryParams from "../../../hooks/useQueryParams";
import sideBackground from "assets/img/pexels-photo-2280547.jpeg";
import logov1 from "assets/img/logos/logov1-transparent.png";
import useFetcher from "hooks/useFetcher";

const Auth = (props) => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const fetcher = useFetcher();

  const searchParams = async () => {
    if (!queryParams.hasValues()) {
      history.push("/login");
      return false;
    }

    const params = queryParams.getValues();

    if (!params?.token) {
      history.push("/login");
      return false;
    }

    const response = await fetcher.post("atendimentos/redirect-to", {
      token: params.token,
    });

    if (!response) {
      history.push("/login");
      return false;
    }

    const usuario = {
      ...response.usuario,
      role: response.role
    };

    const temp = {
      auth: { token: response.token, role: response.role },
      user: usuario,
    };

    localStorage.setItem("@bsi", JSON.stringify(temp));

    history.push(response.redirect);
  };

  useEffect(() => {
    searchParams();
  }, []);

  return (
    <>
      <Grid
        className="wrapper-full auth signin"
        container
        justify="center"
        alignItems="center"
      >
        <Grid item md={4} xs={12}>
          <Paper square>
            <Grid container>
              <Grid item md={12}>
                <div
                  className="content"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "1rem 1rem",
                  }}
                >
                  <img
                    src={logov1}
                    style={{ maxWidth: "100%", width: "200px", margin: "auto" }}
                  />
                  <h1>Por favor aguarde...</h1>
                  <CircularProgress
                    size="2rem"
                    style={{ marginRight: "0.5rem" }}
                  />
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      paddingTop: ".5rem",
                    }}
                  >
                    Buscando seus dados para continuar.
                  </div>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Auth;
