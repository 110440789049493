import { dateDayChecker } from "others/services";
import useFetcher from "./useFetcher";

export default function useStatusSaver() {
  const fetcher = useFetcher();

  const getTodayData = (status) => {
    const localData = JSON.parse(localStorage.getItem("@bsi_status"));
    if (
      !localData ||
      typeof localData === "undefined" ||
      typeof localData.data === "undefined"
    )
      return null;

    if (localData.data < dateDayChecker(new Date())) return null;

    if (localData.status !== status) return null;

    return localData;
  };

  const saveTodayData = (status) => {
    const localData = {
      data: dateDayChecker(new Date()),
      status: status,
    };
    localStorage.setItem("@bsi_status", JSON.stringify(localData));
  };

  async function saveStatus(status) {
    const response = await fetcher.post("prestadores/dashboard/status", {
      status: status,
    });

    if (!response) return false;

    saveTodayData(status);
  }

  async function setUserStatus(status) {
    const lastStatus = getTodayData(status);
    if (lastStatus !== null) return true;

    await saveStatus(status);

    return true;
  }

  return { setUserStatus };
}
