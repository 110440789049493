import React, { useEffect, useState } from 'react';
import { Grid, Paper, Button, CircularProgress } from '@material-ui/core';
import Prontuario from "components/prontuario";
import { useHistory, useParams } from "react-router-dom";
import Jitsi from 'react-jitsi';
import "./index.scss";
import axios from 'axios';
import { useStateValue } from 'contexts';
import useApi from 'hooks/useApi';
import useFetcher from 'hooks/useFetcher';

function Atendimento(){
    const api = useApi();
    const fetcher = useFetcher();
    const params = useParams();
    const history = useHistory();

    const [atendimento, setAtendimento] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [{ user : { nome, email, imagem_url } }] = useStateValue();
    
    useEffect(() => {
        getAtendimento();
    }, []);

    async function getAtendimento() {

        try {
            const { id } = params;
            const request = await api.get(`/atendimentos/${id}`);
            const response = request.data.payload;
            setAtendimento(response);
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
     
        
    }

    async function endAtendimento() {
        const { id } = params;

        try {
            await fetcher.post('atendimentos/encerrar', {
                id: id
            });
            
            history.replace("../dashboard");
        } catch (error) {
            
        }
    }


    document.title = 'BSI - Atendimento';

    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <>
            <Grid style={{width: "100%", margin: 0}} className="atendimento" container spacing={2}>
                <Grid item md={4} xs={12}>
                    <Jitsi domain="meet.bsibr.com" roomName={`bsi-atendimento-${atendimento.id}`} displayName={nome} />
                </Grid>

                <Grid item md={8} xs={12}>
                    <Paper elevation={3} style={{backgroundColor: "#f6f6f6", paddingBottom: 10}} className="prontuario">
                        <Prontuario id={atendimento.beneficiario_id} />
                        <Grid className="page-actions" container justify="flex-end" alignItems="center">
                            <Button variant="contained" color="inherit" className="red-bg" onClick={() => endAtendimento()}>Encerrar Atendimento</Button>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
    
}

export default Atendimento;