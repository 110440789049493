import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Dashboard from "./dashboard";

import Unidades from "./unidades";
import UnidadesEditar from "./unidades/editar";

import Prestadores from "./prestadores";
import PrestadoresEditar from "./prestadores/editar";

import Notificacoes from "pages/notificacoes";
import NotificacoesEditar from "pages/notificacoes/editar";
import MinhasNotificacoes from "pages/notificacoes/single";

import Triagem from "./triagem";
import TriagemEditar from "./triagem/editar";

import FAQ from "./faq";
import FAQEditar from "./faq/editar";

import Estatisticas from "./estatisticas";
import Faq from './faq';

const Auth = () => {
    let { path } = useRouteMatch();

    return ( 
        <Switch>
            <Route exact path={path}>
                <Dashboard />
            </Route>
            <Route exact path={`${path}/dashboard`}>
                <Dashboard />
            </Route>
            <Route exact path={`${path}/unidades`}>
                <Unidades />
            </Route>
            <Route exact path={`${path}/unidades/:id`}>
                <UnidadesEditar />
            </Route>
            <Route exact path={`${path}/prestadores`}>
                <Prestadores />
            </Route>
            <Route exact path={`${path}/prestadores/:id`}>
                <PrestadoresEditar />
            </Route>
            <Route exact path={`${path}/notificacoes`}>
                <Notificacoes />
            </Route>
            <Route exact path={`${path}/notificacoes/:id`}>
                <NotificacoesEditar />
            </Route>
            <Route exact path={`${path}/minhas-notificacoes/:id`}>
                <MinhasNotificacoes />
            </Route>
            <Route exact path={`${path}/triagem`}>
                <Triagem />
            </Route>
            <Route exact path={`${path}/triagem/:id`}>
                <TriagemEditar />
            </Route>
            <Route exact path={`${path}/estatisticas`}>
                <Estatisticas />
            </Route>
            <Route exact path={`${path}/faq`}>
                <FAQ />
            </Route>
            <Route exact path={`${path}/faq/:id`}>
                <FAQEditar />
            </Route>
        </Switch>
    )
    
}

export default Auth;