import React, { useEffect, useRef, useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Chip from '@material-ui/core/Chip';
import { FiChevronDown } from "react-icons/fi";
import axios from "axios";
import { useStateValue } from 'contexts';
import useApi from 'hooks/useApi';
import useStatusSaver from 'hooks/useStatusSaver';

export default function MenuListComposition() {
  const api = useApi();
  const { setUserStatus } = useStatusSaver();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState({titulo: "Indisponivel", slug: "indisponivel"});
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const [{ user : { id } }, dispatch] = useStateValue();

  const statusList = [
    {titulo: "Online", slug: "online"},
    {titulo: "Offline", slug: "offline"},
    {titulo: "Ocupado", slug: "ocupado"},
    {titulo: "Em intervalo", slug: "intervalo"}
  ];

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if(id) getCurrentStatus();
  }, [id]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };

  function updateGlobalActivity(value){

    dispatch({
      type: 'setActivity',
      payload: value
    });

  }

  async function updateStatus(value){

    try {
      setStatus(value);
      setOpen(false);
      updateGlobalActivity(value);
      await api.post(`/usuarios/atividades`, value);
      setUserStatus(value.slug);
    } catch (error) {
      
    }
  
  }

  async function getCurrentStatus(){

    try {
      const request = await api.get(`public/usuarios/${id}/atividade`);
      const response = request.data.payload;
      if(response) {
        setStatus(response);
        updateGlobalActivity(response);
        setUserStatus(response.slug);
      }
    } catch (error) {
      
    }
   
  }

  return (
    <div className='activity'>
      <div>
        <span
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
        {
          <Chip
            label={status.titulo}
            clickable
            className={`status ${status.slug}`}
            size="small"
            deleteIcon={<FiChevronDown />} onDelete={() => console.log("")}
          />
        }
        </span>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                    <div className="wrapper">
                        {
                            statusList.map((value, i) => (
                                <div onClick={() => updateStatus(value)} className={`nav ${status.slug === value.slug ? 'active' : ''}`} key={i}><span>{value.titulo}</span></div>
                            ))
                        }
                    </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}