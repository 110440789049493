import React from 'react';
import { Grid, Paper } from '@material-ui/core';

import "./index.scss";
import Tuss from "assets/json/tuss.json";

function AgrupamentosLista(){

    document.title = 'BSI - Agrupamento de Procedimentos';

    return ( 
        <>
            <Grid className="procedimentos listar" container spacing={2}>
                <div className="page-box">
                    <div className="title">
                        Lista de Procedimentos
                    </div>
                    <Paper elevation={3} className="content">
                    <table>
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Descrição</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Tuss.map((value, i) => (
                                <tr key={i}>
                                    <td>#{value.id}</td>
                                    <td>{value.desc}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                    </Paper>
                </div>

            </Grid>

        </>
    )
    
}

export default AgrupamentosLista;