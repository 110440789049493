import React, { useState, useEffect } from 'react';
import { Grid, Paper, LinearProgress, CircularProgress } from '@material-ui/core';
import axios from "axios";
import "./index.scss";
import { FiUser, FiBook, FiClipboard, FiMapPin } from "react-icons/fi";
import ReactMapGL, { Marker } from 'react-map-gl';
import useApi from 'hooks/useApi';

function Map() {
    const api = useApi();
    const [pacientes, setPacientes] = useState([]);
    const [markers, setMarkers] = useState([]);

    useEffect(() => {
        loadPacientes();
    }, []);

    async function loadPacientes(){
        const request = await api.get('/beneficiarios');
        const response = request.data.payload;
        setPacientes(response);
    }

    const [viewport, setViewport] = useState({
      width: "100%",
      height: 360,
      latitude: -20.1967316,
      longitude: -40.266438,
      zoom: 13
    });

    return (
      <ReactMapGL
        {...viewport}
        mapboxApiAccessToken={"pk.eyJ1IjoiZGV2Y29zdGEiLCJhIjoiY2tjemt6eWZqMGt0MjJ5cGpiN3R6OWJjbSJ9.cVpjDDts5jhmWgCzTwBmqg"}
        onViewportChange={nextViewport => setViewport(nextViewport)}
      >
        <div className="nav">
            {
                pacientes.map((paciente, index) => {

                    if(!paciente.latitude || !paciente.longitude) return null;

                    const lat = Number(paciente.latitude);
                    const long = Number(paciente.longitude);

                    return (
                        <div key={paciente.id}> 
                            <Marker longitude={long} latitude={lat}>
                                <FiMapPin color="red" size={32} />
                            </Marker>
                        </div>
                    )

                }) 
            }
        </div>
      </ReactMapGL>
    );
}

function Estatisticas(){
    const api = useApi();
    document.title = 'BSI - Estatisticas';

    const [diagnosticos, setDiagnosticos] = useState({ total: 0 });
    const [solicitacoes, setSolicitacoes] = useState({ total: 0 });
    const [triagens, setTriagens] = useState({ total: 0 });
    const [beneficiarios, setBeneficiarios] = useState({ total: 0 });
    const [sintomas, setSintomas] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        loadEstatisticas();
        loadBeneficiarios();
        loadSintomas();

        setTimeout(() => {
            setLoading(false);
        }, 800);

    }, []);

    async function loadSintomas(){
        const request = await api.get("/estatisticas/sintomas");
        const response = request.data.payload;

        const principais = response.sintomas.sort((a, b) => b.total - a.total);
        const order = principais.filter((value, i) => i < 5);

        setSintomas(order);
        
        setTriagens({total: response.triagens});
    }

    async function loadEstatisticas(){
        const request = await api.get("/estatisticas/resumo");
        const response = request.data.payload;

        setDiagnosticos(response.diagnosticos);
        setSolicitacoes(response.solicitacoes);

    }

    async function loadBeneficiarios(){
        const request = await api.get("/beneficiarios");
        const response = request.data.payload;
        setBeneficiarios({total: response.length});
    }

    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <>
            <div className="estatisticas">
                <Grid container className="main-cards" spacing={2}>
                    <Grid item md={4}>
                        <Paper className="card-mod-1" elevation={3}>
                            <h4 className="card-title">Beneficiários</h4>
                            <Grid container alignItems="center">
                                <div className="card-icon purple-bg"><FiUser /></div>
                                <div className="card-icon-text purple-text">{beneficiarios.total}</div>
                                <p><a href="#">Clique aqui</a> parar listar todos os beneficiarios cadastrados no periodo</p>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper className="card-mod-1" elevation={3}>
                            <h4 className="card-title">Solicitações</h4>
                            <Grid container alignItems="center">
                                <div className="card-icon green-bg"><FiClipboard /></div>
                                <div className="card-icon-text green-text">{solicitacoes.total}</div>
                                <p><a href="#">Clique aqui</a> parar listar todas solicitações realizados no periodo</p>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item md={4}>
                        <Paper className="card-mod-1" elevation={3}>
                            <h4 className="card-title">Diagnosticos</h4>
                            <Grid container alignItems="center">
                                <div className="card-icon blue-bg"><FiBook /></div>
                                <div className="card-icon-text blue-text">{diagnosticos.total}</div>
                                <p><a href="#">Clique aqui</a> parar listar todos Diagnosticos realizados no periodo</p>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginTop: 10}}>
                    <Grid item md={4}>
                        <Paper className="card total-progress" elevation={3}>
                            <h4 className="card-title">Sintomas mais comuns</h4>
                            <Grid container className="infos">
                                {
                                    sintomas.map(value => 
                                        <Grid container className="info" direction="column" key={value.id}>
                                            <Grid container justify="space-between">
                                                <h5>{value.titulo}</h5>
                                                <h5><b>{value.total}</b></h5>
                                            </Grid>
                                            <LinearProgress className="progress-blue" variant="determinate" value={value.total / triagens.total * 100} />
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item md={8}>
                        <Paper elevation={3} className="map">
                            <Map />
                        </Paper>
                    </Grid>
                </Grid>

            </div>
        </>
    )
    
}

export default Estatisticas;