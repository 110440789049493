import React from 'react';
import { Image, View, StyleSheet } from '@react-pdf/renderer';
import logov1 from "assets/img/logos/logov1-transparent.png";

const styles = StyleSheet.create({
    wrapper: {
        // backgroundColor: "red"
    }
});

const DocumentHeader = () => {
    
    return (
        <View style={styles.wrapper}>
            <Image style={{width: 110, alignSelf: "flex-end", marginTop: 30}} src={logov1} />
        </View>
    )
}

export default DocumentHeader;
