import React from 'react';
import { Image, View, Text, StyleSheet, Font } from '@react-pdf/renderer';
// import logov1 from "assets/img/logos/logov1-transparent.png";

import footer from "assets/img/footer.png";

import marker from "assets/img/icons/map-marker.png";
import phone from "assets/img/icons/phone.png";
import web from "assets/img/icons/web-white.png";
import qrcode from "assets/img/icons/qrcode.jpg";

import Certificado from "./certificado";

Font.register({ family: 'Roboto', fonts: [
    { src: require("assets/fonts/Roboto/Roboto-Regular.ttf") }, 
    { src: require("assets/fonts/Roboto/Roboto-Light.ttf"), fontWeight: 300 },
    { src: require("assets/fonts/Roboto/Roboto-Medium.ttf"), fontWeight: 500 },
    { src: require("assets/fonts/Roboto/Roboto-Bold.ttf"), fontWeight: 700 },
]});

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        justifyContent: "flex-end"
    },
    informations: {
        width: 150,
        justifyContent: "flex-end"
    },
    infoTitle: {
        fontSize: 9, 
        textAlign: "right", 
        color: "#343130", 
        fontWeight: "medium",
        fontFamily: "Roboto"
    },
    infoText: {
        fontSize: 7, 
        textAlign: "right", 
        color: "#343130",
        margin: 0
    },
    footer: {
        alignSelf: "flex-end"
    }
});

const DocumentHeader = ({certificado}) => {
    
    return (
        <View style={styles.wrapper}>
            
            <View style={{flexDirection :"row", marginHorizontal: certificado ? 50 : 0, alignItems: "center", justifyContent: "space-around"}}>
                
                <View style={{alignSelf: "flex-start"}}>
                    {
                        certificado &&
                        <View style={{height: 60, width: 160, marginBottom: 10}}>
                            <Certificado certificado={certificado} />
                        </View>
                    }

                </View>
                
                <View style={{flexDirection: "row", alignSelf: "flex-end"}}>

                    <View style={styles.informations}>
                        <View style={{flexDirection: "row"}}>
                            <View style={{flex: .8}}>
                            <Text style={styles.infoTitle}>Contatos</Text>
                            <Text style={styles.infoText}>(27) 99640-4654</Text>
                            <Text style={styles.infoText}>(27) 99611-5494 ou (27) 3241-1112</Text>
                            </View>
                            <View style={{flex: .2, alignItems: "flex-end", justifyContent: "center"}}>
                                <Image src={phone} style={{width: 24, height: 24, backgroundColor: "#4cbbc9", padding: 5}}/>
                            </View>
                        </View>
                        <View style={{flexDirection: "row", marginVertical: 10}}>
                            <View style={{flex: .8}}>
                            <Text style={styles.infoTitle}>Web</Text>
                            <Text style={styles.infoText}>contato@bsisaude.com.br</Text>
                            <Text style={styles.infoText}>www.bsisaude.com.br</Text>
                            </View>
                            <View style={{flex: .2, alignItems: "flex-end", justifyContent: "center"}}>
                                <Image src={web} style={{width: 24, height: 24, backgroundColor: "#343130", padding: 5}}/>
                            </View>
                        </View>
                        <View style={{flexDirection: "row"}}>
                            <View style={{flex: .8}}>
                            <Text style={styles.infoTitle}>Endereço</Text>
                            <Text style={styles.infoText}>Rua Camacua, número 41, Porto Canoa, Serra, ES, CEP 29168695</Text>
                            </View>
                            <View style={{flex: .2, alignItems: "flex-end", justifyContent: "center"}}>
                                <Image src={marker} style={{width: 24, height: 24, backgroundColor: "#4cbbc9", padding: 5}}/>
                            </View>
                        </View>
                    </View>

                    <Image src={qrcode} style={{width: 80, height: 80, margin: 10, marginRight: -10}} />

                </View>

            </View>

            <View style={styles.footer}>
                <Image src={footer} style={{height: "auto", width: "100%"}} />
            </View>

        </View>
    )
}

export default DocumentHeader;
