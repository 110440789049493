import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid, Paper } from '@material-ui/core';
import "./index.scss";
import axios from "axios";
import { dateParser } from "others/services";
import { Link } from 'react-router-dom';
import useApi from 'hooks/useApi';

function Exames(){
    const api = useApi();
    document.title = 'BSI - Lista de Pacientes';

    const [pacientes, setPacientes] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        loadPacientes();
    }, []);

    async function loadPacientes(){

        try {
            const request = await api.get('/beneficiarios');
            const response = request.data.payload;
            setPacientes(response);
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
      

    }

    async function removeExame(id){
        alert("ainda não pode remover um beneficiario");
    }

    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <>
            <Grid className="procedimentos listar" container spacing={2}>
                <div className="page-box">
                    <div className="title">
                        Lista de Pacientes
                    </div>
                    <Paper elevation={3} className="content">
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nome</th> 
                                <th>Data</th> 
                                <th>Telefone</th> 
                                <th>Ações</th> 
                            </tr>
                        </thead>
                        <tbody>
                            {
                                pacientes.map(value => (
                                    <tr key={value.id}>
                                        <td>#{value.id}</td>
                                        <td>{value.nome}</td>
                                        <td>{dateParser(value.created_at)}</td>
                                        <td>{value.telefone}</td>
                                        <td>
                                            <Link className="btn blue-bg small" to={`${value.id}`}>Editar</Link>
                                            <a className="btn gray-bg small" onClick={() => removeExame(value.id)}>Remover</a>
                                        </td>
                                    </tr>
                                ))
                            }
                           
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                    </Paper>
                </div>

            </Grid>

        </>
    )
    
}

export default Exames;