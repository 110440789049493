import React, { useEffect } from 'react';
import { Switch, Route, useHistory, useRouteMatch } from "react-router-dom";
import Header from "components/header";
import { useStateValue } from 'contexts';

import Administrador from "./administrador";
import Entidade from "./entidade";
import Unidade from "./unidade";
import Prestador from "./prestador";

function Private({permission, Component}){

    let history = useHistory();

    try {
    
        const localData = JSON.parse(localStorage.getItem("@bsi"));

        const { auth: { token, role } } = localData;
    
        if(!token || role !== permission) history.push("/login");

        return (
            <>
                <Header />
                <div className="app wrapper">
                    <Component />
                </div>
            </>
        )

    } catch (error) {

        setTimeout(() => {
            history.push("/login");
        }, 500);

        return (
            <>
                <div className="app wrapper">
                </div>
            </>
        )
    }
   

}

function loadDefault(dispatch){

    function loadUser(){
        const localData = JSON.parse(localStorage.getItem("@bsi"));
        
        if(!localData) return;

        dispatch({
            type: 'setUser',
            payload: localData.user
        });

    }

    loadUser();
}

function App(){
    
    let { path, isExact } = useRouteMatch();
    let history = useHistory();

    const [{}, dispatch] = useStateValue();

    useEffect(() => loadDefault(dispatch), []);
    
    useEffect(() => {

        if(path === '/app' && isExact) {
            const localData = JSON.parse(localStorage.getItem("@bsi"));
            const { auth: { role }} = localData;

            if(role === 'admin') return history.push("/app/administrador/dashboard");
            if(role === 'entidade') return history.push("/app/entidade/dashboard");
            if(role === 'unidade') return history.push("/app/unidade/dashboard");
            if(role === 'prestador') return history.push("/app/prestador/dashboard");
        }

    },[path, isExact]);

    return ( 
        <Switch>
            <Route path={path+'/administrador'}>
                <Private permission={"admin"} Component={Administrador} />
            </Route>
            <Route path={path+'/entidade'}>
                <Private permission={"entidade"} Component={Entidade} />
            </Route>
            <Route path={path+'/unidade'}>
                <Private permission={"unidade"} Component={Unidade} />
            </Route>
            <Route path={path+'/prestador'}>
                <Private permission={"prestador"} Component={Prestador} />
            </Route>
        </Switch>
    )
    
}

export default App;