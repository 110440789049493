import React from "react";
import { useLocation } from "react-router-dom";

function useQueryParams() {
  const location = useLocation();

  function hasValues() {
    if (location.search === "") return false;

    return true;
  }
  function getValues(options = {}) {
    const urlparams = location.search;
    const searchParams = new URLSearchParams(urlparams.replace("?", ""));

    let dados = {};
    for (var pair of searchParams.entries()) {
      if (pair[0] in options) {
        if (Array.isArray(options[pair[0]])) {
          dados[pair[0]] = [];
          if (pair[1].includes(",")) {
            dados[pair[0]] = pair[1].split(",");
          } else if (pair[1] !== "") {
            dados[pair[0]].push(pair[1]);
          }
        } else {
          dados[pair[0]] = pair[1];
        }
      } else {
        dados[pair[0]] = pair[1];
      }
    }
    return dados;
  }

  function setGetData(data) {
    const urlAppender = new URLSearchParams(data).toString();
    window.history.replaceState(
      null,
      null,
      `/app/paciente/agendamentos/novo?${urlAppender}`
    );
  }

  return { getValues, setGetData, hasValues };
}

export default useQueryParams;
