const actions = (state, payload) => (
    {
        setUser: () => {
            return { ...state, user: payload }
        },
        setFlash: () => {
            return { ...state, flash: payload }
        },
        setActivity: () => {
            return { ...state, activity: payload }
        },
        
    }
)

export default actions;