const navigation = {

    admin: {
        toolbar:[
            { title: "", path: "" },
        ],
        shortcuts:[
            { title: "Inicio", path: "/app/administrador/dashboard", icon: "FiHome" },
            { title: "Entidades", path: "/app/administrador/entidades", icon: "FiServer" },
            { title: "Notificacoes Internas", path: "/app/administrador/notificacoes", icon: "FiBell" },
            { title: "Configurações", path: "/app/administrador/configuracoes", icon: "FiSettings" },
        ]
    },
    unidade: {
        toolbar:[
        ],
        shortcuts:[
            { title: "Inicio", path: "/app/unidade/dashboard", icon: "FiHome" },
            { title: "Agenda", path: "/app/unidade/agenda", icon: "FiCalendar" },
            { title: "Serviços", path: "/app/unidade/servicos", icon: "FiBox" }
        ]
    },
    entidade: {
        toolbar:[
        ],
        shortcuts:[
            { title: "Inicio", path: "/app/entidade/dashboard", icon: "FiHome" },
            { title: "Unidades", path: "/app/entidade/unidades", icon: "FiGlobe" },
            { title: "Prestadores", path: "/app/entidade/prestadores", icon: "FiUsers" },
            { title: "Atendimentos", path: "/app/entidade/atendimentos", icon: "FiBook" },
            { title: "Triagem", path: "/app/entidade/triagem", icon: "FiMap" },
            { title: "Notificacoes Internas", path: "/app/entidade/notificacoes", icon: "FiBell" },
            { title: "Perguntas Frequentes", path: "/app/entidade/faq", icon: "FiAlertOctagon" },
            { title: "Relatórios", path: "/app/entidade/relatorios", icon: "FiFileText" },
            { title: "Estatisticas", path: "/app/entidade/estatisticas", icon: "FiRss" },
        ]
    },
    prestador:{
        toolbar:[
        ],
        shortcuts:[
            { title: "Inicio", path: "/app/prestador/dashboard", icon: "FiHome" },
            { title: "Atendimentos", path: "/app/prestador/atendimentos", icon: "FiBook" },
            { title: "Solicitações", icon: "FiCompass", children: [
                { title: "Listar Solicitações", icon: "FiCompass", path: "/app/prestador/solicitacoes/lista" },
                { title: "Kit de Medicamentos", icon: "FiArchive", path: "/app/prestador/medicamentos/agrupamentos/lista" },
                { title: "Kit de Procedimentos", icon: "FiArchive", path: "/app/prestador/procedimentos/agrupamentos/lista" },
            ] },
            { title: "Diagnósticos", path: "/app/prestador/diagnosticos/lista", icon: "FiClipboard" },
            { title: "Pacientes", path: "/app/prestador/pacientes/lista", icon: "FiUsers" },
            { title: "Relatórios", path: "/app/prestador/relatorios", icon: "FiFileText" },
            { title: "Tabelas", icon: "FiCompass", children: [
                { title: "Procedimentos", path: "/app/prestador/procedimentos/lista" },
                { title: "Medicamentos", path: "/app/prestador/medicamentos/lista" },
            ] },
            { title: "Estatisticas", path: "/app/prestador/estatisticas", icon: "FiRss" },
        ]
    },
    auditor:{
        toolbar:[
            { title: "Inicio", path: "/app/dashboard" },
            { title: "Assinatura Digital", path: "" },
            { title: "Exame Eletrônico", children: [
                { title: "Exames", path: "/app/exames/lista" },
                { title: "Lista de Procedimentos", path: "/app/procedimentos/lista" },
                { title: "Grupo de Procedimentos", path: "/app/procedimentos/agrupamentos/lista" },
            ] },
            { title: "Auditoria Eletrônica", path: "" },
            { title: "Atendimento Virtual", path: "/app/atendimento" }
        ],
        shortcuts:[
            { title: "Atalho 1", path: "", icon: "" },
        ]
    }

    
}

export default navigation;