import React from 'react';
import { Grid, Button } from '@material-ui/core';
import Swal from 'sweetalert2';

function Actions({formState, doSigning, doClear, doPrint, doSave, doCancel}){

    function sign(){

        Swal.fire({
            title: 'Deseja assinar o arquivo?',
            text: 'Quando for assinado o documento terá validade juridica!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, continuar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                
                Swal.fire({
                    title: 'Assinando...'
                });
                Swal.showLoading();

                doSigning();

            }
        });

    }
    
    function cancel(){

        Swal.fire({
            title: 'Solicitação de Cancelamento',
            text: 'Esse processo é irreversivel, tem certeza?!',
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: 'Sim, cancelar!',
            cancelButtonText: 'Fechar'
        }).then((result) => {
            if (result.value) doCancel();
        });

    }

    function save(){

        Swal.fire({
            title: 'Você concluiu a edição?',
            text: 'Após salvar não será possivel editar, você terá que solicitar o cancelamento e fazer novamente.',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            cancelButtonText: 'Manter em Rascunho'
        }).then((result) => {
            if (result.value) doSave(true);
            if (!result.value) doSave();
        });


    }

    const draft = formState.situacao === 1;
    const finished = formState.situacao === 4;
    const canceled = formState.situacao === 5;

    return ( 
        
        <Grid className="page-actions" container justify="flex-end" alignItems="center">

            { formState.saved && !draft && 
                <div>
                    { !canceled && <Button disabled={finished} variant="contained" target="_blank" color="secondary" onClick={sign}>{ finished ? "Assinado" : "Assinar" }</Button> }
                    <Button variant="contained" target="_blank" color="primary" onClick={doPrint}>Relatório</Button>
                    { !canceled && <Button variant="contained" color="inherit" className="red-bg" onClick={cancel}>Cancelar</Button> }
                </div>
            }

            { (!formState.saved || draft) && 
                <div>
                    <Button variant="contained" onClick={doClear}>Limpar</Button>
                    <Button variant="contained" onClick={save} color="primary">Salvar</Button>
                </div>
            }
            
        </Grid>
    )
    
}

export default Actions;