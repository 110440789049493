import React from 'react';
import { Page, Font, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { cpfMask, cellPhoneMask, phoneMask } from "others/services";

import Header from "../header";
import Footer from "../footer";

Font.register({ family: 'Roboto', fonts: [
  { src: require("assets/fonts/Roboto/Roboto-Regular.ttf") }, 
  { src: require("assets/fonts/Roboto/Roboto-Light.ttf"), fontWeight: 300 },
  { src: require("assets/fonts/Roboto/Roboto-Medium.ttf"), fontWeight: 500 },
  { src: require("assets/fonts/Roboto/Roboto-Bold.ttf"), fontWeight: 700 },
]});

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    margin: 0,
    padding: 0
  },
  header: {
    flex: .15,
    paddingHorizontal: 80,
    // backgroundColor: "#29323b",
    justifyContent: "center"
  },
  content: {
    paddingHorizontal: 80,
    flex: .5
  },
  footer: {
    flex: .35
  },
  personTitle: {
    textTransform: "uppercase",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 12,
    marginBottom: 10
  },
  personName: {
    fontWeight: 400,
    fontFamily: "Roboto",
    fontWeight: 500,
    color: "#40a8b0",
    fontSize: 16
  },
  personSmall: {
    fontWeight: 300,
    fontFamily: "Roboto",
    color: "#595553",
    fontSize: 9,
    marginTop: 5,
  },
  people: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  prescription: {
    marginVertical: 20,
    minHeight: 200,
    justifyContent: "center"
  },
  prescriptionText: {
    fontSize: 10,
    color: "#615e5c",
    fontWeight: "300",
    marginVertical: 10,
    letterSpacing: 1,
    textAlign: "justify"
  },
  signature: {
    height: 80
  },
  certificado: {
    
  }
});

const MyDocument = ({ prestador, beneficiario, diagnostico, certificado }) => {

  return(
    <Document>
        <Page size="A4" style={styles.page}>

          { diagnostico.situacao.id === 5 && 
              <Text style={{fontSize: 100, position: "absolute", transform: "rotate(-45deg)", zIndex: 999999, opacity: .2, top: "40%", left: "10%" }}>Cancelado</Text>
          }

          <View style={styles.header}>
              <Header />
          </View>

          <View style={styles.content}>

            <View style={styles.people}>
              
              <View style={styles.person}>
                <Text style={styles.personTitle}>Paciente:</Text>
                <Text style={styles.personName}>{beneficiario.nome}</Text>
                <Text style={styles.personSmall}>Desenvolvedor - BSI Saúde & Tecnologia.</Text>
                { beneficiario.cpf && <Text style={styles.personSmall}>CPF: { cpfMask(beneficiario.cpf) }</Text> }
                <Text style={styles.personSmall}>{beneficiario.email}</Text>
              </View>
              
            </View>

            <View style={styles.prescription}>

              <Text style={styles.prescriptionText}>
                { diagnostico.descricao }
              </Text>
              
            </View>


            <View style={styles.signature}>
              <View style={styles.person}>
                <Text style={[styles.personName, { fontSize: 12, color: "#343130"}]}>Dr. {prestador.nome}</Text>
                <Text style={styles.personSmall}>Médico - BSI Saúde & Tecnologia.</Text>
                <Text style={styles.personSmall}>CRM: {prestador.crm} - {prestador.crm_uf}</Text>
                <Text style={styles.personSmall}>Contatos: { phoneMask(prestador.telefone) } ou { cellPhoneMask(prestador.celular) }</Text>
              </View>

              {/* <Image src={signature} style={{width: 150}}/> */}
            </View>

          </View>

          <View style={styles.footer}>
            <Footer certificado={certificado} />
          </View>

        </Page>
    </Document>
  );

}

export default MyDocument;
