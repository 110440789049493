import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper, Select, Switch, MenuItem, CircularProgress } from '@material-ui/core';

import { useHistory, useParams } from "react-router-dom";
import { useStateValue } from 'contexts';
import axios from "axios";
import "./index.scss";
import { FiArrowLeft } from "react-icons/fi";
import Editor from "components/editor";
import useApi from 'hooks/useApi';

function FAQEditar(){
    const api = useApi();
    const [faq, setFAQ] = useState({ativo: true});
    const [isLoading, setLoading] = useState(true);
    const [{ user: { role } }, dispatch] = useStateValue();

    const history = useHistory();
    const params = useParams();

    useEffect(() => {
        document.title = 'BSI - Editar FAQ';

        const { id } = params;
        const parserID = Number(id);
        if(parserID) loadFaq(parserID);

    }, []);

    async function loadFaq(id){

        try {
            const request = await api.get(`/faq/${id}`);
            const response = request.data.payload;
            setFAQ(response);
            
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
    }

    function showFlash(obj){

        obj.isOpen = true;

        dispatch({
            type: 'setFlash',
            payload: obj
        });

    }

    async function save(){

        
        try {
            const { id } = faq;
            const request = (id) ? await api.put(`faq/${id}`, faq) : await api.post(`faq`, faq);
            const response = request.data;

            showFlash({type: "success", title: "Sucesso", subTitle: response.msg});
            
            setTimeout(() => {
                history.push(`/app/${role}/faq`);
            }, 1000);
            
        } catch (error) {
            showFlash({type: "error", title: "Error", subTitle: "Verifique os dados"});
            console.log(error);
        }
       
    }

    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <>
            <Grid className="faq editar" style={{width: "100%", margin: 0}} container spacing={2}>
                <Grid container>
                    <div className="page-title">
                        <a href="../faq">
                            <FiArrowLeft size={28} />
                            <h3>Voltar</h3>
                        </a>
                    </div>
                </Grid>
                <Grid container>
                    <div className="page-box">
                        <div className="title">
                            Dados da Notificação
                        </div>
                        <Paper elevation={3} className="content">
                            <form>

                                <Grid container spacing={2}>

                                    <Grid item xs>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-1">Titulo</label>
                                            <input defaultValue={faq.titulo} onChange={(evt) => setFAQ({...faq, titulo: evt.target.value})} id="input-1" />
                                        </div>
                                    </Grid>
                                
                                    <Grid item md={1} xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-4">Ativo</label>
                                                <Switch
                                                    checked={faq.ativo}
                                                    color="primary"
                                                    onClick={() => setFAQ({...faq, ativo: !faq.ativo })}
                                                />
                                            </div>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className="input-wrapper">
                                            <label>Descrição</label>
                                            <Editor content={faq.descricao_raw} onChange={(text) => setFAQ({...faq, descricao_raw: text}) } />
                                        </div>
                                    </Grid>
                                </Grid>

                            </form>
                            <br/>
                        </Paper>
                    </div>
                    
                </Grid>

                <Grid className="page-actions" container justify="flex-end" alignItems="center">
                    <Button variant="contained" href="../faq" color="inherit">Cancelar</Button>
                    <Button className="primary-gradient" variant="contained" onClick={save}>Salvar</Button>
                </Grid>


            </Grid>
            
        
        </>
    )
    
}

export default FAQEditar;