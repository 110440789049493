import React, { useState } from 'react';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

function AutoComplete({disabled, label, object, list, value, onChangeHandle}){

    // const filterOptions = createFilterOptions({
    //     startAfter: 0,
    //     limit: 20,
    // });

    const filterOptions = (options, { inputValue }) => {
        
        if(inputValue.length < 2 && options.length > 100) return [];

        const query = inputValue.toLowerCase().split(" ").filter(value => value !== "");

        const firstSearch = options.filter(value => value && value[object] && value[object].toString().toLowerCase().includes(query[0]) );

        if(query.length === 1) return firstSearch;

        const multiSearch = firstSearch.filter(value => {

            const keys = value[object].toLowerCase().split(" ");

            const mult = keys.filter(key => query.find(q => key.includes(q) ));

            if(mult.length === query.length) return true;

        });

        return multiSearch;
    };

    

    return(
        <Autocomplete
            disabled={disabled}
            filterOptions={filterOptions}
            options={list}
            value={value}
            autoHighlight
            freeSolo
            onChange={(evt, value) => onChangeHandle(value || {})}
            getOptionLabel={(option) => option[object] ? option[object].toString() : '' }
            renderInput={(params) =>{
                return <div className="input-wrapper autocomplete">
                    <label>{label}</label>
                    <TextField disabled={disabled} placeholder="Procurar..." {...params} variant="outlined" />
                </div>
            } 
            
            }
        />
    )
}

export default AutoComplete;
