import React, { useState } from 'react';
import Estados from "assets/json/estados.json";
import masks from "others/mask";
import MaskedInput from 'react-text-mask';
import { Grid, Paper, Select, MenuItem, CircularProgress } from '@material-ui/core';
import { useStateValue } from 'contexts';

function Endereco({endereco, changeData}){

    const [isLoading, setLoading] = useState(false);
    const [focusNumber, setFocusNumber] = useState(false);

    const [{}, dispatch] = useStateValue();

    function showFlash(obj){

        obj.isOpen = true;

        dispatch({
            type: 'setFlash',
            payload: obj
        });

    }
    

    function viaCepRequest(cep){

        return new Promise((resolve, reject) => {
            let request = new XMLHttpRequest();
            request.open('get', `https://viacep.com.br/ws/${cep}/json/`, true);
            request.send();
            request.onload = function () {
                if(request.status === 200) {

                    setTimeout(() => {
                        const data = JSON.parse(this.response);
                        if(data.erro) return reject(null);
                        if(!data.erro) return resolve(data);
                    }, 600);

                }else{
                    reject(false);
                }

            }
        });
        
    }
    async function updateCep(cep){

        changeData({...endereco, cep } );
        
        const parser = Number(cep.toString().replace(/\D/g, ''));

        if(cep.length === 9) {

            setLoading(true);

            try {

                const { logradouro, bairro, localidade, uf, cep} = await viaCepRequest(parser);

                const update = { logradouro, bairro, cidade: localidade, estado: uf, cep, complemento: "", numero: null};

                changeData({...endereco, ...update } );

                setFocusNumber(true);

                showFlash({type: "success", title: "Sucesso", subTitle: "Cep Localizado!"});


            } catch (error) {

                const update = { logradouro: null, bairro: null, cidade: null, estado: null, cep, complemento: null, numero: null};
                
                changeData({...endereco, ...update } );

                showFlash({type: "error", title: "Error", subTitle: "Não foi possivel localizar o cep."});

            } finally {
                setLoading(false);
            }

        }


    }

    if(isLoading) {

        return (
            <div className="loading-wrapper" style={{minHeight: "40vh", backgroundColor: "white"}}>
                <CircularProgress />
            </div>
        )

    }

    return(
        <Paper elevation={3} className="content">
            <form>
                <Grid container spacing={2}>
                    <Grid item md={2} xs={6}>
                        <div className="input-wrapper">
                            <label htmlFor="input-11">CEP</label>
                            <MaskedInput guide={false} mask={masks.cep} defaultValue={endereco.cep} onChange={evt => updateCep(evt.target.value) }  id="input-11" />
                        </div>
                    </Grid>
                    <Grid item md xs={6}>
                        <div className="input-wrapper">
                            <label htmlFor="input-12">Endereço</label>
                            <input defaultValue={endereco.logradouro} onChange={(evt) => changeData({...endereco, logradouro: evt.target.value } )} id="input-12" />
                        </div>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <div className="input-wrapper">
                            <label htmlFor="input-13">Número</label>
                            <input autoFocus={focusNumber} defaultValue={endereco.numero} onChange={(evt) => changeData({...endereco, numero: evt.target.value } )} id="input-13" />
                        </div>
                    </Grid>
                </Grid>
                
                <Grid container spacing={2}>
                    <Grid item md xs={6}>
                        <div className="select-wrapper">
                            <label htmlFor="input-14">Estado</label>
                            <Select value={endereco.estado || ''} onChange={(evt) => changeData({...endereco, estado: evt.target.value } )} htmlFor="input-11" variant="outlined" fullWidth>
                                { Estados.map((value, i) => <MenuItem value={value.sigla} key={i}><span>{value.desc}</span></MenuItem>) }
                            </Select> 
                        </div>
                    </Grid>
                    <Grid item md xs={6}>
                        <div className="input-wrapper">
                            <label htmlFor="input-15">Cidade</label>
                            <input defaultValue={endereco.cidade} onChange={(evt) => changeData({...endereco, cidade: evt.target.value } )} id="input-15" />
                        </div>
                    </Grid>
                    <Grid item md xs={6}>
                        <div className="input-wrapper">
                            <label htmlFor="input-16">Bairro</label>
                            <input defaultValue={endereco.bairro} onChange={(evt) => changeData({...endereco, bairro: evt.target.value } )} id="input-16" />
                        </div>
                    </Grid>
                    <Grid item md xs={6}>
                        <div className="input-wrapper">
                            <label htmlFor="input-17">Complemento</label>
                            <input defaultValue={endereco.complemento} onChange={(evt) => changeData({...endereco, complemento: evt.target.value } )} id="input-14" />
                        </div>
                    </Grid>
                </Grid>

            </form>
        </Paper>
    )
}

export default Endereco;