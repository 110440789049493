import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Sadt from "./sadt";
import Diagnostico from "./default/diagnostico";
import Receita from "./default/receita";

function RelatoriosSwitch(){
    let { path } = useRouteMatch();

    var body = document.body;

    body.classList.add("relatorio-wrapper");

    return ( 
        <Switch>
            <Route exact path={path+'/sadt'}>
                <Sadt/>
            </Route>
            <Route exact path={path+'/diagnostico'}>
                <Diagnostico/>
            </Route>
            <Route exact path={path+'/receita'}>
                <Receita/>
            </Route>
        </Switch>
    )
    
}

export default RelatoriosSwitch;