import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid, Paper } from '@material-ui/core';
import axios from "axios";
import "./index.scss";
import { dateParser, phoneMask } from "others/services";
import { useStateValue } from 'contexts';
import useApi from 'hooks/useApi';

function Prontuario({id}){
    const api = useApi();
    const [beneficiario, setBeneficiario] = useState({genero: {}, diagnosticos: [], solicitacoes: []});
    const [isLoading, setLoading] = useState(true);
    const [triagem, setTriagem] = useState("");
    const [{ user: { role } }, dispatch] = useStateValue();

    async function getBeneficiario() {

        try {
            const request = await api.get(`/beneficiarios/${id}`);
            const response = request.data.payload;
            const searchTriagem = response.diagnosticos.filter(value => value.tipo_id === 1);
            const last = searchTriagem[searchTriagem.length - 1];
            if(last) setTriagem(last.descricao);
            setBeneficiario(response);
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
      
    }
    
    useEffect(() => {

        if(id) getBeneficiario();
        
        const loop = setInterval(() => {
            getBeneficiario();
        }, 5000);

        return () => {
            clearInterval(loop);
        }

    }, [id]);

    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <Grid className="prontuario" style={{width: "100%", margin: 0}} container spacing={2}>
            
            <Paper elevation={1} className="content">

                <div className="header">
                    <h2 className="main-title">Prontuário Eletrônico</h2>
                    {/* <h3 className="second-title">Thank you for your order!</h3> */}
                    <h4 className="third-title">Controle do pacientes diagnosticos e solicitações</h4>
                </div>

                <div className="body">

                    <div className="section">

                        <div className="title">
                            <h4>Dados do beneficiario</h4>
                            {
                                beneficiario && beneficiario.id && (
                                    <a className="btn blue-bg small" rel="noopener noreferrer" target="_blank" href={`/app/${role}/pacientes/${beneficiario.id}`}>Atualizar</a>
                                )
                            }
                        </div>

                        <div className="values">
                            <Grid container className="data">
                                <Grid item xs={4}><h5 className="key">Identificação:</h5> </Grid>
                                <Grid item xs><h5 className="value">#{beneficiario.id}</h5></Grid>
                            </Grid>
                            <Grid container className="data">
                                <Grid item xs={4}><h5 className="key">Nome:</h5> </Grid>
                                <Grid item xs><h5 className="value">{beneficiario.nome}</h5></Grid>
                            </Grid>
                            {
                                beneficiario.genero && beneficiario.genero.id && (
                                    <Grid container className="data">
                                        <Grid item xs={4}><h5 className="key">Gênero:</h5> </Grid>
                                        <Grid item xs><h5 className="value">{beneficiario.genero.titulo}</h5></Grid>
                                    </Grid>
                                )
                            }
                            {
                                beneficiario.celular && 
                                <Grid container className="data">
                                    <Grid item xs={4}><h5 className="key">Celular:</h5> </Grid>
                                    <Grid item xs><h5 className="value">{ phoneMask(beneficiario.celular) }</h5></Grid>
                                </Grid>
                            }
                            {
                                beneficiario.email && (
                                    <Grid container className="data">
                                        <Grid item xs={4}><h5 className="key">Email:</h5> </Grid>
                                        <Grid item xs><h5 className="value">{beneficiario.email}</h5></Grid>
                                    </Grid>
                                )
                            }
                            <Grid container className="data">
                                <Grid item xs={4}><h5 className="key">Cadastro na Plataforma:</h5> </Grid>
                                <Grid item xs><h5 className="value">{dateParser(beneficiario.created_at)}</h5></Grid>
                            </Grid>
                            
                    </div>
                    </div>

                    <div className="section">

                        <div className="title">
                            <h4>Resultado da Triagem</h4>
                        </div>

                        <div className="values">
                            <Grid container>
                                <Grid item xs>
                                    <h5 className="value">
                                        <pre>{triagem}</pre> 
                                    </h5>
                                    </Grid>
                            </Grid>
                        </div>
                    </div>

                    <div className="section">

                        <div className="title">
                            <h4>Relatórios de Atendimentos do Beneficiário</h4>
                            <a className="btn green-bg small" rel="noopener noreferrer" target="_blank" href={`/app/${role}/diagnosticos/novo/${beneficiario.id}`}>Criar Novo</a>
                        </div>

                        <div className="values">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Protocolo</th>
                                        <th>Tipo</th>
                                        <th>Urgência</th> 
                                        <th>Data</th> 
                                        <th>Situação</th> 
                                        <th>Ações</th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        beneficiario.diagnosticos.map(value => (
                                            <tr key={value.id}>
                                                <td>#{value.id}</td>
                                                <td>{value.tipo.titulo}</td>
                                                <td>{value.gravidade.titulo}</td>
                                                <td>{ dateParser(value.created_at)}</td>
                                                <td>{value.situacao.titulo}</td>
                                                <td>
                                                    <a className="btn blue-bg small" rel="noopener noreferrer" target="_blank" href={`/app/${role}/diagnosticos/${value.id}`}>Abrir</a>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="section">

                        <div className="title">
                            <h4>Prontuário Eletrônico / Solicitação Médica</h4>
                            <a className="btn green-bg small" rel="noopener noreferrer" target="_blank" href={`/app/${role}/solicitacoes/novo/${beneficiario.id}`}>Criar Novo</a>
                        </div>

                        <div className="values">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Protocolo</th>
                                        <th>Tipo</th>
                                        <th>Urgência</th> 
                                        <th>Data</th> 
                                        <th>Situação</th> 
                                        <th>Ações</th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        beneficiario.solicitacoes.map(value => (
                                            <tr key={value.id}>
                                                <td>#{value.id}</td>
                                                <td>{value.tipo.titulo}</td>
                                                <td>{value.gravidade.titulo}</td>
                                                <td>{ dateParser(value.created_at)}</td>
                                                <td>{value.situacao.titulo}</td>
                                                <td>
                                                    <a className="btn blue-bg small" rel="noopener noreferrer" target="_blank" href={`/app/${role}/solicitacoes/${value.id}`}>Abrir</a>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                </div>

            </Paper>
            
        </Grid>
    )
    
}

export default Prontuario;