import React, { useState, useEffect } from 'react';
import { Grid, Paper, CircularProgress } from '@material-ui/core';
import "./index.scss";
import axios from 'axios';
import { datetimeParser } from "others/services";
import { Dialog } from '@material-ui/core';
import Prontuario from "components/prontuario";
import useApi from 'hooks/useApi';

function Atendimentos(){
    const api = useApi();
    document.title = 'BSI - Lista de Atendimentos';

    const [atendimentos, setAtendimentos] = useState([]);
    const [prontuario, setProntuario] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        loadAtendimentos();
    }, []);

    async function loadAtendimentos(){
        try {
            const request = await api.get("/atendimentos");
            const response = request.data.payload;
            setAtendimentos(response);
            
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
    
    }

    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <>
            <Grid className="atendimentos listar" container spacing={2}>
                <Dialog maxWidth="md" fullWidth open={prontuario ? true : false} onClose={() => setProntuario(null)} aria-labelledby="form-dialog-title">
                    <Prontuario id={prontuario} />
                </Dialog>
                <div className="page-box">
                    <div className="title">
                        Lista de Atendimentos
                    </div>
                    <Paper elevation={3} className="content">
                        <table>
                            <thead>
                                <tr>
                                    <th>Protocolo</th>
                                    <th>Beneficiário</th> 
                                    <th>Inicio</th> 
                                    <th>Fim</th> 
                                    <th>Ações</th> 
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    atendimentos.map(value => (
                                        <tr key={value.id}>
                                            <td>#{value.id}</td>
                                            <td>{value.beneficiario.nome}</td>
                                            <td>{ datetimeParser(value.created_at)}</td>
                                            <td>{ datetimeParser(value.end_at)}</td>
                                            <td>
                                                <a className="btn blue-bg small" onClick={() => setProntuario(value.beneficiario.id)}>Abrir Prontuário</a>
                                                {/* <a className="btn gray-bg small" onClick={() => remove(value.id)}>Remover</a> */}
                                            </td>
                                        </tr>
                                    ))
                                }
                            
                            </tbody>
                        </table>
                        <br/>
                        <br/>
                    </Paper>
                </div>

            </Grid>

        </>
    )
    
}

export default Atendimentos;