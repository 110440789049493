import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper, CircularProgress } from '@material-ui/core';
import "../index.scss";
import { dateParser } from "others/services";
import axios from "axios";
import { Link } from 'react-router-dom';
import useApi from 'hooks/useApi';

function AgrupamentosLista(){
    const api = useApi();
    document.title = 'BSI - Agrupamento de Medicamentos';

    const [agrupamentos, setAgrupamentos] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        loadAgrupamentos();
    }, []);

    async function loadAgrupamentos(){

        try {
            const request = await api.get("/agrupamentos/lista/medicamento");
            const response = request.data.payload;
            setAgrupamentos(response);

        } catch (error) {
            
        } finally {
            setLoading(false);
        }

     
    }

    async function removeAgrupamento(id){
        await api.del(`/agrupamentos/${id}`);
        const newAgrupamento = agrupamentos.filter(value => value.id !== id);
        setAgrupamentos(newAgrupamento);
    }

    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <>
            <Grid className="procedimentos listar" container spacing={2}>
                <div className="page-box">
                    <div className="title">
                        Lista de Procedimentos Medicamentos
                    </div>
                    <Paper elevation={3} className="content">
                    <table>
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Titulo</th>
                                <th>Data da Criação</th>
                                <th>Ações</th> 
                            </tr>
                        </thead>
                        <tbody>
                            {
                                agrupamentos.map(value => (
                                    <tr key={value.id}>
                                        <td>#{value.id}</td>
                                        <td>{value.titulo}</td>
                                        <td>{dateParser(value.created_at)}</td>
                                        <td>
                                            <Link className="btn blue-bg small" to={`${value.id}`}>Editar</Link>
                                            <a className="btn gray-bg small" onClick={() => removeAgrupamento(value.id)}>Remover</a>
                                        </td>
                                    </tr>
                                ))
                            }
                           
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                    <Grid className="page-actions borded" container justify="flex-start" alignItems="center">
                        <Button href='novo' variant="contained" color="primary">Adicionar</Button>
                    </Grid>
                    </Paper>
                </div>

            </Grid>

        </>
    )
    
}

export default AgrupamentosLista;