import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import MyDocument from "./doc";

const DocumentViewer = () => {

  const { prestador, beneficiario, medicamentos, solicitacao, certificado } = JSON.parse(localStorage.getItem("@bsi:relatorio"));
  
  const props = { prestador, beneficiario, medicamentos, solicitacao, certificado };

  return(
    <PDFViewer style={{width: "100%", height: "100vh", overflow: "hidden"}}>
        <MyDocument {...props} />
    </PDFViewer>
  );

}

export default DocumentViewer;
