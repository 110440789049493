import React, { useEffect, useState } from 'react';

import './index.scss';
import { Grid, Paper } from '@material-ui/core';
import { FiPlus } from "react-icons/fi";
import { useStateValue } from 'contexts';
import axios from "axios";
import { dateParser, datetimeParser } from "others/services";

import useMediaQuery from '@material-ui/core/useMediaQuery';
import useApi from 'hooks/useApi';

function Table(){
    const api = useApi();
    const matches = useMediaQuery('(max-width:768px)');

    const configs = {
        tabs: ['Atendimentos', 'Solicitações', 'Diagnósticos'],
        header: ["Protocolo", 'Beneficiário','Inicio', 'Fim', "Ações"]
    }

    const [solicitacoes, setSolicitacoes] = useState([]);
    const [atendimentos, setAtendimentos] = useState([]);
    const [diagnosticos, setDiagnosticos] = useState([]);

    const [{ user : { id, tipo } }] = useStateValue();

    const [config, setConfig] = useState({tabs: [], header: []});
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        loadAtendimentos();
        loadSolicitacoes();
        loadDiagnosticos();
    }, []);

    useEffect(() => {
        setConfig(configs);

    }, [tipo]);

    
    async function loadAtendimentos(){
        
        try {
            const request = await api.get("/atendimentos");
            const response = request.data.payload;
            setAtendimentos(response);
        } catch (error) {
            
        }
      
    }

    async function loadSolicitacoes(){

        try {
            const request = await api.get("/solicitacoes");
            const response = request.data.payload;
            setSolicitacoes(response);
        } catch (error) {
            
        }
      
    }

    async function loadDiagnosticos(){

        try {
            const request = await api.get("/diagnosticos");
            const response = request.data.payload;
            setDiagnosticos(response);
        } catch (error) {
            
        }
     
    }

    function changeTab(i){
        let header = [];

        if(i === 0) header = ["Protocolo", 'Beneficiário','Inicio', 'Fim', "Ações"];
        if(i === 1) header = ["Protocolo", 'Titulo', 'Tipo', 'Beneficiário', "Data", "Ações"];
        if(i === 2) header = ["Protocolo", 'Titulo', 'Tipo', 'Beneficiário', "Data", "Ações"];
        
        setConfig({...config, header});

        setActiveTab(i);
    }

    function bindTableBody() {

        if(activeTab === 0) return atendimentos.map(value => (
            <tr key={value.id}>
                <td>#{value.id}</td>
                <td>{value.beneficiario.nome}</td>
                <td>{ datetimeParser(value.created_at)}</td>
                <td>{ datetimeParser(value.end_at)}</td>
                <td>
                    {/* <a className="btn blue-bg small" onClick={() => setProntuario(value.beneficiario.id)}>Abrir Prontuário</a> */}
                </td>
            </tr>
        ))
        
        if(activeTab === 1) return solicitacoes.map(value => (
            <tr key={value.id}>
                <td>#{value.id}</td>
                <td>{value.titulo}</td>
                <td>{value.tipo.titulo}</td>
                <td>{value.beneficiario.nome}</td>
                <td>{ dateParser(value.created_at)}</td>
                <td>
                    <a className="btn blue-bg small" href={`${value.id}`}>Editar</a>
                </td>
            </tr>
        ))

        if(activeTab === 2) return diagnosticos.map(value => (
            <tr key={value.id}>
                <td>#{value.id}</td>
                <td>{value.titulo}</td>
                <td>{value.tipo.titulo}</td>
                <td>{value.beneficiario.nome}</td>
                <td>{ dateParser(value.created_at)}</td>
                <td>
                    <a className="btn blue-bg small" href={`${value.id}`}>Editar</a>
                </td>
            </tr>
        ))
    }

    return (
        <div className="lista-wrapper">
            <Grid container>
                <Paper className="wrapper" elevation={3}>
                    <h4 className="gray-text">Acesso Rápido</h4>
                    <div className="box-header hide-mobile">
                        <Grid container>
                            <Grid item md>
                                { config.tabs.map((value, i) => <div key={i} onClick={() => changeTab(i)} className={`single gray-text ${activeTab === i ? 'active' : ''}`}>{value}</div>) }
                            </Grid>
                            <Grid item md={4}>
                                <Grid container justify="flex-end">
                                    <button className="btn blue-bg">
                                        <FiPlus size={17} color={"white"} />
                                        <span>Ver {config.tabs[activeTab]}</span>
                                    </button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <br/>
                    <table>
                        <thead>
                            <tr>
                                {  
                                    config.header.map((value, i) => {
                                        if(value === 'Protocolo' && matches) return;
                                        if(value === 'Tipo' && matches) return;
                                        if(value === 'Beneficiário' && matches) return;
                                        if(tipo === 'prestador' && value === 'Prestador') return;
                                        return ( <th key={i}>{value}</th> );
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            { bindTableBody() }
                        </tbody>
                    </table>
                </Paper>
            </Grid>
        </div>
    );
}

export default Table;