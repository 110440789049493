import React, { useState, useEffect } from 'react';
import { Grid, Chip, Button, Paper, CircularProgress } from '@material-ui/core';
import axios from "axios";
import "./index.scss";
import { dateParser } from "others/services";
import useApi from 'hooks/useApi';

function Faq(){
    const api = useApi();
    document.title = 'BSI - Perguntas Frequentes';

    const [faq, setFaq] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        loadFaq();
    }, []);

    async function loadFaq(){

        try {
            const request = await api.get("/faq");
            const response = request.data.payload;
            setFaq(response);
            
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
    }

    async function removeFaq(id){
        alert("Ainda não foi implementado a regra pra exclusão de faq!");
    }

    
    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <>
            <Grid className="faq listar" container spacing={2}>
                <div className="page-box">
                    <div className="title">
                        Perguntas Frequentes
                    </div>
                    <Paper elevation={3} className="content">
                    <table>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Titulo</th>
                                <th>Data</th> 
                                <th>Situação</th> 
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                faq.map(value => (
                                    <tr key={value.id}>
                                        <td>{value.id}</td>
                                        <td>{value.titulo}</td>
                                        <td>{ dateParser(value.created_at)}</td>
                                        <td> <Chip size="small" color={value.ativo ? "primary" : "danger"} label={value.ativo ? "Habilitado" : "Desabilitado"} /></td>
                                        <td>
                                            <a className="btn blue-bg small" href={`Faq/${value.id}`}>Editar</a>
                                            <a className="btn gray-bg small" onClick={() => removeFaq(value.id)}>Remover</a>
                                        </td>
                                    </tr>
                                ))
                            }
                           
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                    <Grid className="page-actions borded" container justify="flex-start" alignItems="center">
                        <Button href='faq/novo' variant="contained" color="primary">Adicionar</Button>
                    </Grid>
                    </Paper>
                </div>

            </Grid>

        </>
    )
    
}

export default Faq;