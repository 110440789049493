import React, { useEffect, useState } from "react";

import { Grid, Paper, LinearProgress } from "@material-ui/core";
import "./index.scss";
import { useStateValue } from "contexts";
import axios from "axios";
import useApi from "hooks/useApi";

function Statistics() {
  const api = useApi();
  const [stats, setStats] = useState({
    atendimentos: {
      minutos: {},
    },
    solicitacoes: {},
    diagnosticos: {},
  });
  const [
    {
      user: { tipo },
    },
  ] = useStateValue();

  useEffect(() => {
    loadStats();
  }, []);

  async function loadStats() {
    try {
      const request = await api.get("/estatisticas/resumo");
      const response = request.data.payload;
      setStats(response);
    } catch (e) {}
  }

  function barSize(init, total) {
    return (Number(init) / Number(total)) * 100;
  }

  return (
    <Paper elevation={3} className="total-progress">
      <Grid container className="infos">
        <h4>Resumo de Atividades</h4>
        <Grid container className="info" direction="column">
          <Grid container justify="space-between">
            <h5>Atendimentos</h5>
            <h5>
              <b>{stats.atendimentos.prestador}</b>
            </h5>
          </Grid>
          <LinearProgress
            className="progress-blue"
            variant="determinate"
            value={barSize(
              stats.atendimentos.prestador,
              stats.atendimentos.total
            )}
          />
        </Grid>
        <Grid container className="info" direction="column">
          <Grid container justify="space-between">
            <h5>Diagnosticos</h5>
            <h5>
              <b>{stats.diagnosticos.prestador}</b>
            </h5>
          </Grid>
          <LinearProgress
            className="progress-green"
            variant="determinate"
            value={barSize(
              stats.diagnosticos.prestador,
              stats.diagnosticos.total
            )}
          />
        </Grid>
        <Grid container className="info" direction="column">
          <Grid container justify="space-between">
            <h5>Solicitações</h5>
            <h5>
              <b>{stats.solicitacoes.prestador}</b>
            </h5>
          </Grid>
          <LinearProgress
            className="progress-yellow"
            variant="determinate"
            value={barSize(
              stats.solicitacoes.prestador,
              stats.solicitacoes.total
            )}
          />
        </Grid>
        <Grid container className="info" direction="column">
          <Grid container justify="space-between">
            <h5>Tempo em atendimento</h5>
            <h5>
              <b>{stats.atendimentos.minutos.atendendido} min</b>
            </h5>
          </Grid>
          <LinearProgress
            className="progress-blue"
            variant="determinate"
            value={barSize(
              stats.atendimentos.minutos.atendendido,
              stats.atendimentos.minutos.total
            )}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Statistics;
