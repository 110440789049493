import axios from "axios";
import Swal from "sweetalert2";

const customApi = "https://app.atendimento.iguanait.com.br/api";
const defaultApi = "https://bsi-atendimento.azurewebsites.net/api/v1";
//const customApi = "http://localhost:8000/api";
//const defaultApi = "http://localhost:4000/api/v1";

const customInstance = axios.create({
  baseUrl: customApi,
});

customInstance.defaults.baseURL = customApi;

const apiInstance = axios.create({
  baseUrl: defaultApi,
});
apiInstance.defaults.baseURL = defaultApi;

function getToken() {
  try {
    const localData = JSON.parse(localStorage.getItem("@bsi"));
    const {
      auth: { token },
    } = localData;
    return token;
  } catch (error) {
    return null;
  }
}

//Custom instance interceptors

customInstance.interceptors.response.use(
  (request) => {
    return request;
  },
  (error) => {
    const localData = JSON.parse(localStorage.getItem("@bsi"));

    if (localData && error.request.status === 401) {
      Swal.fire({
        title: "Você foi deslogado",
        text: "Seu token expirou você será redirecionado para tela de login",
        icon: "warning",
        confirmButtonText: "Entendi",
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem("@bsi");
          window.location.reload();
        }
      });
    }

    Promise.reject(error);
  }
);

customInstance.interceptors.request.use(
  (request) => {
    const jToken = getToken();

    if (!jToken) return request;

    request.headers.Authorization = jToken;

    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

//API instance interceptors

apiInstance.interceptors.response.use(
  (request) => {
    return request;
  },
  (error) => {
    const localData = JSON.parse(localStorage.getItem("@bsi"));

    if (localData && error.request.status === 401) {
      Swal.fire({
        title: "Você foi deslogado",
        text: "Seu token expirou você será redirecionado para tela de login",
        icon: "warning",
        confirmButtonText: "Entendi",
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem("@bsi");
          window.location.reload();
        }
      });
    }

    Promise.reject(error);
  }
);

apiInstance.interceptors.request.use(
  (request) => {
    const jToken = getToken();

    if (!jToken) return request;

    request.headers.Authorization = jToken;

    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

export {customInstance, apiInstance, customApi, defaultApi};