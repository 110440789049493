import React, { useState, useEffect, useRef } from 'react';
import { Grid, Button, Fab, Paper, Switch, Select, MenuItem, CircularProgress } from '@material-ui/core';

import MaskedInput from 'react-text-mask';
import masks from "others/mask";
import Estados from "assets/json/estados.json";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStateValue } from 'contexts';
import axios from "axios";
import "./index.scss";
import { FiArrowLeft, FiCamera } from "react-icons/fi";
import { changedValues, prismaNewValues } from "others/services";
import Endereco from 'components/endereco';
import useApi from 'hooks/useApi';

function PrestadorEditar(){
    const api = useApi();
    const [original, setOriginal] = useState({});
    const [prestador, setPrestador] = useState({
        credencial: {
            ativo: true,
        },
        endereco: {
            estado: ''
        },
        imagem_url: "https://f0.pngfuel.com/png/340/956/profile-user-icon-png-clip-art-thumbnail.png",
        genero: { id: '' }
    });

    const [{}, dispatch] = useStateValue();
    const uploadInput = useRef(null);

    const history = useHistory();
    const params = useParams();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const { id } = params;
        const parserID = Number(id);
        if(parserID) loadPrestador(id);
    }, []);

    function showFlash(obj){

        obj.isOpen = true;

        dispatch({
            type: 'setFlash',
            payload: obj
        });

    }
    
    async function loadPrestador(id){

        try {
            const request = await api.get(`prestadores/${id}`);
            const response = request.data.payload;
    
            setPrestador(response);
            setOriginal(response);
            
            document.title = 'BSI - '+ response.nome;
        } catch (error) {
            
        } finally {
            setLoading(false);
        }

    }

    async function save(){

        if(prestador.telefone) prestador.telefone = prestador.telefone.replace(/\D/g, '');
        if(prestador.celular) prestador.celular = prestador.celular.replace(/\D/g, '');
        if(prestador.cpf) prestador.cpf = prestador.cpf.replace(/\D/g, '');
        
        if(prestador.endereco.cep) prestador.endereco.cep = Number(prestador.endereco.cep.toString().replace(/\D/g, ''));
        if(prestador.endereco.numero) prestador.endereco.numero = Number(prestador.endereco.numero);

        const newPrestador = changedValues(original, prestador);
        const endereco = prismaNewValues(original.endereco, prestador.endereco);
        const credencial = prismaNewValues(original.credencial, prestador.credencial);
        const genero = changedValues(original.genero, prestador.genero);

        let payload = {...newPrestador};
        if(endereco) payload.endereco = endereco;
        if(credencial) payload.credencial = credencial;
        
        if(genero && genero.id){
            payload.genero = {
                connect : { id: genero.id }
            }
        }

        try {
            const { id } = prestador;
            const request = (id) ? await api.put(`prestadores/${id}`, payload) : await api.post(`prestadores`, payload);
            const response = request.data;

            showFlash({type: "success", title: "Sucesso", subTitle: response.msg});
            
            setTimeout(() => {
                history.push("/app/entidade/prestadores");
            }, 1000);
            
        } catch (error) {
            showFlash({type: "error", title: "Error", subTitle: "Verifique os dados"});
            console.log(error);
        }
       
    }

    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <>
            <Grid className="prestadores editar" style={{width: "100%", margin: 0}} container spacing={2}>
                <Grid container>
                    <div className="page-title">
                        <Link to="../prestadores">
                            <FiArrowLeft size={28} />
                            <h3>Voltar</h3>
                        </Link>
                    </div>
                </Grid>
                <Grid container>
                    <div className="page-box">
                        <div className="title">
                            Informações legais
                        </div>
                        <Paper elevation={3} className="content">
                            <form>
                                {/* <TextField onChange={(evt) => setCredential({...credential, login : evt.target.value})} color="primary" className="custom-input" label="Login" fullWidth variant="outlined" /> */}
                                <Grid container>
                                    {/* <label htmlFor="input-2">Razão Social</label> */}
                                    <input autoFocus={true} placeholder="Insira o nome" className="big-input" defaultValue={prestador.nome} onChange={(evt) => setPrestador({...prestador, nome: evt.target.value})} />
                                </Grid>
                                    
                                <Grid container spacing={2}>
                                    
                                    <Grid item md={3} xs={6}>
                                        <div className="avatar-wrapper">
                                            <img src={prestador.imagem_url || 'https://f0.pngfuel.com/png/340/956/profile-user-icon-png-clip-art-thumbnail.png'} alt=""/>
                                            <input ref={uploadInput} accept="image/*" type="file" />
                                            <Fab onClick={() => uploadInput.current.click() } size="medium" className="primary-gradient"> <FiCamera size={20} /></Fab>
                                        </div>
                                    </Grid>

                                    <Grid item md>

                                        <Grid container spacing={2}>

                                            <Grid item md={3} xs={6}>
                                                <div className="select-wrapper">
                                                    <label htmlFor="input-14">Gênero</label>
                                                    <Select value={prestador.genero.id} onChange={(evt) => setPrestador({...prestador, genero: { id: evt.target.value } } )} htmlFor="input-11" variant="outlined" fullWidth>
                                                        <MenuItem value={1}><span>Masculino</span></MenuItem>
                                                        <MenuItem value={2}><span>Feminino</span></MenuItem>
                                                        <MenuItem value={3}><span>Não Informado</span></MenuItem>
                                                    </Select> 
                                                </div>
                                            </Grid>

                                            <Grid item md={1} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-4">Habilitado</label>
                                                    <Switch
                                                        checked={prestador.credencial.ativo}
                                                        color="primary"
                                                        onClick={() => setPrestador({...prestador, credencial: {...prestador.credencial, ativo: !prestador.credencial.ativo } })}
                                                    />
                                                </div>
                                            </Grid>

                                        </Grid>

                                        <Grid container spacing={2}>

                                            <Grid item md={4} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-5">Email</label>
                                                    <input defaultValue={prestador.credencial.email} onChange={(evt) => setPrestador({...prestador, credencial: {...prestador.credencial, email: evt.target.value }})} id="input-5" />
                                                </div>
                                            </Grid>

                                            <Grid item md={3} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-6">Telefone</label>
                                                    <MaskedInput guide={null} mask={masks.phone} defaultValue={prestador.telefone} onChange={(evt) => setPrestador({...prestador, telefone: evt.target.value})} id="input-6" />
                                                </div>
                                            </Grid>
                                            
                                            <Grid item md={3} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-7">Celular</label>
                                                    <MaskedInput guide={null} mask={masks.phone} defaultValue={prestador.celular} onChange={(evt) => setPrestador({...prestador, celular: evt.target.value})} id="input-7" />
                                                </div>
                                            </Grid>

                                            <Grid item md={3} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-3">CPF</label>
                                                    <MaskedInput guide={false} mask={masks.cpf} defaultValue={prestador.cpf} onChange={(evt) => setPrestador({...prestador, cpf: evt.target.value})} id="input-3" />
                                                </div>
                                            </Grid>

                                            <Grid item md={3} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-9">CRM</label>
                                                    <input defaultValue={prestador.crm} onChange={(evt) => setPrestador({...prestador, crm: evt.target.value })} id="input-19" />
                                                </div>
                                            </Grid>

                                            <Grid item md={4} xs={6}>
                                                <div className="select-wrapper">
                                                    <label htmlFor="input-21">CRM Estado</label>
                                                    <Select value={prestador.crm_uf || ''} onChange={(evt) => setPrestador({...prestador, crm_uf: evt.target.value } )} htmlFor="input-21" variant="outlined" fullWidth>
                                                        { Estados.map((value, i) => <MenuItem value={value.sigla} key={i}><span>{value.desc}</span></MenuItem>) }
                                                    </Select> 
                                                </div>
                                            </Grid>

                                            <Grid item md={2} xs={6}>
                                                <div className="input-wrapper">
                                                    <label htmlFor="input-9">CBO</label>
                                                    <input defaultValue={prestador.cbo} onChange={(evt) => setPrestador({...prestador, cbo: evt.target.value })} id="input-19" />
                                                </div>
                                            </Grid>


                                        </Grid>

                                    </Grid>
                                </Grid>                               
                            </form>
                            <br/>
                        </Paper>
                    </div>
                    <div className="page-box">
                        
                        <div className="title">
                            Endereço
                        </div>

                        <Endereco endereco={prestador.endereco} changeData={endereco => setPrestador({...prestador, endereco}) } />
                        
                    </div>

                </Grid>

                <Grid className="page-actions" container justify="flex-end" alignItems="center">
                    <Button variant="contained" href="../prestadores" color="inherit">Cancelar</Button>
                    <Button className="primary-gradient" variant="contained" onClick={save}>Salvar</Button>
                </Grid>


            </Grid>
            
        
        </>
    )
    
}

export default PrestadorEditar;