import { format } from "date-fns";
import { conformToMask } from 'react-text-mask';
import masks from "others/mask";
import estados from "assets/json/estados.json";


export function doUpload(file){

    console.log(file);

    // const { form } = this.state;

    // const storageRef = firebase.storage().ref();
    // const fileExt = file.name.split('.').pop();
    // const hashName = rdgenerator.randomStringNumbersLower(12)+'.'+fileExt;

    // let uploadTask = storageRef.child('posts/'+hashName).put(file);

    // return new Promise(resolve => {

    //   uploadTask.on('state_changed', (snapshot) => {
    //   }, function(error) {
    //     alert("Ocorreu um erro ao enviar o arquivo, consulte o desenvolvedor!");
    //   }, () => {
    //     uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
    //       resolve(downloadURL);
    //     });
    //   });

    // });

}

export function changedValues(oldObj = {}, newObj){
    if(!newObj) return null;

    const filter = {};
    const keys = Object.keys(newObj);

    keys.map(key => {
        const check = oldObj[key] !== newObj[key];
        const value = newObj[key];
        if(check && typeof value !== 'object') filter[key] = value;
    });

    return filter;

}

export function prismaNewValues(oldObj, newObj){

    const filter = changedValues(oldObj, newObj);

    if(Object.keys(filter).length === 0) return null;

    return { [newObj.id ? 'update' : 'create']: filter };
}

export function datetimeParser(dateTime){
    if(!dateTime) return "";
    return format(new Date(dateTime), "dd/MM/yyyy - HH:mm a");
}

export function dateDayChecker(dateTime){
    if(!dateTime) return "";
    return format(new Date(dateTime), "yyyy-MM-dd 00:00:00");
}

export function dateParser(date){
    if(!date) return "";
    return format(new Date(date), "dd/MM/yyyy");
}

export function printDateParser(date){
    if(!date) return "";
    return format(new Date(date), "dd|MM|yy");
}

export function printDateTimeParser(date){
    if(!date) return "";
    return format(new Date(date), "dd|MM|yy|-|hh|mm");
}

export function cnpjMask(string){
    if(!string) return "";
    return conformToMask(string.toString(), masks.cnpj).conformedValue;
}

export function cpfMask(string){
    if(!string) return "";
    return conformToMask(string.toString(), masks.cpf).conformedValue;
}

export function phoneMask(string){
    if(!string) return "";
    return conformToMask(string.toString(), masks.phone).conformedValue;
}

export function cellPhoneMask(string){
    if(!string) return "";
    return conformToMask(string.toString(), masks.cellPhone).conformedValue;
}

export function cepMask(string){
    if(!string) return "";
    return conformToMask(string.toString(), masks.cep).conformedValue;
}

export function stateDesc(sigla){
    const estado = estados.find(value => value.sigla === sigla);
    return estado.desc;
}