import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Dashboard from "./dashboard";

import Entidades from "./entidades";
import EntidadesEditar from "./entidades/editar";

import Notificacoes from "pages/notificacoes";
import NotificacoesEditar from "pages/notificacoes/editar";
import MinhasNotificacoes from "pages/notificacoes/single";

const Auth = () => {
    let { path } = useRouteMatch();

    return ( 
        <Switch>
            <Route exact path={path}>
                <Dashboard />
            </Route>
            <Route exact path={`${path}/dashboard`}>
                <Dashboard />
            </Route>
            <Route exact path={`${path}/entidades`}>
                <Entidades />
            </Route>
            <Route exact path={`${path}/entidades/:id`}>
                <EntidadesEditar />
            </Route>
            <Route exact path={`${path}/notificacoes`}>
                <Notificacoes />
            </Route>
            <Route exact path={`${path}/notificacoes/:id`}>
                <NotificacoesEditar />
            </Route>
            <Route exact path={`${path}/minhas-notificacoes/:id`}>
                <MinhasNotificacoes />
            </Route>
        </Switch>
    )
    
}

export default Auth;