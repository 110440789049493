import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Dashboard from "./dashboard";
import Servicos from "./servicos";
import ServicosEditar from "./servicos/editar";

import Agenda from "../../agenda";

const Auth = () => {
    let { path } = useRouteMatch();

    return ( 
        <Switch>
            <Route exact path={path}>
                <Dashboard />
            </Route>
            <Route exact path={`${path}/dashboard`}>
                <Dashboard />
            </Route>
            <Route exact path={`${path}/servicos`}>
                <Servicos />
            </Route>
            <Route exact path={`${path}/servicos/:id`}>
                <ServicosEditar />
            </Route>
            <Route exact path={`${path}/agenda`}>
                <Agenda />
            </Route>
            
        </Switch>
    )
    
}

export default Auth;