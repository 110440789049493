import React, { useState, useEffect } from "react";

import { Grid, Button, TextField, Paper } from "@material-ui/core";
import { slide as Menu } from "react-burger-menu";
import {
  FiMenu,
  FiX,
  FiPower,
  FiSearch,
  FiChevronDown,
  FiChevronUp,
} from "react-icons/fi";
import * as FeatherIcon from "react-icons/fi";

import logov1 from "assets/img/logos/logov1-only.jpg";
// import Profile1 from "@assets/img/HIJ-Ajun_400x400.jpg";
import "./index.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useStateValue } from "contexts";
import nav from "others/nav";
// import DropDownMenu from "./dropdown";
import Notifications from "./notifications";
import { Collapse } from "@material-ui/core";

import Activity from "./activity";

function Header() {
  const history = useHistory();
  const { pathname } = useLocation();

  const [navigation, setNavigation] = useState({ toolbar: [], shortcuts: [] });
  const [mobileNavOpen, setMobileOpen] = useState(false);
  const [dropdownTarget, setDropdownTarget] = useState(null);
  const [collapseNav, setCollapse] = useState(null);

  const [
    {
      user: { nome, razao_social, imagem_url },
    },
  ] = useStateValue();

  const localData = JSON.parse(localStorage.getItem("@bsi"));
  const {
    auth: { role },
  } = localData;

  useEffect(() => {
    if (role) {
      let userNavigation = nav[role];
      if (userNavigation) setNavigation(userNavigation);
    }
  }, [role]);

  const matchCheck = (route) => {
    return pathname.includes(route);
  };

  function logout() {
    localStorage.removeItem("@bsi");
    return history.push("/login");
  }

  function navigate(path) {
    if (mobileNavOpen) setMobileOpen(false);
    history.push(path);
  }

  function collapseChildren(i) {
    if (collapseNav === i) return setCollapse(null);
    setCollapse(i);
  }

  return (
    <header>
      <Paper className="gray-shadow wrapper" elevation={6}>
        <Grid className="desktop-nav" container alignItems="center">
          <Grid item className="logo">
            <img src={logov1} width="90px" alt="BSI logo" />
          </Grid>
          <Grid item md className="search">
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              placeholder="Buscar Paciente, solicitação ou diagnostico"
            />
            <Button disableElevation={true} variant="contained" color="primary">
              <FiSearch size={20} color="white" />
            </Button>
          </Grid>
          <Grid item md={5} className="options">
            <Grid container justify="flex-end" alignItems="center">
              <Grid item md={8} className="user">
                <Grid container justify="flex-end" alignItems="center">
                  <img
                    src={imagem_url}
                    className="avatar"
                    alt="Avatar do usuario"
                  />
                  <div className="name">
                    <h5>{nome || razao_social}!</h5>
                    {role === "prestador" && <Activity />}
                  </div>
                </Grid>
              </Grid>
              <div className="dropdown">
                <Grid container justify="center" alignItems="center">
                  <Notifications />
                </Grid>
              </div>
              <div className="btn">
                <Grid container justify="center" alignItems="center">
                  <FiPower onClick={logout} color={"#D8DFE5"} size={20} />
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems="center" className="mobile-nav">
          <Grid item md={2} className="logo">
            <img src={logov1} width="90px" alt="BSI logo" />
          </Grid>
          <div className={`hamburger${mobileNavOpen ? " opened" : ""}`}>
            <Menu
              isOpen={mobileNavOpen}
              onStateChange={({ isOpen }) => setMobileOpen(isOpen)}
              right
              customBurgerIcon={<FiMenu size={40} />}
              customCrossIcon={<FiX size={40} />}
            >
              <img className="logo" src={logov1} width="120px" alt="BSI logo" />
              {navigation.toolbar.map((value, i) => (
                <span
                  key={i}
                  className={matchCheck(value.path) ? "active" : ""}
                  onClick={() => navigate(value.path)}
                >
                  {value.title}
                </span>
              ))}
              <span onClick={logout}>Sair</span>
            </Menu>
          </div>
        </Grid>
      </Paper>
      <nav className="side-nav">
        <Grid container>
          <ul>
            {navigation.shortcuts.map((value, i) => {
              const icon = React.createElement(FeatherIcon[value.icon]);

              if (value.children)
                return (
                  <div key={i}>
                    <li
                      onClick={() => collapseChildren(i)}
                      className={matchCheck(value.path) ? "active" : ""}
                    >
                      <div className="title">
                        <div style={{ marginRight: 20 }}>{icon}</div>
                        <div>{value.title}</div>
                        <span
                          style={{
                            height: 20,
                            marginLeft: "45px",
                            marginBottom: "5px",
                          }}
                        >
                          {i === collapseNav ? (
                            <FiChevronUp />
                          ) : (
                            <FiChevronDown />
                          )}
                        </span>
                      </div>
                    </li>
                    <Collapse
                      style={{ backgroundColor: "#f3f3f3" }}
                      in={i === collapseNav}
                      timeout="auto"
                      unmountOnExit
                    >
                      {value.children.map((child, i) => {
                        return (
                          <li
                            key={i}
                            onClick={() => navigate(child.path)}
                            className={matchCheck(child.path) ? "active" : ""}
                          >
                            <div className="title" style={{ paddingLeft: 60 }}>
                              {child.title}
                            </div>
                          </li>
                        );
                      })}
                    </Collapse>
                  </div>
                );

              return (
                <li
                  onClick={() => navigate(value.path)}
                  className={matchCheck(value.path) ? "active" : ""}
                  key={i}
                >
                  <div className="title">
                    <span style={{ marginRight: 20 }}>{icon}</span>
                    {value.title}
                  </div>
                </li>
              );
            })}
          </ul>
        </Grid>
      </nav>
    </header>
  );
}

export default Header;
