import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Switch,
  Button,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import "./index.scss";
import { GoPencil, GoX } from "react-icons/go";
import { useHistory, useParams } from "react-router-dom";
import { useStateValue } from "contexts";
import { changedValues } from "others/services";
import useApi from "hooks/useApi";

function Triagem() {
  const api = useApi();
  document.title = "BSI - Triagem";

  const [pergunta, setPergunta] = useState({ tipo: 1, respostas: [] });
  const [isLoading, setLoading] = useState(true);
  const [edit, setEdit] = useState({ tipo: 1, ativo: true });
  const [respostas, setRespostas] = useState([]);
  const [novaResposta, setNovaResposta] = useState(null);
  const [{}, dispatch] = useStateValue();

  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    const { id } = params;
    if (id) {
      console.log(id);
      const parserID = Number(id);
      if (parserID) loadPergunta(id);
      else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, []);

  function showFlash(obj) {
    obj.isOpen = true;

    dispatch({
      type: "setFlash",
      payload: obj,
    });
  }

  async function loadPergunta(id) {
    try {
      const request = await api.get(`triagem/${id}`);
      const response = request.data.payload;
      setRespostas(response.respostas);
      setEdit({
        tipo: response.tipo,
        ativo: response.ativo,
        descricao: response.descricao,
      });
      setPergunta(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function deleteResposta(index) {
    const id = respostas[index] ? respostas[index].id : null;

    const copy = [...respostas];

    copy.splice(index, 1);

    setRespostas(copy);

    if (!id) return;

    try {
      await api.del(`triagem/respostas/${id}`);
    } catch (error) {
      console.log(error);
    }
  }

  async function saveResposta(novaResposta) {
    const { id } = novaResposta;

    if (novaResposta.peso) novaResposta.peso = Number(novaResposta.peso);

    if (!id) {
      if (edit.respostas && edit.respostas.create) {
        edit.respostas = { create: [...edit.respostas.create, novaResposta] };
      } else {
        edit.respostas = { create: [novaResposta] };
      }

      respostas.push(novaResposta);
      setRespostas(respostas);

      return setNovaResposta(null);
    }

    try {
      const index = respostas.findIndex((value) => value.id === id);
      const diff = changedValues(respostas[index], novaResposta);
      const request = await api.put(`triagem/respostas/${id}`, diff);
      const payload = request.data.payload;

      respostas[index] = payload;

      setRespostas(respostas);
    } catch (error) {
      console.log(error);
    } finally {
      setNovaResposta(null);
    }
  }

  async function save() {
    try {
      const { id } = pergunta;
      const request = id
        ? await api.put(`triagem/${id}`, edit)
        : await api.post(`triagem`, edit);
      const response = request.data;

      showFlash({ type: "success", title: "Sucesso", subTitle: response.msg });

      setTimeout(() => {
        history.push("/app/entidade/triagem");
      }, 1000);
    } catch (error) {
      showFlash({
        type: "error",
        title: "Error",
        subTitle: "Verifique os dados",
      });
      console.log(error);
    }
  }

  // async function disablePergunta(id){
  //     alert("Ainda não foi implementado a regra pra exclusão de entidade!");
  // }

  if (isLoading) {
    return (
      <div className="loading-wrapper">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Grid className="triagem" container spacing={2}>
        <Grid item md xs>
          <div className="page-box">
            <div className="title">Editar pergunta</div>
            <Paper>
              <div className="box">
                {/* <h4>Editar Pergunta</h4> */}
                <form>
                  <Grid container>
                    <Grid item xs>
                      <div className="input-wrapper">
                        <label htmlFor="input-1">Titulo</label>
                        <input
                          defaultValue={pergunta.titulo}
                          onChange={(evt) =>
                            setEdit({ ...edit, titulo: evt.target.value })
                          }
                          id="input-1"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className="input-wrapper">
                        <label htmlFor="input-2">Habilitado</label>
                        <Switch
                          checked={edit.ativo}
                          color="primary"
                          onClick={() =>
                            setEdit({ ...edit, ativo: !edit.ativo })
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <div className="select-wrapper">
                        <label htmlFor="input-5">Tipo da Pergunta</label>
                        {/* <Select value={edit.tipo} onChange={(evt) => setEdit({...edit, tipo: evt.target.value } )} htmlFor="input-5" variant="outlined" fullWidth>
                                                    <MenuItem value={1}><span>Input</span></MenuItem>
                                                    <MenuItem value={2}><span>Textarea</span></MenuItem>
                                                    <MenuItem value={3}><span>Upload de arquivo</span></MenuItem>
                                                    <MenuItem value={4}><span>Opções</span></MenuItem>
                                                </Select>  */}
                        <Select
                          value={edit.tipo}
                          onChange={(evt) =>
                            setEdit({ ...edit, tipo: evt.target.value })
                          }
                          htmlFor="input-5"
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem value={1}>
                            <span>Unico</span>
                          </MenuItem>
                          <MenuItem value={2}>
                            <span>Multiplo</span>
                          </MenuItem>
                          <MenuItem value={3}>
                            <span>Upload de Arquivo</span>
                          </MenuItem>
                          <MenuItem value={4}>
                            <span>Lista de Doenças</span>
                          </MenuItem>
                        </Select>
                      </div>
                    </Grid>
                    {/* {
                                            (pergunta.tipo === 1 || pergunta.tipo === 2) && (
                                                <Grid item xs>
                                                    <div className="input-wrapper">
                                                        <label htmlFor="input-4">Nome do campo</label>
                                                        <input defaultValue={pergunta.campo} onChange={(evt) => setEdit({...edit, campo: evt.target.value})} id="input-4"/>
                                                    </div>
                                                </Grid>
                                            )
                                        } */}
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className="input-wrapper">
                        <label htmlFor="input-3">Descrição</label>
                        <textarea
                          defaultValue={edit.descricao}
                          onKeyUp={(evt) =>
                            setEdit({ ...edit, descricao: evt.target.value })
                          }
                          id="input-3"
                        ></textarea>
                      </div>
                    </Grid>
                  </Grid>
                </form>
                <br />
              </div>
            </Paper>
          </div>
        </Grid>
        <Grid item md xs>
          {edit.tipo !== 3 && (
            <div className="page-box">
              <div className="title">Respostas</div>
              <Paper>
                <div className="box">
                  <form>
                    <div className="answers">
                      {novaResposta ? (
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <div className="input-wrapper">
                              <label htmlFor="input-13">Titulo</label>
                              <input
                                defaultValue={novaResposta.titulo}
                                onChange={(evt) =>
                                  setNovaResposta({
                                    ...novaResposta,
                                    titulo: evt.target.value,
                                  })
                                }
                                id="input-13"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <div className="input-wrapper">
                              <label htmlFor="input-14">Peso</label>
                              <input
                                type="number"
                                max="5"
                                min="0"
                                defaultValue={novaResposta.peso}
                                onChange={(evt) =>
                                  setNovaResposta({
                                    ...novaResposta,
                                    peso: evt.target.value,
                                  })
                                }
                                id="input-14"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <div className="input-wrapper">
                              <label htmlFor="input-14">Exclusiva</label>
                              <Switch
                                checked={novaResposta.exclusiva}
                                color="primary"
                                onClick={() =>
                                  setNovaResposta({
                                    ...novaResposta,
                                    exclusiva: !novaResposta.exclusiva,
                                  })
                                }
                              />
                            </div>
                          </Grid>
                          <Grid item xs>
                            <div className="input-wrapper">
                              <label htmlFor="input-15">Descrição</label>
                              <textarea
                                defaultValue={novaResposta.descricao}
                                onChange={(evt) =>
                                  setNovaResposta({
                                    ...novaResposta,
                                    descricao: evt.target.value,
                                  })
                                }
                                id="input-15"
                              ></textarea>
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div>
                              <span
                                className="btn blue-bg small"
                                onClick={() => saveResposta(novaResposta)}
                              >
                                Salvar
                              </span>
                              <span
                                className="btn gray-bg small"
                                onClick={() => setNovaResposta(null)}
                              >
                                Cancelar
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          {respostas.map((value, i) => (
                            <div className="pergunta answer" key={i}>
                              <GoPencil
                                onClick={() => setNovaResposta(value)}
                                className="edit"
                                size={18}
                              />
                              <GoX
                                onClick={() => deleteResposta(i)}
                                className="close"
                                size={18}
                              />
                              <h4>
                                #{i + 1} - {value.titulo}
                              </h4>
                              <small>
                                {value.descricao}
                                <span className="author">
                                  {value.peso} Pontos
                                </span>
                              </small>
                            </div>
                          ))}
                          <br />
                          <a
                            className="btn blue-bg small"
                            onClick={() =>
                              setNovaResposta({ exclusiva: false })
                            }
                          >
                            Criar Resposta
                          </a>
                        </Grid>
                      )}
                    </div>
                  </form>
                </div>
              </Paper>
            </div>
          )}
        </Grid>
        <Grid
          className="page-actions "
          container
          justify="flex-end"
          alignItems="center"
        >
          <Button href="../triagem" variant="contained">
            Cancelar
          </Button>
          <Button onClick={save} variant="contained" color="primary">
            Salvar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default Triagem;
