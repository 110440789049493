import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#10b1dc'
            },
            secondary: {
                main: '#122f3b'
            }
        },
        // overrides: {
        //     MuiButton: {
        //         contained:{
        //             background: 'linear-gradient(45deg, #10b1dc 30%, #2dd4c7 90%)',
        //             color: '#fff',
        //         }
        //     },
        // }
    },
)

export default theme;