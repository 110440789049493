import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper, Select, Switch, MenuItem } from '@material-ui/core';

import { useHistory } from "react-router-dom";
import { useStateValue } from 'contexts';
import axios from "axios";
import "./index.scss";
import { FiArrowLeft } from "react-icons/fi";
import useApi from 'hooks/useApi';

function NotificacoesEditar(){
    const api = useApi();
    const [notificacao, setNotificacao] = useState({push_notification: false});
    const [{ user: { role } }, dispatch] = useStateValue();

    const history = useHistory();

    useEffect(() => {
        document.title = 'BSI - Cadastro de Notificação';
    }, []);

    function showFlash(obj){

        obj.isOpen = true;

        dispatch({
            type: 'setFlash',
            payload: obj
        });

    }

    async function save(){

        try {
            const request = await api.post(`/notificacoes`, notificacao);
            const response = request.data;

            showFlash({type: "success", title: "Sucesso", subTitle: response.msg});
            
            setTimeout(() => {
                history.push(`/app/${role === 'admin' ? 'administrador' : role}/notificacoes`);
            }, 1000);
            
        } catch (error) {
            showFlash({type: "error", title: "Error", subTitle: "Verifique os dados"});
            console.log(error);
        }
       
    }

    return ( 
        <>
            <Grid className="notificacoes editar" style={{width: "100%", margin: 0}} container spacing={2}>
                <Grid container>
                    <div className="page-title">
                        <a href="../notificacoes">
                            <FiArrowLeft size={28} />
                            <h3>Voltar</h3>
                        </a>
                    </div>
                </Grid>
                <Grid container>
                    <div className="page-box">
                        <div className="title">
                            Dados da Notificação
                        </div>
                        <Paper elevation={3} className="content">
                            <form>

                                <Grid container spacing={2}>

                                    <Grid item xs>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-1">Titulo</label>
                                            <input defaultValue={notificacao.titulo} onChange={(evt) => setNotificacao({...notificacao, titulo: evt.target.value})} id="input-1" />
                                        </div>
                                    </Grid>
                                
                                    <Grid item md={2} xs={6}>
                                        <div className="select-wrapper">
                                            <label htmlFor="input-14">Público Alvo</label>
                                            <Select value={notificacao.publico || ''} onChange={(evt) => setNotificacao({...notificacao, publico: evt.target.value } )} htmlFor="input-11" variant="outlined" fullWidth>
                                                {
                                                    role === "admin" && <MenuItem value={"entidades"}><span>Entidades</span></MenuItem>
                                                }
                                                <MenuItem value={"prestadores"}><span>Prestadores</span></MenuItem>
                                                <MenuItem value={"beneficiarios"}><span>Beneficiarios</span></MenuItem>
                                            </Select> 
                                        </div>
                                    </Grid>

                                    <Grid item md={1} xs={6}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-4">Push</label>
                                                <Switch
                                                    disabled={notificacao.publico !== 'beneficiarios'}
                                                    checked={notificacao.push_notification}
                                                    color="primary"
                                                    onClick={() => setNotificacao({...notificacao, push_notification: !notificacao.push_notification })}
                                                />
                                            </div>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className="input-wrapper">
                                            <label htmlFor="input-3">Descrição</label>
                                            <textarea defaultValue={notificacao.descricao} onKeyUp={(evt) => setNotificacao({...notificacao, descricao: evt.target.value})} id="input-3"></textarea>
                                        </div>
                                    </Grid>
                                </Grid>

                            </form>
                            <br/>
                        </Paper>
                    </div>
                    
                </Grid>

                <Grid className="page-actions" container justify="flex-end" alignItems="center">
                    <Button variant="contained" href="../notificacoes" color="inherit">Cancelar</Button>
                    <Button className="primary-gradient" variant="contained" onClick={save}>Salvar</Button>
                </Grid>


            </Grid>
            
        
        </>
    )
    
}

export default NotificacoesEditar;