import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper, CircularProgress } from '@material-ui/core';
import "./index.scss";
import axios from 'axios';
import { dateParser } from "others/services";
import { Link } from 'react-router-dom';
import useApi from 'hooks/useApi';

function Exames(){
    const api = useApi();
    document.title = 'BSI - Lista de Solicitações';

    const [solicitacoes, setSolicitacoes] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        loadSolicitacoes();
    }, []);

    async function loadSolicitacoes(){

        try {
            const request = await api.get("/solicitacoes");
            const response = request.data.payload;
            setSolicitacoes(response);
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
       
    }

    async function removeExame(id){
        await api.del(`/solicitacoes/${id}`);
        const newSolicitacoes = solicitacoes.filter(value => value.id !== id);
        setSolicitacoes(newSolicitacoes);
    }

    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <>
            <Grid className="procedimentos listar" container spacing={2}>
                <div className="page-box">
                    <div className="title">
                        Lista de Exames Solicitados
                    </div>
                    <Paper elevation={3} className="content">
                    <table>
                        <thead>
                            <tr>
                                <th>Protocolo</th>
                                <th>Titulo</th>
                                <th>Tipo</th>
                                <th>Beneficiário</th> 
                                <th>Urgência</th> 
                                <th>Data</th> 
                                <th>Situação</th> 
                                <th>Ações</th> 
                            </tr>
                        </thead>
                        <tbody>
                            {
                                solicitacoes.map(value => (
                                    <tr key={value.id}>
                                        <td>#{value.id}</td>
                                        <td>{value.titulo}</td>
                                        <td>{value.tipo.titulo}</td>
                                        <td>{value.beneficiario.nome}</td>
                                        <td>{value.gravidade.titulo}</td>
                                        <td>{ dateParser(value.created_at)}</td>
                                        <td>{value.situacao.titulo}</td>
                                        <td>
                                            <Link className="btn blue-bg small" to={`${value.id}`}>Editar</Link>
                                            <a className="btn gray-bg small" onClick={() => removeExame(value.id)}>Remover</a>
                                        </td>
                                    </tr>
                                ))
                            }
                           
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                    <Grid className="page-actions borded" container justify="flex-start" alignItems="center">
                        <Link to='novo' variant="contained" className="btn blue-bg" color="primary">Adicionar</Link>
                    </Grid>
                    </Paper>
                </div>

            </Grid>

        </>
    )
    
}

export default Exames;