import React, { useState, useEffect } from 'react';
import { Grid, Paper, Button, CircularProgress } from '@material-ui/core';
import axios from "axios";
import "./index.scss";
import { GoCheck } from "react-icons/go";
import { dateParser } from "others/services";
import { Link } from 'react-router-dom';
import useApi from 'hooks/useApi';

function Triagem(){
    const api = useApi();
    document.title = 'BSI - Triagem';

    const [perguntas, setPerguntas] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        loadPerguntas();
    }, []);

    async function loadPerguntas(){

        try {
            const request = await api.get("/triagem");
            const response = request.data.payload;
            setPerguntas(response);
        } catch (error) {
            console.log(error);
        } finally{
            setLoading(false);
        }
       
    }

    async function disablePergunta(id){
        alert("Ainda não foi implementado a regra pra exclusão de entidade!");
    }

    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <>
            <Grid className="triagem" container>
                <Grid item md={8} xs>
                    <div className="page-box">
                        <div className="title">
                        Perguntas cadastrada
                        </div>
                        <Paper>
                            <div className="box">
                                {
                                    perguntas.map((value, i) => (
                                        <div className="pergunta" key={value.id}>
                                            <Grid container>
                                                <Grid item xs={1}>
                                                    <div className={`status ${value.ativo ? 'active' : ""}`}>
                                                        { value.ativo && <GoCheck /> } 
                                                    </div>
                                                </Grid>
                                                <Grid item xs>
                                                    <Link to={`triagem/${value.id}`}>
                                                        <h4>#{i + 1} - {value.titulo}</h4>
                                                        <small>Criado em { dateParser(value.created_at) } - Por <span className="author">Lucas Costa</span></small>
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    ))
                                }
                                <br/>
                                <Grid className="page-actions borded" container justify="flex-start" alignItems="center">
                                    <Button href="triagem/novo" variant="contained" color="primary">Criar Pergunta</Button>
                                </Grid>
                            </div>
                        </Paper>
                    </div>
                </Grid>
            </Grid>

        </>
    )
    
}

export default Triagem;