import React from 'react';
import { Page, Font, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer'
import "./index.scss";
import logov1 from "assets/img/logos/logov1.jpg";
import { printDateParser, printDateTimeParser } from "others/services";

Font.register({ family: 'Roboto', fonts: [
    { src: require("assets/fonts/Roboto/Roboto-Regular.ttf") }, 
    { src: require("assets/fonts/Roboto/Roboto-Light.ttf"), fontWeight: 300 },
    { src: require("assets/fonts/Roboto/Roboto-Medium.ttf"), fontWeight: 500 },
    { src: require("assets/fonts/Roboto/Roboto-Bold.ttf"), fontWeight: 700 },
]});

// Create styles
const styles = StyleSheet.create({
  page: {
   marginVertical: 5,
  },
  section: {
    padding: 0,
    margin: 0,
    marginTop: -1,
    flexDirection: "row",
    display: "flex",
    marginHorizontal: 20,
    borderWidth: 1,
    borderColor: "#777",
    flexGrow: 1
  },
  single: {
    display: "flex",
    borderColor: "#777",
    flexDirection: "column",
    height: 18,
    justifyContent: "space-between",
    paddingLeft: 2
  },
  multiple: {
    borderBottomWidth: 1,
    borderColor: "#777",
  },
  label:{
    fontSize: 5,
    fontWeight: "bold",
    display: "block",
    textAlign: "left",
    paddingTop: 2,
  },
  title: {
    fontSize: 6,
    margin: 0,
    paddingHorizontal: 20,
    fontWeight: "bold",
    marginVertical: 1,
    fontFamily: "Roboto",
    fontWeight: "medium",
  },
  format: {
    display: "block",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flexGrow: 1,
    flex: 1,
    height: "100%"
  },
  character: {
    borderLeftWidth: 1,
    borderColor: "#777",
    textAlign: "center",
    display: "flex",
    flex: 1,
    verticalAlign: "bottom",
    height: "70%",
    // backgroundColor: "red"
  },
  custom: {
    flex: .5,
    height: "70%",
    borderBottomWidth: 0
  },
  separator: {
    backgroundColor: "#777",
    borderLeftWidth: 0,
    height: "85%",
    flex: .08,
  },
  noBorderChar: {
    borderLeftWidth: 0
  },
  options: {
    paddingBottom: 2,
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: "100%",
  },
  optionsWrapper: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 2,
    height: "100%",
    paddingHorizontal: 10,
    flex: .05
  },
    optionSelected: {
        borderWidth: 1,
        borderColor: "#000",
        borderTopWidth: 0,
        width: 12,
        height: "100%",
        marginTop: 3,
        marginRight: 2
    },
    optionsList: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        height: 16,
        height: "100%",
        width: "100%"

    },
    optionsListOption: {
        fontSize: 5,
        display: "inline-block",
        marginTop: 2,
        marginRight: 10,
        fontWeight: 300,
    },
    simpleValue: {
        fontSize: 8, 
        textTransform: 'uppercase', 
        paddingHorizontal: 5, 
        fontFamily: "Roboto", 
        fontWeight: "medium"
    },
    simpleSmallValue: {
        fontSize: 7, 
        paddingHorizontal: 5, 
        fontFamily: "Roboto"
    }

});

const bindValue = (char) => {
    if(char === "|") return null;
    return char;
}

const bindCharacterClass = (char, { border, multiple, last }) => {
    
    const classes = [styles.character];

    if(border) classes.push(styles.noBorderChar);

    if(char === '|') classes.push(styles.separator);

    if(multiple){

        classes.push(styles.multiple);

        // if(last) classes.push({borderRightWidth: 1, borderLeftWidth: 1});

    }

    if(char !== 'x' && char !== '|') classes.push(styles.custom);

    return classes;
}

const bindFormatedValue = (row, multiple) => {

    const parser = [...row.format];

    let valueWithSpaces = row.value && row.value.toString().padStart(parser.length, " ");

    const parserValue = row.value ? [...valueWithSpaces] : [];

    if(multiple && parserValue) parserValue.shift();

    return (
        <View style={styles.format}>
            {
                parser.map((char, i) => {

                    const noBorder = i === 0 || parser[i - 1] === "|";

                    const last = ( parser.length - 1 ) === i;

                    const options = { border: noBorder, multiple, last };

                    return (
                        <View style={ bindCharacterClass(char, options) } key={i}>
                            <Text style={{fontSize: 8}}>{ bindValue(parserValue[i]) }</Text>
                        </View>
                    )
                })
            }
        </View>
    );
}

const bindLine = (value) => {
    return <Text style={{width: "100%", borderBottomWidth: 1, borderBottomColor: "#777", fontSize: 8, paddingHorizontal: 5}}>{value && value.value ? value.value : ''}</Text>
}

const bindSignature = (value, multiple) => {

    return (
        <View style={{flex: 1, flexDirection: "row", justifyContent: "flex-end", paddingBottom: multiple ? 0 : 2.5, paddingHorizontal: multiple ? 0 : 5, height: multiple ? "100%" : "70%"}}>
            <View style={{flex: .4, minHeight: 10}}>{ bindFormatedValue({format: "|xx|xx|xx|"}, true) }</View>
            <View style={{flex: .6, minHeight: 10, paddingLeft: 5, justifyContent: "flex-end"}}>{ bindLine() }</View>
        </View>
        
    )
  
}


const bindOptionValue = (row) => {

    const chars = [...row.options[0].tag];
    const charsValue = row.value ? [...row.value.toString()] : [];

    return (
        <View style={styles.options}>
            <View style={styles.optionsWrapper}>
                {
                    chars.map((value, i) => 
                        <View style={styles.optionSelected} key={i}>
                            <Text style={{fontSize: 5, textAlign:"center", fontWeight: "medium", fontFamily: "Roboto"}}>{charsValue[i]}</Text>
                        </View>
                    )
                }
                
            </View>
            <View style={styles.optionsList}>
                { 
                    row.options.map((opt, index) => (
                        <Text key={index} style={styles.optionsListOption}><Text style={{fontFamily: 'Roboto', fontWeight: "medium"}}>{opt.tag}</Text>  - {opt.desc}</Text>
                    ))
                }
            </View>
        </View>
    )
   
}

const adaptHeight = (row) => {
    if(row.height) return row.height;
    return row.qtd ? row.qtd * 18 : 22;
}

const bindSingleNumber = (row, rowIndex, nodeIndex) => {

    if(!row.count && !row.qtd) return;
    if(row.qtd && !row.count && rowIndex !== 0) return;

    return <View style={{fontSize: 6, marginLeft: 2, marginTop: 2, marginRight: 2, height: "100%", fontWeight: "bold", alignItems: "center", justifyContent: "center"}}><Text>{nodeIndex + 1} - </Text></View>;

}

const MyDocument = ({prestador, beneficiario, solicitacao, procedimentos}) => {


    if(procedimentos){
        procedimentos = procedimentos.map(value => {
            value.qtd = value.qtd.toString().padStart(2, "0");
            value.tabela = "22";
            return value;
        });
    }

    const form = [
    {
        title: "",
        data:[
            { 
                id: 1, 
                name: "REGISTRO ANS", 
                size: 4,
                value: "330809"
            },
            { 
                id: 3,
                name: "NUMERO GUIA PRINCIPAL (Paciente internado)",
                value: solicitacao.guia_prestador,
                size: 10,
            },
            { 
                id: 4,
                name: "DATA DA AUTORIZAÇÃO",
                format: "xx|xx|xx",
                value: printDateParser(solicitacao.data_autorizacao),
                optional: true,
                size: 3,
            },
            { 
                id: 5,
                name: "SENHA",
                value: solicitacao.senha,
                size: 3,
            },
            { 
                id: 6,
                name: "VALIDADE SENHA",
                format: "xx|xx|xx",
                value: printDateParser(solicitacao.validade_senha),
                optional: true,
                size: 3,
            },
            { 
                id: 7,
                name: "DATA EMISSÃO GUIA",
                format: "xx|xx|xx",
                value: printDateParser(solicitacao.data_emissao_guia),
                size: 3,
            }
        ]
    },
    {
        title: "DADOS DO BENEFICIÁRIO",
        data:[
            { 
                id: 8, 
                name: "NÚMERO DA CARTEIRA", 
                format: "xxxxxxxxxxxxxxxxx",
                size: 8,
                value: beneficiario.carteirinha
            },
            { 
                id: 9,
                name: "PLANO",
                value: beneficiario.plano,
                size: 3,
            },
            { 
                id: 10,
                name: "VALID. CARTEIRA",
                format: "xx|xx|xx",
                value: printDateParser(beneficiario.validade_carteirinha),
                size: 3,
                optional: true 
            },
            { 
                id: 11,
                name: "Nome",
                value: beneficiario.nome,
                size: 10,
            },
            {
                id: 12,
                size: 3,
                name: "NÚMERO DO CARTÃO NACIONAL DE SAÚDE",
                format: "xxxxxxxxxxxxxxxxx",
                value: beneficiario.cns,
                optional: true,
                size: 8
            }
        ]
    },
    {
        title: "DADOS DO CONTRATADO SOLICITANTE",
        data:[
            { 
                id: 13, 
                name: "CÓDIGO NA OPERADORA/CNPJ/CPF", 
                format: "xxxxxxxxxxxxxx",
                size: 3,
            },
            { 
                id: 14,
                name: "NOME DO CONTRATADO",
                size: 10,
            },
            { 
                id: 15,
                name: "CÓDIGO CNES",
                optional: true 
            }
        ]
    },
    {
        data:[
            { 
                id: 16, 
                name: "NOME DO PROFISSIONAL SOLICITANTE", 
                size: 10,
                value: prestador.nome,
                optional: true, 
            },
            { 
                id: 17,
                name: "CONSELHO PROFISSIONAL",
                value: "CRM",
                size: 2,
            },
            { 
                id: 18,
                name: "NÚMERO DO CONSELHO",
                value: prestador.crm,
                size: 3,
            },
            { 
                id: 19,
                name: "UF",
                value: prestador.crm_uf,
                size: 1,
            },
            { 
                id: 20,
                name: "CÓDIGO CBO-S",
                value: prestador.cbo,
                size: 2,
            },
        ]
    },
    {
        title: "DADOS DA SOLICITAÇÃO / PROCEDIMENTOS E EXAMES SOLICITADOS",
        data:[
            { 
                id: 21, 
                name: "DATA - HORA DA SOLICITAÇÃO", 
                format: "xx|xx|xx|-|xx|xx",
                value: printDateTimeParser(solicitacao.created_at),
                size: 2,
                optional: true, 
            },
            { 
                id: 22,
                name: "CARÁTER DA SOLICITAÇÃO",
                value: solicitacao.carater_solicitacao,
                size: 3,
                options: 
                [
                    {
                        tag: "E",
                        desc: "ELETIVO"
                    },
                    {
                        tag: "U",
                        desc: "URGÊNCIA/EMERGÊNCIA"
                    }
                ],
            },
            { 
                id: 23,
                name: "CID 10",
                value: solicitacao.cid_10,
                size: 1,
                format: "xxxxx",
            },
            { 
                id: 24,
                name: "INDICAÇÃO CLÍNICA (Obrigatório se pequena cirurgia, terapia, consultas referenciadas e alto custo)",
                value: solicitacao.descricao,
                textSize: "small",
                size: 6,
            }
        ]
    },
    {
      multiple: true,
      qtd: 5,
      values: procedimentos,
      data:[
          { 
              id: 25, 
              name: "TABELA", 
              format: "|xx|",
              key: "tabela",
              size: 1,
              optional: false, 
          },
          { 
              id: 26, 
              name: "CÓDIGO DO PROCEDIMENTO", 
              format: "|xxxxxxxxxxxx|",
              key: "id",
              size: 4,
              optional: false, 
          },
          { 
              id: 27, 
              name: "DESCRIÇÃO",
              line: true,
              key: "desc",
              size: 10,
              optional: false, 
          },
          { 
              id: 28, 
              name: "QT SOLIC.", 
              key: "qtd",
              format: "|xx|",
              size: .8,
              optional: false, 
          },
          { 
              id: 29, 
              name: "QT AUTORI.", 
              format: "|xx|",
              size: .8,
              optional: false, 
          },
          
      ]
    },
    {
        title: "DADOS DO CONTRATADO EXECUTANTE",
        data:[
            { 
                id: 30, 
                name: "CÓDIGO OPERADORA / CNPJ / CPF", 
                format: "xxxxxxxxxxxxxx",
                size: 2.8,
            },
            { 
                id: 31,
                name: "NOME DO CONTRATADO",
                size: 3,
            },
            { 
                id: 32,
                name: "T.L.",
                size: .5,
                optional: true 
            },
            { 
                id: [33, 34, 35],
                name: "LOGRADOURO - Nº E COMPLEMENTO.",
                size: 2,
                optional: true 
            },
            { 
                id: 36,
                name: "MUNICÍPIO",
                size: 1,
                optional: true 
            },
            { 
                id: 37,
                size: .5,
                name: "UF",
                optional: true 
            },
            { 
                id: 38,
                name: "CÓDIGO IBGE",
                size: 1,
                optional: true 
            },
            { 
                id: 39,
                name: "CEP",
                size: 1,
                optional: true 
            },
            { 
                id: 40,
                name: "CÓDIGO CNES",
                size: 1,
            }
        ]
    },
    {
        data:[
            { 
                id: 41, 
                name: "NOME DO PROFISSIONAL EXECUTANTE / COMPLEMENTAR", 
                size: 5,
            },
            { 
                id: 42,
                name: "CONSELHO PROFISSIONAL",
                size: 2,
            },
            { 
                id: 43,
                name: "NÚMERO NO CONSELHO",
                size: 3,
            },
            { 
                id: 44,
                size: 1,
                name: "UF",
            },
            { 
                id: 45,
                name: "CÓDIGO CBO-S",
                size: 1,
            },
        ]
    },
    {
        title: "DADOS DO ATENDIMENTO",
        data:[
            { 
                id: 46, 
                name: "TIPO DE ATENDIMENTO", 
                value: solicitacao.procedimento_tipo,
                options: 
                [
                    {
                        tag: "01",
                        desc: "Remoção"
                    },
                    {
                        tag: "02",
                        desc: "Pequena Cirurgia"
                    },
                    {
                        tag: "03",
                        desc: "Terapias"
                    },
                    {
                        tag: "04",
                        desc: "Consulta"
                    },
                    {
                        tag: "05",
                        desc: "Exame"
                    },
                    {
                        tag: "06",
                        desc: "Atend. Domiciliar"
                    },
                    {
                        tag: "07",
                        desc: "SADT Internado"
                    },
                    {
                        tag: "08",
                        desc: "Quimioterapia"
                    },
                    {
                        tag: "09",
                        desc: "Radioterapia"
                    },
                    {
                        tag: "10",
                        desc: "TRS - Terapia Renal Substitutiva"
                    }
                ],
                size: 5,
                double: true,
            },
            { 
                id: 47,
                name: "INDICAÇÃO DE ACIDENTE",
                value: solicitacao.indicacao_acidente,
                size: 3,
                options: 
                [
                    {
                        tag: "0",
                        desc: "Acidente/Doença Relacionada ao Trabalho"
                    },
                    {
                        tag: "1",
                        desc: "Trânsito"
                    },
                    {
                        tag: "2",
                        desc: "Outros"
                    }
                ],
                optional: true 
            },
            { 
                id: 48,
                name: "TIPO DE SAÍDA",
                value: solicitacao.tipo_saida,
                size: 3,
                options: 
                [
                    {
                        tag: "1",
                        desc: "Retorno"
                    },
                    {
                        tag: "2",
                        desc: "Retorno SADT"
                    },
                    {
                        tag: "3",
                        desc: "Referência"
                    },
                    {
                        tag: "4",
                        desc: "Internação"
                    },
                    {
                        tag: "5",
                        desc: "Alta"
                    },
                    {
                        tag: "6",
                        desc: "Óbito"
                    }
                ],
            },
            
        ]
    },
    {
        title: "CONSULTA REFERÊNCIA",
        data:[
            { 
                id: 49, 
                name: "TIPO DE DOENÇA", 
                value: solicitacao.tipo_doenca,
                options: 
                [
                    {
                        tag: "A",
                        desc: "Aguda"
                    },
                    {
                        tag: "C",
                        desc: "Crônica"
                    }
                ],
                size: 2,
            },
            { 
                id: 50,
                name: "TEMPO DE DOENÇA",
                value: solicitacao.tempo_doenca,
                size: 3,
                options: 
                [
                    {
                        tag: "A",
                        desc: "Anos"
                    },
                    {
                        tag: "M",
                        desc: "Meses"
                    },
                    {
                        tag: "D",
                        desc: "Dias"
                    }
                ],
                optional: true 
            },
        ]
    },
    {
      multiple: true,
      qtd: 5,
      data:[
          { 
              id: 51, 
              name: "DATA", 
              format: "|xx|xx|xx|",
              size: 2.5,
              optional: false, 
          },
          { 
              id: 52, 
              name: "HORA INICIAL", 
              format: "|xx:xx|",
              size: 1.8,
              optional: true, 
          },
          { 
              id: 53, 
              name: "HORA FINAL",
              format: "|xx:xx|",
              size: 1.8,
              optional: true, 
          },
          { 
              id: 54, 
              name: "TABELA", 
              format: "|xx|",
              size: 1,
              optional: false, 
          },
          { 
              id: 55, 
              name: "CÓDIGO DO PROCEDIMENTO", 
              format: "|xxxxxxxxxx|",
              size: 4,
              optional: false, 
          },
          { 
              id: 56, 
              name: "DESCRIÇÃO", 
              line: true,
              size: 4.5,
              optional: false, 
          },
          { 
              id: 57, 
              name: "QTDE.", 
              format: "|xx|",
              size: 1,
              optional: false, 
          },
          { 
              id: 58, 
              name: "VIA", 
              format: "|x|",
              size: .7,
              optional: true, 
          },
          { 
              id: 59, 
              name: "TÉC", 
              format: "|x|",
              size: .7,
              optional: true, 
          },
          { 
              id: 60, 
              name: "% RED./ACRÉSC.", 
              format: "|xxx,xx|",
              size: 2.2,
              optional: true, 
          },
          { 
              id: 61, 
              name: "VALOR UNITÁRIO R$", 
              format: "|xxxxx,xx|",
              size: 2.8,
              optional: false, 
          },
          { 
              id: 62, 
              name: "VALOR TOTAL R$", 
              format: "|xxxxx,xx|",
              size: 2.8,
              optional: false, 
          },
          
      ]
    },
    {
      multiple: true,
      qtd: 2,
      count: true,
      data:[
          { 
              id: 51, 
              name: "DATA E ASSINATURA DO PROCEDIMENTO EM SÉRIE", 
              signature: true,
              size: 10,
              optional: false, 
          },
          { 
              id: "511", 
              name: "", 
              signature: true,
              size: 10,
              optional: false, 
          },
          { 
              id: "522", 
              name: "", 
              signature: true,
              size: 10,
              optional: false, 
          },
          { 
              id: "522", 
              name: "", 
              signature: true,
              size: 10,
              optional: false, 
          },
          { 
              id: "522", 
              name: "", 
              signature: true,
              size: 10,
              optional: false, 
          }
      ]
    },
    {
        data:[
            { 
                id: 64, 
                name: "OBSERVAÇÃO", 
                size: 12,
                optional: true, 
            },
        ]
    },
    {
        data:[
            { 
                id: 65, 
                name: "TOTAL DO PROCEDIMENTO - R$", 
                format: "xxxxxxx,xx",
                size: 10,
            },
            { 
                id: 66, 
                name: "TOTAL TAXAS E ALUGUÉIS - R$", 
                format: "xxxxxxx,xx",
                size: 10,
            },
            { 
                id: 67, 
                name: "TOTAL MATERIAIS - R$", 
                format: "xxxxxxx,xx",
                size: 10,
            },
            { 
                id: 68, 
                name: "TOTAL DE MEDICAMENTOS - R$", 
                format: "xxxxxxx,xx",
                size: 10,
            },
            { 
                id: 69, 
                name: "TOTAL DE DIÁRIAS", 
                format: "xxxxxxx,xx",
                size: 10,
            },
            { 
                id: 70, 
                name: "TOTAL DE GASES MEDICINAIS - R$", 
                format: "xxxxxxx,xx",
                size: 10,
            },
            { 
                id: 71, 
                name: "TOTAL GERAL DA GUIA - R$", 
                format: "xxxxxxx,xx",
                size: 10,
            },
        ]
    },
    {
      height: 30,
      data:[
          { 
              id: 86, 
              name: "DATA E ASSINATURA DO SOLICITANTE", 
              signature: true,
              size: 10,
          },
          { 
              id: 87, 
              name: "DATA E ASSINATURA DO RESPONSÁVEL PELA AUTORIZAÇÃO", 
              signature: true,
              size: 10,
          },
          { 
              id: 88, 
              name: "DATA E ASSINATURA DO BENEFICIÁRIO OU RESPONSÁVEL", 
              signature: true,
              size: 10,
          },
          { 
              id: 89, 
              name: "DATA E ASSINATURA DO PRESTADOR EXECUTANTE", 
              signature: true,
              size: 10,
          },
      ]
    },
  
  ];


  return(
    <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>

            { solicitacao.situacao.id === 5 && 
                <Text style={{fontSize: 120, position: "absolute", transform: "rotate(-45deg)", zIndex: 999999, opacity: .2, top: "30%", left: "15%" }}>Cancelado</Text>
            }

            <View style={{ marginHorizontal: 20, display: "flex", alignItems: "center", flexDirection: "row", borderWidth: 0, height: 30}}>
                <View style={{flex: .25, alignItems: "center", justifyContent: "center"}}>
                    <Image style={{width: 120, marginTop: -2}} src={logov1} />
                </View>
                <View style={{flex: 1, paddingHorizontal: 10}}>
                    <View style={[styles.single, { justifyContent: "center", borderWidth: 1, height: "70%" }]}>
                        <Text style={{fontSize: 9, textAlign: "center", margin: 0, fontFamily: "Roboto", fontWeight: "medium"}}>GUIA DE SERVIÇO PROFISSIONAL / SERVIÇO AUXILIAR DE DIAGNÓSTICO E TERAPIA - SP/SADT</Text>
                    </View>
                </View>
                <View style={{flex: .3}}>
                    <View style={[styles.single, { borderWidth: 1, height: "70%" }]}>
                        <Text style={styles.label}>2 - Nº</Text>
                        <Text style={[styles.simpleValue, {fontSize: 10}]}>{solicitacao.guia_prestador}</Text>
                    </View>
                </View>
            </View>
            {
            form.map((row, index) => <View key={index}>
                
                { row.title ? <Text style={styles.title}>{row.title}</Text> : null }
                
                <View style={styles.section}>
                    { 
                        row.data.map((value, rowIndex) => {

                            const nodes = new Array(row.qtd || 1).fill('bla');
                            const last = rowIndex === (row.data.length - 1);
                            const rowHeight = adaptHeight(row);

                            const borderHandle = { 
                                borderLeftWidth: row.qtd || rowIndex === 0 ? 0 : 1,
                            }

                            let nodeNumber = 1;

                            return (
                                <View style={[styles.single, borderHandle, { flex: value.size / 10, height: rowHeight, backgroundColor: value.optional ? "#dcdddf" : '', paddingBottom: row.qtd ? 5 : 0}, row.height && { height: row.height }]} key={rowIndex}>
                                    <View style={{height: 8}}>
                                        { value.name ? <Text style={styles.label}>{value.id} - {value.name}</Text> : null}
                                    </View>
                                    {
                                        nodes.map((node, nodeIndex) => {
                                            
                                            if(row.multiple) value.value = "";

                                            if(row.values && row.values[nodeIndex] ) value.value = row.values[nodeIndex][value.key];

                                            return (
                                            
                                                <View key={nodeIndex} style={{flex: 1, flexDirection: 'row'}}>

                                                    { bindSingleNumber(row, rowIndex, nodeIndex) } 

                                                    <View style={{flexDirection: "row", alignItems: "flex-end", flex: 1, height: "100%", paddingHorizontal: row.multiple && 2}}>
                                                        { value.format && bindFormatedValue(value, row.multiple) }
                                                        { value.options && bindOptionValue(value) }
                                                        { value.line && bindLine(value) }
                                                        { value.signature && bindSignature(value, row.multiple) }
                                                        { !value.format && !value.options && !value.line && !value.signature && 
                                                            <Text style={value.textSize && value.textSize === "small" ? styles.simpleSmallValue : styles.simpleValue }>{value.value}</Text>
                                                        }
                                                    </View>
                                                </View>

                                            )

                                        })
                                    
                                    }
                                </View>
                            )
                        })
                            
                        
                    }
                    </View>
                </View>
            )
            }
        </Page>
    </Document>
  );

}

export default MyDocument;
