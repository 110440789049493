import React, { useEffect, useState } from "react";
import { Grid, Paper, Button, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import sideBackground from "assets/img/pexels-photo-2280547.jpeg";
import logov1 from "assets/img/logos/logov1.jpg";
import { useStateValue } from "contexts";

import { useHistory } from "react-router-dom";

import "./index.scss";
import useApi from "hooks/useApi";

function Signin() {
  const api = useApi();
  const [{}, dispatch] = useStateValue();
  const [isLogging, setIsLogging] = useState(false);

  useEffect(() => {
    localStorage.removeItem("@bsi");
  }, []);

  let email = null;
  let pass = null;

  let history = useHistory();

  const [alert, setAlert] = useState(null);
  const [credential, setCredential] = useState({});

  function showFlash(obj) {
    obj.isOpen = true;

    dispatch({
      type: "setFlash",
      payload: obj,
    });
  }

  async function doLogin(evt) {
    console.log("called")
    evt.preventDefault();

    const { email, pass } = credential;

    if (!email || !pass)
      return showFlash({
        type: "error",
        title: "Ocorreu um erro ao autenticar!",
        subTitle: "Preencha corretamente os campos!",
      });

    try {
      if (!isLogging) {
        setIsLogging(true);
        const request = await api.post("/public/auth/signin", {
          email,
          pass,
        });
        const response = request.data;
        console.log(response);
        const nome = response.payload.nome || response.payload.razao_social;

        showFlash({
          type: "success",
          title: `Olá, ${nome}`,
          subTitle: "A conexão segura estabelecida",
          caption: "Você será redirecionado...",
        });

        const temp = {
          auth: { token: response.token, role: response.payload.role },
          user: response.payload,
        };
        localStorage.setItem("@bsi", JSON.stringify(temp));
        setIsLogging(false);
        setTimeout(() => {
          history.push("/app");
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      setIsLogging(false);
      const response = error.response;

      if (!response)
        return showFlash({
          type: "error",
          title: "Ocorreu um erro ao autenticar!",
          subTitle:
            "O servidor encontra-se offline, tente novamente mais tarde.",
        });

      if (response.msg)
        showFlash({
          type: "error",
          title: "Ocorreu um erro ao autenticar!",
          subTitle: response.msg,
        });
    }
  }

  return (
    <>
      <Grid
        className="wrapper-full auth signin"
        container
        justify="center"
        alignItems="center"
      >
        <Grid item md={7} xs={12}>
          <Paper square>
            <Grid container>
              <Grid item md={6} className="banners hide-mobile">
                <img src={sideBackground} alt="Side background" />
                <div className="content">
                  <h1>Bem vindo visitante!</h1>
                  <p>
                    Entre com sua conta e tenha acesso ao seu painel
                    administrativo!
                  </p>
                </div>
              </Grid>
              <Grid item md={6} className="login-area">
                <img src={logov1} width="60%" alt="Logo BSI" />

                <form onSubmit={doLogin}>
                  <TextField
                    ref={email}
                    onChange={(evt) =>
                      setCredential({ ...credential, email: evt.target.value })
                    }
                    color="primary"
                    className="custom-input"
                    label="Email"
                    fullWidth
                    variant="outlined"
                  />
                  <TextField
                    type="password"
                    ref={pass}
                    onChange={(evt) =>
                      setCredential({ ...credential, pass: evt.target.value })
                    }
                    className="custom-input"
                    label="Senha"
                    fullWidth
                    variant="outlined"
                  />
                  {alert && (
                    <Alert variant="filled" severity={alert.type}>
                      {alert.msg}
                    </Alert>
                  )}
                  <p>
                    Esqueceu a senha?{" "}
                    <a href="#/forgot">
                      <b>Clique aqui</b>
                    </a>
                  </p>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={doLogin}
                  >
                    Entrar
                  </Button>
                </form>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default Signin;
