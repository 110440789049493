import React from 'react';
import { Grid, Paper } from '@material-ui/core';

import "./index.scss";
import Tiss from "assets/json/tiss.json";

function AgrupamentosLista(){

    document.title = 'BSI - Tabela de Medicamentos';

    const tissFilter = Tiss.filter((value, i) => i < 1000);

    return ( 
        <>
            <Grid className="medicamentos listar" container spacing={2}>
                <div className="page-box">
                    <div className="title">
                        Lista de Medicamentos
                    </div>
                    <Paper elevation={3} className="content">
                    <table>
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Medicamento</th>
                            <th>Apresentação</th>
                            {/* <th>Substancia</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tissFilter.map((value, i) => {
                                return (
                                    <tr key={i}>
                                        <td>#{value.ean}</td>
                                        <td>{value.medicamento}</td>
                                        <td>{value.apresenta}</td>
                                        {/* <td>{value.substancia}</td> */}
                                    </tr>
                                )
                            })
                            
                        }
                    </tbody>
                </table>
                    </Paper>
                </div>

            </Grid>

        </>
    )
    
}

export default AgrupamentosLista;