import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Paper,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import MaskedInput from "react-text-mask";
import masks from "others/mask";
import Estados from "assets/json/estados.json";
import AutoComplete from "components/autoComplete";
import NovoProcedimento from "components/agrupamentos/procedimentos";
import NovoMedicamento from "components/agrupamentos/medicamentos";
import { Link, useHistory, useParams } from "react-router-dom";
import { useStateValue } from "contexts";
import { changedValues } from "others/services";
import { FiArrowLeft } from "react-icons/fi";
import axios from "axios";
import PageActions from "components/relatorio/actions";

import SadtDoc from "../relatorios/sadt/doc";
import ReceitaDoc from "../relatorios/default/receita/doc";

import "./index.scss";
import useApi from "hooks/useApi";
import useCert from "hooks/useCert";

function Solicitacao() {
  const api = useApi();
  const { requestCert, signinFile } = useCert();
  const [solicitacao, setSolicitacao] = useState({
    tipo: { id: "" },
    situacao: { id: 1 },
    gravidade: { id: "" },
  });
  const [original, setOriginal] = useState({});

  const [beneficiario, setBeneficiario] = useState({});
  const [beneficiarios, setBeneficiarios] = useState([]);

  const [prestador, setPrestador] = useState({});
  const [prestadores, setPrestadores] = useState([]);

  const [modal, setOpenModal] = useState(false);
  const [nascimento, setNascimento] = useState(null);
  const [procedimentos, setProcedimentos] = useState([]);
  const [medicamentos, setMedicamentos] = useState([]);

  const [situacoes, setSituacoes] = useState([]);
  const [gravidades, setGravidades] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [disableSelectBeneficiario, setDisableSelectBeneficiario] =
    useState(false);

  const history = useHistory();
  const params = useParams();

  document.title = "BSI - Solicitação de Exames";

  const [{ user }, dispatch] = useStateValue();

  useEffect(() => {
    listaSituacoes();
    listaGravidades();
    listaTipos();
    listaBeneficiarios();
  }, []);

  useEffect(() => {
    const id = Number(params.id);

    if (user && user.id) {
      if (id) loadSolicitacao(id);
      if (!id) setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    const bene_id = Number(params.bene_id);

    if (bene_id && beneficiarios.length > 0) {
      const beneficiario = beneficiarios.find((value) => value.id === bene_id);
      if (beneficiario) {
        setBeneficiario(beneficiario);
        setDisableSelectBeneficiario(true);
      }
    }
  }, [beneficiarios]);

  function clearData() {
    setBeneficiario({ id: "", nome: "" });
    setPrestador({});
    setProcedimentos([]);
    setMedicamentos([]);
    setSolicitacao({
      tipo: { id: "" },
      situacao: { id: 1 },
      gravidade: { id: "" },
    });
  }

  async function loadSolicitacao(id) {
    try {
      const request = await api.get(`/solicitacoes/${id}`);
      let response = request.data.payload;

      if (response.procedimentos) setProcedimentos(response.procedimentos);
      if (response.medicamentos) setMedicamentos(response.medicamentos);

      setBeneficiario(response.beneficiario);
      setPrestador(response.prestador);

      delete response.procedimentos;
      delete response.beneficiario;
      delete response.prestador;

      setSolicitacao(response);
      setOriginal(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function listaTipos() {
    try {
      const request = await api.get("/tipos");
      const response = request.data.payload;
      console.log(response);
      const filter = response.filter((value) => value.solicitacao);
      setTipos(filter);
    } catch (error) {
      console.log(error);
    }
  }

  async function listaSituacoes() {
    try {
      const request = await api.get("/situacoes");
      const response = request.data.payload;
      setSituacoes(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function listaGravidades() {
    try {
      const request = await api.get("/gravidades");
      const response = request.data.payload;
      setGravidades(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function listaBeneficiarios() {
    try {
      const request = await api.get("/beneficiarios");
      const response = request.data.payload;
      setBeneficiarios(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function signinHandle() {
    const certificado = await requestCert();

    const props = {
      solicitacao,
      procedimentos,
      medicamentos,
      beneficiario,
      prestador,
      certificado,
    };

    const docWithSignature =
      solicitacao.tipo.id === 5 ? (
        <ReceitaDoc {...props} />
      ) : (
        <SadtDoc {...props} />
      );

    const updateSolic = await signinFile(
      `solicitacoes/${solicitacao.id}`,
      docWithSignature
    );

    setSolicitacao(updateSolic);
  }

  async function cancelSolicitacao() {
    const { id } = solicitacao;

    try {
      const request = await api.del(`/solicitacoes/${id}`);
      const response = request.data;

      setSolicitacao({ ...solicitacao, ...response.payload });

      showFlash({ type: "success", title: "Sucesso", subTitle: response.msg });
    } catch (error) {}
  }

  function selectBeneficiario(single, byName = false) {
    if (single && byName) setOpenModal(true);
    setBeneficiario(single);
  }

  function verifyBeneficiario() {
    setOpenModal(false);
  }

  async function inserirListaDeProcedimentos(NovosProcedimentos) {
    setProcedimentos([...procedimentos, ...NovosProcedimentos]);
  }

  async function inserirListaDeMedicamentos(novosMedicamentos) {
    setMedicamentos([...medicamentos, ...novosMedicamentos]);
  }

  function inserirNovoProcedimento(procedimento) {
    setProcedimentos([...procedimentos, procedimento]);
  }

  function inserirNovoMedicamento(medicamento) {
    setMedicamentos([...medicamentos, medicamento]);
  }

  function removerNovoProcedimento(index) {
    setProcedimentos(procedimentos.filter((item, i) => i !== index));
  }

  function removerNovoMedicamento(index) {
    setMedicamentos(medicamentos.filter((item, i) => i !== index));
  }

  function showFlash(obj) {
    obj.isOpen = true;

    dispatch({
      type: "setFlash",
      payload: obj,
    });
  }

  function gerarRelatorio() {
    localStorage.setItem(
      "@bsi:relatorio",
      JSON.stringify({
        beneficiario,
        prestador,
        solicitacao,
        procedimentos,
        medicamentos,
      })
    );

    console.log(solicitacao.tipo_id);
    if (solicitacao.tipo_id === 5) window.open("/relatorios/receita", "_blank");
    if (solicitacao.tipo_id === 4) window.open("/relatorios/sadt", "_blank");
  }

  async function save(finished) {
    const newSolicitacao = changedValues(original, solicitacao);

    let payload = { ...newSolicitacao };

    payload.situacao = {
      // 1 = rascunho, 2 = Aguardando assinatura
      connect: { id: finished ? 2 : 1 },
    };

    payload.entidade = {
      connect: { id: 1 },
    };

    if (procedimentos.length > 0) {
      payload.procedimentos = procedimentos;
    }

    if (medicamentos.length > 0) {
      payload.medicamentos = medicamentos;
    }

    if (beneficiario && beneficiario.id) {
      payload.beneficiario = {
        connect: { id: beneficiario.id },
      };
    }

    if (solicitacao.tipo && solicitacao.tipo.id) {
      payload.tipo = {
        connect: { id: solicitacao.tipo.id },
      };
    }

    if (solicitacao.gravidade && solicitacao.gravidade.id) {
      payload.gravidade = {
        connect: { id: solicitacao.gravidade.id },
      };
    }

    try {
      const { id } = solicitacao;
      const request = id
        ? await api.put(`/solicitacoes/${id}`, payload)
        : await api.post(`/solicitacoes`, payload);
      let response = request.data.payload;

      setSolicitacao(response);

      delete response.prestador;
      delete response.beneficiario;

      if (response.situacao.id === 1)
        history.push("/app/prestador/solicitacoes/lista");
      if (response.situacao.id === 2)
        history.push("/app/prestador/solicitacoes/" + response.id);

      showFlash({
        type: "success",
        title: "Sucesso",
        subTitle: request.data.msg,
      });
    } catch (error) {
      showFlash({
        type: "error",
        title: "Error",
        subTitle: "Verifique os dados",
      });
      console.log(error);
    }
  }

  if (isLoading) {
    return (
      <div className="loading-wrapper">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Grid
        className="exames solicitacao"
        style={{ width: "100%", margin: 0 }}
        container
        spacing={2}
      >
        <Dialog
          open={modal}
          onClose={() => setBeneficiario({ id: "", nome: "" })}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Confirme a data de nascimento
          </DialogTitle>
          <DialogContent>
            <div className="input-wrapper">
              <label htmlFor="input-24">Data de Nascimento</label>
              <MaskedInput
                value={nascimento}
                onChange={(evt) => setNascimento(evt.target.value)}
                mask={masks.date}
                id="input-24"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <button
              disabled={!nascimento}
              className="btn blue-bg"
              onClick={verifyBeneficiario}
            >
              {" "}
              Confirmar{" "}
            </button>
            <br />
            <br />
          </DialogActions>
        </Dialog>
        <Grid container>
          <div className="page-title">
            <Link to="../solicitacoes/lista">
              <FiArrowLeft size={28} />
              <h3>Voltar</h3>
            </Link>
          </div>
        </Grid>
        <Grid container>
          <div className="page-box">
            <div className="title">Informações legais</div>
            <Paper elevation={3} className="content">
              <form>
                {/* <TextField onChange={(evt) => setCredential({...credential, login : evt.target.value})} color="primary" className="custom-input" label="Login" fullWidth variant="outlined" /> */}
                <Grid container spacing={2}>
                  <Grid item md={2} xs={6}>
                    <div className="input-wrapper">
                      <label htmlFor="input-2">Protocolo</label>
                      <input
                        defaultValue={solicitacao.id}
                        disabled
                        id="input-2"
                      />
                    </div>
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <AutoComplete
                      disabled={disableSelectBeneficiario}
                      list={beneficiarios}
                      onChangeHandle={selectBeneficiario}
                      label="Matricula"
                      object={"id"}
                      value={beneficiario}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <AutoComplete
                      disabled={disableSelectBeneficiario}
                      list={beneficiarios}
                      onChangeHandle={selectBeneficiario}
                      label="Beneficiário"
                      object={"nome"}
                      value={beneficiario}
                    />
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <div className="input-wrapper">
                      <label htmlFor="input-2">Data</label>
                      <input
                        disabled
                        defaultValue={solicitacao.created_at}
                        id="input-2"
                      />
                    </div>
                  </Grid>
                </Grid>
                {user.role === "entidade" && (
                  <Grid container spacing={2}>
                    <Grid item md={2} xs={12}>
                      <AutoComplete
                        disabled={user.role === "prestador"}
                        list={prestadores}
                        onChangeHandle={setPrestador}
                        label="Matricula"
                        object={"id"}
                        value={prestador}
                      />
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <AutoComplete
                        disabled={user.role === "prestador"}
                        list={prestadores}
                        onChangeHandle={setPrestador}
                        label="Profissional Solicitante"
                        object={"nome"}
                        value={prestador}
                      />
                    </Grid>

                    <Grid item md={3} xs={6}>
                      <div className="select-wrapper">
                        <label htmlFor="input-11">CRM Estado</label>
                        <Select
                          disabled
                          value={prestador.crm_uf || ""}
                          onChange={(evt) =>
                            setPrestador({
                              ...prestador,
                              crm_uf: evt.target.value,
                            })
                          }
                          htmlFor="input-11"
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem value="">
                            {" "}
                            <span>Selecione</span>{" "}
                          </MenuItem>
                          {Estados.map((value, i) => (
                            <MenuItem value={value.sigla} key={i}>
                              <span>{value.desc}</span>
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </Grid>
                    <Grid item md={2} xs={6}>
                      <div className="input-wrapper">
                        <label htmlFor="input-5">Número</label>
                        <input
                          disabled
                          value={prestador.crm || ""}
                          onChange={(evt) =>
                            setPrestador({
                              ...prestador,
                              crm: evt.target.value,
                            })
                          }
                          id="input-5"
                        />
                      </div>
                    </Grid>
                    <Grid item md xs={6}>
                      <div className="input-wrapper">
                        <label htmlFor="input-6">CBO</label>
                        <input
                          disabled
                          value={prestador.cbo || ""}
                          onChange={(evt) =>
                            setPrestador({
                              ...prestador,
                              cbo: evt.target.value,
                            })
                          }
                          id="input-6"
                        />
                      </div>
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={2}>
                  <Grid item md xs={12}>
                    <div className="input-wrapper">
                      <label htmlFor="input-7">Titulo</label>
                      <input
                        value={solicitacao.titulo || ""}
                        onChange={(evt) =>
                          setSolicitacao({
                            ...solicitacao,
                            titulo: evt.target.value,
                          })
                        }
                        id="input-6"
                      />
                    </div>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <div className="select-wrapper">
                      <label htmlFor="input-11">Tipo de Solicitação</label>
                      <Select
                        onChange={(evt) =>
                          setSolicitacao({
                            ...solicitacao,
                            tipo: { id: evt.target.value },
                          })
                        }
                        value={solicitacao.tipo.id}
                        htmlFor="input-11"
                        variant="outlined"
                        fullWidth
                      >
                        {tipos.map((value) => (
                          <MenuItem value={value.id} key={value.id}>
                            <span>{value.titulo}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <div className="select-wrapper">
                      <label htmlFor="input-11">Gravidade</label>
                      <Select
                        onChange={(evt) =>
                          setSolicitacao({
                            ...solicitacao,
                            gravidade: { id: evt.target.value },
                          })
                        }
                        value={solicitacao.gravidade.id}
                        htmlFor="input-11"
                        variant="outlined"
                        fullWidth
                      >
                        {gravidades.map((value) => (
                          <MenuItem value={value.id} key={value.id}>
                            <span>{value.titulo}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>
                  <Grid item md={2} xs={6}>
                    <div className="select-wrapper">
                      <label htmlFor="input-11">Situação</label>
                      <Select
                        disabled
                        onChange={(evt) =>
                          setSolicitacao({
                            ...solicitacao,
                            situacao: { id: evt.target.value },
                          })
                        }
                        value={solicitacao.situacao.id}
                        htmlFor="input-11"
                        variant="outlined"
                        fullWidth
                      >
                        {situacoes.map((value) => (
                          <MenuItem value={value.id} key={value.id}>
                            <span>{value.titulo}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md xs={12}>
                    <div className="input-wrapper">
                      <label htmlFor="input-7">Indicação Clínica</label>
                      {/* <CKEditor
                                                editor={ ClassicEditor }
                                                data={solicitacao.descricao}
                                                onChange={( event, editor ) => setSolicitacao({...solicitacao, descricao: editor.getData() })}
                                            /> */}
                      <textarea
                        value={solicitacao.descricao || ""}
                        onChange={(evt) =>
                          setSolicitacao({
                            ...solicitacao,
                            descricao: evt.target.value,
                          })
                        }
                        id="input-7"
                      />
                    </div>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </div>
          {solicitacao.tipo.id === 5 && (
            <div className="page-box">
              <div className="title">Medicamentos</div>
              <Paper elevation={3} className="content">
                <table>
                  <thead>
                    <tr>
                      <th>Ean</th>
                      <th>Medicamento</th>
                      <th>Posologia</th>
                      <th>Quantidade</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {medicamentos.map((value, i) => (
                      <tr key={i}>
                        <td>{value.ean}</td>
                        <td>
                          {value.medicamento} -{" "}
                          {value.apresenta.substring(0, 30)}...
                        </td>
                        <td>{value.posologia.substring(0, 50)}...</td>
                        <td>{value.qtd}</td>
                        <td>
                          <button
                            disabled={solicitacao.situacao.id !== 1}
                            className="btn small gray-bg"
                            onClick={() => removerNovoMedicamento(i)}
                          >
                            Remover
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <NovoMedicamento
                  disabled={solicitacao.situacao.id !== 1}
                  save={inserirNovoMedicamento}
                  listSave={inserirListaDeMedicamentos}
                />
              </Paper>
            </div>
          )}
          {solicitacao.tipo.id === 4 && (
            <div className="page-box">
              <div className="title">Procedimentos</div>
              <Paper elevation={3} className="content">
                <table>
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Descrição</th>
                      <th>Quantidade</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {procedimentos.map((value, i) => (
                      <tr key={i}>
                        <td>{value.id}</td>
                        <td>{value.desc}</td>
                        <td>{value.qtd}</td>
                        <td>
                          <button
                            disabled={solicitacao.situacao.id !== 1}
                            className="btn small gray-bg"
                            onClick={() => removerNovoProcedimento(i)}
                          >
                            Remover
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <NovoProcedimento
                  disabled={solicitacao.situacao.id !== 1}
                  save={inserirNovoProcedimento}
                  listSave={inserirListaDeProcedimentos}
                />
              </Paper>
            </div>
          )}
        </Grid>

        <PageActions
          formState={{
            saved: !!solicitacao.id,
            situacao: solicitacao.situacao.id,
          }}
          doPrint={gerarRelatorio}
          doClear={clearData}
          doCancel={cancelSolicitacao}
          doSave={save}
          doSigning={signinHandle}
        />
      </Grid>
    </>
  );
}

export default Solicitacao;
