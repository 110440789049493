import React, { useState, useEffect } from "react";
import { Grid, Paper, Switch, Button } from "@material-ui/core";
import axios from "axios";
import "./index.scss";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { ViewState } from "@devexpress/dx-react-scheduler";
import { addHours } from "date-fns";

import {
  Scheduler,
  WeekView,
  Appointments,
  Toolbar,
  AppointmentTooltip,
  AppointmentForm,
  DateNavigator,
} from "@devexpress/dx-react-scheduler-material-ui";
import useApi from "hooks/useApi";

const horariosDisponiveis = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

const diasDisponiveis = [
  { title: "Domingo", value: "Sunday" },
  { title: "Segunda-feira", value: "Monday" },
  { title: "Terça-feira", value: "Tuesday" },
  { title: "Quarta-feira", value: "Wednesday" },
  { title: "Quinta-feira", value: "Thursday" },
  { title: "Sexta-feira", value: "Friday" },
  { title: "Sábado", value: "Saturday" },
];

const useStyles = makeStyles((theme) => ({
  todayCell: {
    backgroundColor: fade(theme.palette.primary.main, 0.1),
    "&:hover": {
      backgroundColor: fade(theme.palette.primary.main, 0.14),
    },
    "&:focus": {
      backgroundColor: fade(theme.palette.primary.main, 0.16),
    },
  },
  weekendCell: {
    backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
    "&:hover": {
      backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
    },
    "&:focus": {
      backgroundColor: fade(theme.palette.action.disabledBackground, 0.04),
    },
  },
  today: {
    backgroundColor: fade(theme.palette.primary.main, 0.16),
  },
  weekend: {
    backgroundColor: fade(theme.palette.action.disabledBackground, 0.06),
  },
}));

function Agenda() {
  document.title = "BSI - Agenda";
  const api = useApi();
  const [agenda, setAgenda] = useState([]);
  const [config, setConfig] = useState({ habilitado: true, intervalo: 15 });
  const [horarios, setHorarios] = useState([]);
  const [dias, setDias] = useState([]);
  const [prestadores, setPrestadores] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [edit, setEdit] = useState({});

  useEffect(() => {
    loadAgenda();
    loadPrestadores();
    loadServicos();
  }, []);

  function fixBrDate(dateTime) {
    return addHours(new Date(dateTime), 3);
  }

  async function loadPrestadores() {
    try {
      const request = await api.get("/prestadores");
      const response = request.data.payload;
      setPrestadores(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadServicos() {
    try {
      const request = await api.get("/servicos");
      const response = request.data.payload;
      setServicos(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function loadAgenda() {
    try {
      const request = await api.get("/agenda");
      const response = request.data.payload;

      const schedulerData = response.map((value) => {
        let obj = {
          data: value,
          startDate: fixBrDate(value.init_at),
          endDate: fixBrDate(value.end_at),
          title: value.beneficiario.nome,
        };

        return obj;
      });

      console.log(schedulerData);

      setAgenda(schedulerData);
    } catch (error) {
      console.log(error);
    }
  }

  function horariosHandle(value) {
    const index = horarios.indexOf(value);
    if (index === -1) return setHorarios([...horarios, value]);
    if (index > -1) {
      const newhorarios = horarios.filter((single) => single !== value);
      setHorarios(newhorarios);
    }
  }

  function diasHandle({ value }) {
    const index = dias.indexOf(value);
    if (index === -1) return setDias([...dias, value]);
    if (index > -1) {
      const newDias = dias.filter((single) => single !== value);
      setDias(newDias);
    }
  }

  function checkDias(value) {
    return dias.indexOf(value) > -1;
  }

  function checkHorarios(value) {
    return horarios.indexOf(value) > -1;
  }

  async function updateAgenda(id, action) {
    let payload = { prestador: { connect: { id: 1 } } };

    if (action === "cancela") {
      payload.situacao = { connect: { id: 4 } };
      payload.canceled_at = new Date();
    }

    if (action === "executa") {
      payload.situacao = { connect: { id: 3 } };
    }

    try {
      await api.put(`/agenda/${id}`, payload);
    } catch (error) {
      console.log(error);
    }
  }

  const AppointmentContent = (props) => {
    const { data, style } = props;

    let color = "#64b5f6";

    if (data.data.situacao_id === 3) color = "#64f6a5";
    if (data.data.situacao_id === 4) color = "#f66464";

    return (
      <Appointments.AppointmentContent
        style={{
          ...style,
          backgroundColor: color,
        }}
        {...props}
      />
    );
  };

  const Appointment = ({ children, style, data, ...restProps }) => {
    return (
      <Appointments.Appointment
        {...restProps}
        style={{
          ...style,
          backgroundColor: data.color,
        }}
      >
        {children}
      </Appointments.Appointment>
    );
  };

  const BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
    const agenda = appointmentData.data;
    const prestadoresOptions = prestadores.map((value) => ({
      id: value.id,
      text: value.nome,
    }));

    const servicosOptions = servicos.map((value) => ({
      id: value.id,
      text: value.titulo,
    }));

    return (
      <Grid container style={{ padding: 40 }}>
        <AppointmentForm.Label text="Detalhes" type="title" />
        <Grid item md={12} xs={12}>
          <AppointmentForm.TextEditor
            type={"titleTextEditor"}
            value={agenda.beneficiario.nome}
            readOnly
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={4} md={6}>
              <AppointmentForm.DateEditor
                onValueChange={(evt) => console.log(evt)}
                value={appointmentData.startDate}
              />
            </Grid>
            <Grid item xs={4} md={6}>
              <AppointmentForm.DateEditor
                onValueChange={(evt) => console.log(evt)}
                value={appointmentData.endDate}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <AppointmentForm.Label text="Prestador" type="title" />
          <AppointmentForm.Select
            style={{ width: "100%" }}
            onValueChange={(evt) => console.log(evt)}
            value={1}
            availableOptions={prestadoresOptions}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ marginTop: 20 }}>
          <AppointmentForm.Label text="Serviço" type="title" />
          <AppointmentForm.Select
            style={{ width: "100%" }}
            onValueChange={(evt) => console.log(evt)}
            value={2}
            availableOptions={servicosOptions}
          />
        </Grid>

        <Grid item md={12} xs={12} style={{ marginTop: 20 }}>
          <Grid container spacing={4}>
            <Grid item xs={6} md={3}>
              <AppointmentForm.Label text="Presencial" />
              <AppointmentForm.BooleanEditor
                onValueChange={(evt) => console.log(evt)}
                value={!agenda.remoto}
              />
            </Grid>
            {/* <Grid item xs={6} md={2}>
                            <AppointmentForm.Label text="PCD" />
                            <AppointmentForm.BooleanEditor
                                onValueChange={(evt) => console.log(evt) }
                                value={false}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <AppointmentForm.Label text="Recém Nascido" />
                            <AppointmentForm.BooleanEditor
                                onValueChange={(evt) => console.log(evt) }
                                value={false}
                            />
                        </Grid> */}
          </Grid>
        </Grid>

        <Grid
          className="page-actions"
          container
          justify="flex-end"
          alignItems="center"
        >
          <Button
            variant="contained"
            onClick={() => updateAgenda(agenda.id, "cancela")}
            color="inherit"
            className="red-bg"
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => updateAgenda(agenda.id, "executa")}
            color="primary"
          >
            Executar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const getActualDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
  };

  return (
    <>
      <Grid className="agenda" container>
        <Grid item md={12} xs>
          <div className="page-box">
            <div className="title">Agendamentos</div>
            <Paper>
              <Scheduler data={agenda} locale="pt-BR">
                <ViewState defaultCurrentDate={getActualDate()} />
                <Toolbar />
                <DateNavigator />
                <WeekView startDayHour={4} endDayHour={18} />
                <Appointments
                  appointmentContentComponent={AppointmentContent}
                />
                <AppointmentTooltip showCloseButton showOpenButton />
                <AppointmentForm readOnly basicLayoutComponent={BasicLayout} />
              </Scheduler>
            </Paper>
          </div>
        </Grid>
        <Grid container>
          <div className="page-box">
            <div className="title">Configração da Agenda</div>
            <Paper className="content-wrapper">
              <Grid container spacing={2}>
                <Grid item md={1} xs={6}>
                  <div className="input-wrapper">
                    <label htmlFor="input-1">Intervalo</label>
                    <input
                      type="number"
                      max={1}
                      defaultValue={config.intervalo}
                      onChange={(evt) =>
                        setConfig({ ...config, intervalo: evt.target.value })
                      }
                      id="input-1"
                    />
                  </div>
                </Grid>
                <Grid item md={3} xs={6}>
                  <div className="input-wrapper">
                    <label htmlFor="input-2">Habilitado</label>
                    <Switch
                      checked={config.habilitado}
                      color="primary"
                      onClick={() =>
                        setConfig({ ...config, habilitado: !config.habilitado })
                      }
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <div className="input-wrapper">
                    <label htmlFor="input-3">Dias Disponiveis</label>
                    {diasDisponiveis.map((value, i) => (
                      <span key={i}>
                        <button
                          onClick={() => diasHandle(value)}
                          className={
                            checkDias(value.value)
                              ? "btn blue-bg"
                              : "btn gray-bg"
                          }
                        >
                          {value.title}
                        </button>
                      </span>
                    ))}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                  <div className="input-wrapper">
                    <label htmlFor="input-3">Horários Disponiveis</label>
                    {horariosDisponiveis.map((value, i) => (
                      <span key={i}>
                        <button
                          onClick={() => horariosHandle(value)}
                          className={
                            checkHorarios(value) ? "btn blue-bg" : "btn gray-bg"
                          }
                        >
                          {value}
                        </button>
                      </span>
                    ))}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default Agenda;
