import { apiInstance } from '../axios';

export default function useApi() {
  async function get(path) {
    const request = await apiInstance.get(path);
    return request;
  }

  async function post(url, data) {
    const request = await apiInstance.post(url, data);

    return request;
  }

  async function put(url, data) {
    const request = await apiInstance.put(url, data);

    return request;
  }

  async function del(url) {
    const request = await apiInstance.delete(url);

    return request;
  }

  return {post, get, put, del};
}
