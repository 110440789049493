import React, { useState, useEffect } from 'react';
import { Grid, Paper, Button, CircularProgress } from '@material-ui/core';
import axios from "axios";
import "./index.scss";
import { GoPencil } from "react-icons/go";
import { Link } from 'react-router-dom';
import useApi from 'hooks/useApi';

function Prestadores(){
    const api = useApi();
    document.title = 'BSI - Lista de Prestadores';

    const [prestadores, setPrestadores] = useState([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        loadPrestadores();
    }, []);

    async function loadPrestadores(){
        try {
            const request = await api.get("/prestadores");
            const response = request.data.payload;
            setPrestadores(response);
        } catch (error) {
            
        } finally {
            setLoading(false);
        }
     
    }

    async function removePrestador(id){
        alert("Ainda não foi implementado a regra pra exclusão de entidade!");
    }

    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <>
            <Grid className="prestadores listar" container spacing={2}>
                <Grid container>
                    <div className="page-title">
                        <div className="float-left">
                            <h3>Lista de Prestadores</h3>
                            <small>({prestadores.length})</small>
                        </div>
                        <Button className="float-right" color="primary" variant="contained" href="prestadores/novo">Novo Prestador</Button>
                    </div>
                </Grid>
                <div className="page-box">
                    <Grid container spacing={3}>
                        {
                            prestadores.map(value => (
                                <Grid item md={3} key={value.id}>

                                    <Paper elevation={3}>
                                            <Grid container className="card">
                                                <Grid item xs={12}>
                                                    <Grid container justify="space-between" className="status">
                                                        {
                                                            value.credencial.Atividades.length ? (
                                                                <small><div className={"circle " + value.credencial.Atividades[0].slug}></div> {value.credencial.Atividades[0].titulo}</small>
                                                        ): (
                                                            <small><div className="circle"></div>Indisponivel</small>
                                                            )
                                                        }
                                                        <Link to={`prestadores/${value.id}`}><GoPencil size={18} className="edit-icon" /></Link> 
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className="avatar-wrapper">
                                                        <div className="avatar">
                                                            <img src={value.imagem_url || 'https://f0.pngfuel.com/png/340/956/profile-user-icon-png-clip-art-thumbnail.png'} alt=""/>
                                                        </div>
                                                        <h4 className="name text-center">{ value.nome }</h4>
                                                        <h5 className="text-center">{ value.email }</h5>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} className="statistics">
                                                    <Grid container justify="space-between">
                                                        <Grid container justify="space-between">
                                                            <div className="stats">
                                                                {/* <FiUsers size={18} /> */}
                                                                <small>Atendimentos</small>
                                                                <h2>{value.atendimentos.length}</h2>
                                                            </div>
                                                            <br />
                                                            <br />
                                                        </Grid>
                                                        <Grid container justify="space-between">
                                                            <div className="stats">
                                                                {/* <FiUsers size={18} /> */}
                                                                <small>Diagnosticos</small>
                                                                <h2>{value.diagnosticos.length}</h2>
                                                            </div>
                                                            <div className="stats">
                                                                {/* <FiUsers size={18} /> */}
                                                                <small>Solicitações</small>
                                                                <h2>{value.solicitacoes.length}</h2>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    </Paper>

                                </Grid>
                            ))
                        }
                    </Grid>
                </div>

            </Grid>

        </>
    )
    
}

export default Prestadores;