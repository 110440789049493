import React, { useState, useEffect } from 'react';
import { Grid, Button, Paper, Select, MenuItem, CircularProgress } from '@material-ui/core';

import MaskedInput from 'react-text-mask';
import masks from "others/mask";
import Estados from "assets/json/estados.json";
import { useHistory, useParams } from "react-router-dom";
import { useStateValue } from 'contexts';
import axios from "axios";
import "./index.scss";
import { FiArrowLeft } from "react-icons/fi";
import { changedValues, dateParser, prismaNewValues } from "others/services";
import Endereco from 'components/endereco';
import useApi from 'hooks/useApi';

function PacienteEditar(){
    const api = useApi();
    const [isLoading, setLoading] = useState(true);
    const [original, setOriginal] = useState({});
    const [beneficiario, setBeneficiario] = useState({
        endereco: {
            estado: ''
        },
        genero: { id: '' }
    });

    const [{}, dispatch] = useStateValue();

    const history = useHistory();
    const params = useParams();

    useEffect(() => {
        const { id } = params;
        const parserID = Number(id);
        if(parserID) loadBeneficiario(id);
    }, []);

    function showFlash(obj){

        obj.isOpen = true;

        dispatch({
            type: 'setFlash',
            payload: obj
        });

    }
    
    async function loadBeneficiario(id){

        try {
            const request = await api.get(`beneficiarios/${id}`);
        
            let response = request.data.payload;
    
            if(!response) return null;
    
            if(!response.endereco) response.endereco = { estado: "" };
            if(!response.genero) response.genero = { id: "" };
    
            if(response.data_nascimento) response.data_nascimento = dateParser(response.data_nascimento);
    
            setBeneficiario(response);
            setOriginal(response);
            
            document.title = 'BSI - '+ response.nome;
        } catch (error) {
            
        } finally {
   
          setLoading(false);

        }
     
    }

    async function save(){

        if(beneficiario.celular) beneficiario.celular = beneficiario.celular.replace(/\D/g, '');
        if(beneficiario.cpf) beneficiario.cpf = beneficiario.cpf.replace(/\D/g, '');
        
        if(beneficiario.endereco.cep) beneficiario.endereco.cep = Number(beneficiario.endereco.cep.toString().replace(/\D/g, ''));
        if(beneficiario.endereco.numero) beneficiario.endereco.numero = Number(beneficiario.endereco.numero);

        if(beneficiario.data_nascimento) {
            const parts = beneficiario.data_nascimento.split("/");
            const date = parts[2]+"-"+parts[0]+"-"+parts[1]+"T12:00:00.000Z";
            beneficiario.data_nascimento = date;
        }

        const newbeneficiario = changedValues(original, beneficiario);
        const endereco = prismaNewValues(original.endereco, beneficiario.endereco);
        const genero = changedValues(original.genero, beneficiario.genero);

        let payload = {...newbeneficiario};
        if(endereco) payload.endereco = endereco;
        
        if(genero && genero.id){
            payload.genero = {
                connect : { id: genero.id }
            }
        }

        try {
            const { id } = beneficiario;
            const request = (id) ? await api.put(`beneficiarios/${id}`, payload) : await api.post(`beneficiarios`, payload);
            const response = request.data;

            showFlash({type: "success", title: "Sucesso", subTitle: response.msg});
            
            setTimeout(() => {
                history.push("/app/prestador/pacientes/lista");
            }, 1000);
            
        } catch (error) {
            showFlash({type: "error", title: "Error", subTitle: "Verifique os dados"});
            console.log(error);
        }
       
    }

    if(isLoading) {

        return (
            <div className="loading-wrapper">
                <CircularProgress />
            </div>
        )

    }

    return ( 
        <>
            <Grid className="beneficiarios editar" style={{width: "100%", margin: 0}} container spacing={2}>
                <Grid container>
                    <div className="page-title">
                        <a href="../beneficiarios">
                            <FiArrowLeft size={28} />
                            <h3>Voltar</h3>
                        </a>
                    </div>
                </Grid>
                <Grid container>
                    <div className="page-box">
                        <div className="title">
                            Informações legais
                        </div>
                        <Paper elevation={3} className="content">
                            <form>
                                {/* <TextField onChange={(evt) => setCredential({...credential, login : evt.target.value})} color="primary" className="custom-input" label="Login" fullWidth variant="outlined" /> */}
                                <Grid container>
                                    {/* <label htmlFor="input-2">Razão Social</label> */}
                                    <input autoFocus={true} placeholder="Nome do Paciente" className="big-input" defaultValue={beneficiario.nome} onChange={(evt) => setBeneficiario({...beneficiario, nome: evt.target.value})} />
                                </Grid>
                                    
                                <Grid container spacing={2}>

                                    <Grid container spacing={2}>

                                        <Grid item md={3} xs={6}>
                                            <div className="input-wrapper">
                                                <label htmlFor="input-3">CPF</label>
                                                <MaskedInput guide={false} mask={masks.cpf} defaultValue={beneficiario.cpf} onChange={(evt) => setBeneficiario({...beneficiario, cpf: evt.target.value})} id="input-3" />
                                            </div>
                                        </Grid>

                                        <Grid item md={3} xs={6}>
                                            <div className="select-wrapper">
                                                <label htmlFor="input-14">Gênero</label>
                                                <Select value={beneficiario.genero.id} onChange={(evt) => setBeneficiario({...beneficiario, genero: { id: evt.target.value } } )} htmlFor="input-11" variant="outlined" fullWidth>
                                                    <MenuItem value={1}><span>Masculino</span></MenuItem>
                                                    <MenuItem value={2}><span>Feminino</span></MenuItem>
                                                    <MenuItem value={3}><span>Não Informado</span></MenuItem>
                                                </Select> 
                                            </div>
                                        </Grid>

                                        <Grid item md={3} xs={6}>
                                            <div className="input-wrapper">
                                                <label htmlFor="input-2">Data de Nascimento</label>
                                                <MaskedInput guide={false} mask={masks.date} defaultValue={beneficiario.data_nascimento} onChange={(evt) => setBeneficiario({...beneficiario, data_nascimento: evt.target.value})} id="input-3" />
                                            </div>
                                        </Grid>

                                    </Grid>

                                    <Grid container spacing={2}>

                                        <Grid item md={3} xs={6}>
                                            <div className="input-wrapper">
                                                <label htmlFor="input-2">Email</label>
                                                <input type="email" defaultValue={beneficiario.email} onChange={(evt) => setBeneficiario({...beneficiario, email: evt.target.value } )} id="input-12" />
                                            </div>
                                        </Grid>
                                        
                                        <Grid item md={3} xs={6}>
                                            <div className="input-wrapper">
                                                <label htmlFor="input-7">Celular</label>
                                                <MaskedInput guide={null} mask={masks.cellPhone} defaultValue={beneficiario.celular} onChange={(evt) => setBeneficiario({...beneficiario, celular: evt.target.value})} id="input-7" />
                                            </div>
                                        </Grid>

                                    </Grid>

                                </Grid>                               
                            </form>
                            <br/>
                        </Paper>
                    </div>
                    <div className="page-box">
                        <div className="title">
                            Endereço
                        </div>

                        <Endereco endereco={beneficiario.endereco} changeData={endereco => setBeneficiario({...beneficiario, endereco}) } />
                        
                    </div>

                </Grid>

                <Grid className="page-actions" container justify="flex-end" alignItems="center">
                    <Button variant="contained" href="../beneficiarios" color="inherit">Cancelar</Button>
                    <Button className="primary-gradient" variant="contained" onClick={save}>Salvar</Button>
                </Grid>


            </Grid>
            
        
        </>
    )
    
}

export default PacienteEditar;