import React from 'react';
import { Image, View, StyleSheet, Text } from '@react-pdf/renderer';
import logov1 from "assets/img/logos/logov1-transparent.png";
import pattern from "assets/img/patter-1.png";

const styles = StyleSheet.create({
    wrapper: {
        boxShadow: "1px 3px 1px #9E9E9E",
        // boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
        borderRadius: 8,
        overflow: "hidden",
        padding: 7,
        flex: 1,
        borderWidth: 1,
        borderColor: "#ccc"
    },
    rightPattern: {
        height: "auto", 
        width: 40,
        alignSelf: "flex-end", 
        position: "absolute", 
        top: -1,
        right: -10
    }
});

const DocumentHeader = ({certificado}) => {
    
    const { name, cpf, email } = certificado;

    return (
        <View style={styles.wrapper}>
            <View style={{flexDirection: "row"}}>
                <Image style={{width: 50, height: "auto", marginLeft: -2}} src={logov1} />
                <View style={{marginLeft: 5, marginTop: 5}}>
                    <Text style={{fontSize: 5, color: "#727272"}}>Assinado</Text>
                    <Text style={{fontSize: 7, color: "#727272"}}>Digitalmente</Text>
                </View>
            </View>

            <View style={{marginTop: 5}}>
                <Text style={{color: "#727272", fontSize: 8}}>{ name }</Text>
                <Text style={{color: "#727272", fontSize: 8}}>{ email }</Text>
            </View>

            <Image style={styles.rightPattern} src={pattern} />

        </View>
    )
}

export default DocumentHeader;
